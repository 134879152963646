import React from 'react';
import { Box, createStyles, Grid, makeStyles, Theme, Typography, Button } from '@material-ui/core';

const SolutoLocation = ({ fields }) => {
    const classes = useStyles();
    return (
        <Box component={'section'} className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.title}>
                        {fields?.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <img src={fields.imageUrl} alt={fields.imageTitle} className={classes.locationImage} />
                </Grid>
                <Grid item xs={12} className={classes.buttonHolder}>
                    <a href={fields.buttonUrl} target={'_blank'} className={classes.buttonLink} rel="noreferrer">
                        <Button variant="contained" color="primary" className={classes.button} size="large">
                            <Typography className={classes.buttonText}>{fields.buttonText}</Typography>
                        </Button>
                    </a>
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '140px',

            [theme.breakpoints.down('lg')]: {
                paddingBottom: '110px',
            },
        },
        title: {
            fontSize: '40px',
            letterSpacing: '2px',
            textAlign: 'center',
            margin: '0 0 30px',

            [theme.breakpoints.down('lg')]: {
                fontSize: '34px',
            },
        },
        locationImage: {
            maxWidth: '335px',
            width: '100%',
            display: 'block',
            margin: '35px auto 40px',

            [theme.breakpoints.down('xs')]: {
                maxWidth: '265px',
                marginTop: '15px',
            },
        },
        buttonHolder: {
            textAlign: 'center',
        },
        button: {
            color: '#fff',
            backgroundColor: '#000',
            border: '1px solid #000',
            width: '280px',
            margin: '0 auto',
            fontSize: '20px',
            lineHeight: '45px',

            '&:hover': {
                color: '#000',
                backgroundColor: '#fff',
                border: '1px solid #000',
            },
        },
        buttonText: {
            fontSize: '20px',
            lineHeight: '45px',
        },
        buttonLink: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
            '&:active': {
                textDecoration: 'none',
            },
        },
    })
);

export default SolutoLocation;
