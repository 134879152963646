import React, { Fragment, useState } from 'react';
import { Grid, Box, Drawer, Divider, List, ListItem, Card, CardContent, CardHeader } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import 'swiper/swiper-bundle.css';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface Types {
    name: string;
    icon: string;
    bannerImage: string;
    description: string[];
}

export interface PartnersTypesProps {
    title: ContentfulDocument;
    typesDetails: Types[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
        },
        centerContents: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        spaceBelow: {
            marginBottom: theme.spacing(3),
        },
        textBox: {
            display: 'flex',
            justifyContent: 'center',
            height: '15%',
            textAlign: 'center',
            fontSize: '2.125rem',
            lineHeight: '2.25rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.875rem',
            },
        },
        drawerBox: {
            position: 'relative',
            height: '85%',
        },
        sectionBox: {
            position: 'relative',
        },
        drawerPaper: {
            position: 'absolute',
            width: '20%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        drawerContentBox: {
            width: '30em',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        },
        contentBannerBox: {
            height: '40%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        },
        contentTextBox: {
            [theme.breakpoints.up('md')]: {
                height: '60%',
                padding: theme.spacing(5),
                fontSize: '1.5rem',
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
            },
        },
        listBox: {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-evenly',
            height: '100%',
            padding: 0,
        },
        listItem: {
            flexGrow: 1,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            '&:hover': {
                color: 'white',
                background: theme.palette.primary.main + ` !important`,

                '&>.MuiListItemIcon-root>img': {
                    filter: 'invert(1)',
                },
            },
            '&.even': { background: '#F0F0F5' },
            '&.odd': { background: '#E6E6EB' },
        },
        selectedListItem: {
            color: 'white',
            background: theme.palette.primary.main + ` !important`,
        },
        listIconBox: {
            display: 'flex',
            justifyContent: 'center',
            marginRight: theme.spacing(3),
            width: theme.spacing(3),
        },
        listIcon: {
            '&.selected': {
                filter: 'invert(1)',
            },
            [theme.breakpoints.down('sm')]: {
                height: '30px',
            },
        },
        listText: {
            fontSize: '2.125rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },

        swiperContainer: {
            width: '100%',
        },
        swiper: {
            height: '100%',
        },
        slideContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paginationMod: {
            [theme.breakpoints.up('md')]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
                display: 'none',
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: '#D5D6DA !important',
            margin: '0px 10px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#8223D2 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
        card: {
            width: '300px',
            height: '440px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            marginBottom: theme.spacing(6),
        },
        cardHeader: {
            background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)',
            color: 'white',
            '&>div>span': {
                fontSize: '1.5rem',
            },
            padding: '18px',
        },
        contentBox: {
            padding: theme.spacing(2),
        },
    })
);

function PartnersTypes(typesDetails: PartnersTypesProps) {
    const [showContent, setShowContent] = useState<number>(0);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    SwiperCore.use([Navigation, Pagination, Autoplay]);

    const handleSelectType = selectedType => {
        setShowContent(selectedType);
    };

    console.log(typesDetails);

    const renderSection = (typesDetails: PartnersTypesProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid className={clsx(classes.sectionBox)}>
                <Grid item xs={12} container direction="row" className={classes.textBox}>
                    <Box component="p" fontWeight="bold">
                        {documentToReactComponents(typesDetails.title, options)}
                    </Box>
                </Grid>
                {isMobile ? (
                    <Grid item xs={12} container direction="row">
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                                bulletClass: `` + classes.paginationBullet + ``,
                                bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                clickableClass: `` + classes.paginationMod + ``,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {typesDetails.typesDetails.map((type, i) => (
                                <SwiperSlide className={classes.slideContainer}>
                                    <Card className={classes.card} variant="outlined">
                                        <CardHeader title={type.name} className={classes.cardHeader} avatar={<img src={type.icon} alt={type.name} className={clsx(classes.listIcon, 'selected')} />} />
                                        <CardContent className={classes.contentBox}>
                                            <Box className={classes.contentTextBox}>
                                                {type.description.map((paragraph, i) => (
                                                    <Box component="p">{paragraph}</Box>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>
                ) : (
                    <Grid item xs={12} container direction="row" className={classes.drawerBox}>
                        <Grid item md={4} sm={12}>
                            <Drawer
                                PaperProps={{ style: { position: 'relative' } }}
                                BackdropProps={{ style: { position: 'relative' } }}
                                ModalProps={{
                                    container: document.getElementById('drawer-container'),
                                    style: { position: 'relative' },
                                }}
                                variant="permanent"
                                anchor="left"
                                className={classes.h100}
                            >
                                <Divider />
                                <List className={classes.listBox}>
                                    {typesDetails.typesDetails.map((type, i) => (
                                        <ListItem
                                            button
                                            key={type.name}
                                            className={clsx(classes.listItem, i % 2 === 0 ? 'even' : 'odd', showContent === i ? classes.selectedListItem : '')}
                                            onClick={() => handleSelectType(i)}
                                        >
                                            <ListItemIcon className={classes.listIconBox}>
                                                <img src={type.icon} alt={type.name} className={clsx(classes.listIcon, showContent === i ? 'selected' : '')} />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.listText }} primary={type.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Drawer>
                        </Grid>
                        <Grid item md={8} sm={12} container direction="column" className={classes.drawerContentBox}>
                            {typesDetails.typesDetails.map(
                                (type, i) =>
                                    showContent === i && (
                                        <Fragment key={i}>
                                            <Grid className={classes.contentBannerBox} style={{ backgroundImage: 'url(' + type.bannerImage + ')' }}></Grid>
                                            <Grid className={classes.contentTextBox}>
                                                {type.description.map((paragraph, i) => (
                                                    <Box component="p" key={i}>
                                                        {paragraph}
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Fragment>
                                    )
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(typesDetails)}</div>;
}

export default PartnersTypes;
