import { Box, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { colors } from '../../../../themes/default';
import { sv } from '../../../../common/functions';
import clsx from 'clsx';
import parse from 'html-react-parser';

export interface SmartphoneAccordionProps {
    data?: Array<any>;
}

const useStyle = makeStyles(theme => ({
    programContainer: {
        color: colors.ghostWhite,

        [theme.breakpoints.up('md')]: {
            margin: sv(-35, 0),
        },
    },
    program: {
        width: '14%',
        transition: theme.transitions.create(['width'], {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeInOut,
            delay: '100ms',
        }),
        '&.programClicked': {
            width: '32%',
            maxWidth: '32%',

            [theme.breakpoints.down('sm')]: {
                width: '35%',
                maxWidth: '35%',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },

        [theme.breakpoints.down('sm')]: {
            height: '25em',
            width: '20%',

            '&.shrink': {
                width: '16.25%',
            },
        },
    },

    programItems: {
        margin: sv(30, 20, 0, 0),
    },

    programTitle: {
        transform: 'rotate(180deg)',
        textOrientation: 'sideways',
        whiteSpace: 'nowrap',
        writingMode: 'vertical-lr',
        fontSize: '3rem',
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        '&.programTitleRemoved': {
            display: 'none',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },
    programTitleDescription: {
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },

    programTitleBody: {
        fontSize: '3rem',
        width: '100%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },

    icon: {
        width: '100%',
        textAlign: 'center',
        marginTop: sv(30),

        [theme.breakpoints.down('sm')]: {
            marginTop: sv(20),
        },
    },

    expandButton: {
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },

        '&.expandButtonClicked': {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                left: sv(100),
            },
        },
        '& svg': {
            width: '1.875em',
            height: '1.875em',

            [theme.breakpoints.down('sm')]: {
                width: '15px',
                height: '15px',
            },
        },

        [theme.breakpoints.up('md')]: {
            left: sv(0),
            transition: theme.transitions.create(['left'], {
                duration: theme.transitions.duration.complex,
                easing: theme.transitions.easing.easeInOut,
                delay: '150ms',
            }),
        },
    },

    programCollapse: {
        width: '100%',
        textAlign: 'center',
        display: 'none',
        transition: theme.transitions.create(['display'], {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeOut,
        }),
        '&.expanded': {
            display: 'inline-block',
            transition: theme.transitions.create(['display'], {
                duration: theme.transitions.duration.complex,
                easing: theme.transitions.easing.easeOut,
            }),
        },
    },
    titleBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    imageBox: {
        marginTop: '20px',

        [theme.breakpoints.down('sm')]: {
            padding: '0 16px',

            '&>img': {
                width: '100%',
                maxHeight: '100px',
            },
        },
    },
    descriptionBox: {
        [theme.breakpoints.up('md')]: {
            marginTop: '30px',
            paddingLeft: '1em',
            paddingRight: '1em',
            maxHeight: '1em',
            maxWidth: '20em',
            // wordBreak: 'break-word',
        },

        [theme.breakpoints.down('sm')]: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
}));

export const SmartphoneAccordion = (props: SmartphoneAccordionProps) => {
    const classes = useStyle(props);
    // const [, setExpanded] = useState<string | null>(null);
    const [selectedIndex, setSelectedIndex] = useState('');

    // const handleChange = (panel: string) => (_event: any, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : null);
    // };

    const handleClick = (index: string) => {
        if (selectedIndex === index) {
            setSelectedIndex('');
        } else {
            setSelectedIndex(index);
        }
    };

    return (
        <Grid container justifyContent="flex-end" className={classes.programContainer}>
            {props.data.map((program: any, i: any) => {
                let programFlag = selectedIndex === i;
                return (
                    <Box
                        className={clsx(classes.program, programFlag ? 'programClicked' : '', selectedIndex !== '' && !programFlag && 'shrink')}
                        bgcolor={program.color}
                        onClick={() => handleClick(i)}
                    >
                        <Grid container justifyContent="space-between" direction="column" alignItems="center">
                            <Grid item className={classes.icon}>
                                <Icon className={clsx(classes.expandButton, programFlag ? 'expandButtonClicked' : '')}>{programFlag ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</Icon>
                            </Grid>
                            <Grid item className={classes.titleBox}>
                                <Typography className={clsx(classes.programTitle, programFlag ? 'programTitleRemoved' : '')}>{program.title}</Typography>
                            </Grid>
                            <Grid item className={clsx(classes.programCollapse, programFlag ? 'expanded' : '')}>
                                <Typography className={classes.programTitleBody}>{program.title}</Typography>
                            </Grid>
                            <Grid item className={clsx(classes.programCollapse, classes.imageBox, programFlag ? 'expanded' : '')}>
                                <img src={program.icon} alt={program.icon} />
                            </Grid>
                            <Grid item className={clsx(classes.programCollapse, classes.descriptionBox, programFlag ? 'expanded' : '')}>
                                <span className={classes.programTitleDescription}>{program.content}</span>
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </Grid>
    );
};

export default SmartphoneAccordion;
