import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import bgContent from '../../../images/soluto/content_gradient.png';
import imgTick from '../../../images/soluto/tick.svg';

const renderItemList = ({ list, index, classes, isMobile = false }) => {
    return (
        <ul className={classes.itemList} key={`itemlist-${index}`}>
            <li className={classes.itemListItem}>
                <Grid container>
                    <Grid item xs={1}>
                        <img src={imgTick} alt={list.title} className={classes.tick} />
                    </Grid>
                    <Grid item xs={11} className={classes.itemListItemTitle}>
                        {list.title}
                    </Grid>
                    <Grid item xs={12} className={classes.itemListItemDescription}>
                        {list.description}
                    </Grid>
                </Grid>
            </li>
        </ul>
    );
};

const renderItemSections = ({ item, index, classes, isMobile = false }) => {
    const contentLists = item?.content?.items;
    const isEven = index % 2;

    if (isMobile) {
        return (
            <Grid container className={classes.gridContainer} key={`image-section-${index}`}>
                <Grid item sm={12} style={{ width: '100%' }}>
                    <div className={classes.imgHolder}>
                        <div className={classes.masks}>
                            <img src={item?.image?.url} alt={'masks'} className={classes.maskImg} />
                        </div>
                        <img src={item?.icon?.url} alt={item?.icon?.title} className={classes.icon} />
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="h3" className={classes.itemTitle} align="right">
                        {item?.title}
                    </Typography>
                    {contentLists.map((list, index) => {
                        return renderItemList({ list, index, classes });
                    })}
                </Grid>
            </Grid>
        );
    } else {
        const ContentTextComponent = (
            <Fragment>
                <Typography variant="h3" className={classes.itemTitle} align={'left'}>
                    {item?.title}
                </Typography>
                {contentLists.map((list, index) => {
                    return renderItemList({ list, index, classes });
                })}
            </Fragment>
        );

        const ContentImageComponent = (
            <div className={classes.imgHolder}>
                <div className={classes.masks}>
                    <img src={item?.image?.url} alt={'masks'} className={classes.maskImg} />
                </div>
                <img src={item?.icon?.url} alt={item?.icon?.title} className={classes.icon} />
            </div>
        );

        return (
            <Grid container className={classes.gridContainer} key={`image-section-${index}`}>
                <Grid item xs={isEven ? 6 : 5}>
                    {isEven ? ContentTextComponent : ContentImageComponent}
                </Grid>
                <Grid item xs={isEven ? 5 : 6}>
                    {isEven ? ContentImageComponent : ContentTextComponent}
                </Grid>
            </Grid>
        );
    }
};

const SolutoSections = ({ title, items, isMobile }) => {
    const classes = useStyles();

    const [sectionTitle, setSectionTitle] = useState(title);
    const [sectionItems, setSectionItems] = useState(items);

    useEffect(() => {
        setSectionTitle(title);
        setSectionItems(items);
    }, [title, items]);

    return (
        <Box component={'section'} className={classes.main}>
            <Box component="div" className={classes.container}>
                <Box component="div" sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant="h3" className={classes.mainTitle}>
                        {sectionTitle}
                    </Typography>
                    {sectionItems.map((item, index) => {
                        return renderItemSections({ isMobile, item, index, classes });
                    })}
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            padding: `70px 0`,
            position: 'relative',

            '&::before': {
                backgroundImage: `url(${bgContent})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'absolute',
                content: '""',
                left: 0,
                top: '190px',
                width: '100%',
                height: '700px',
            },
        },
        mainTitle: {
            fontSize: '40px',
            [theme.breakpoints.down('lg')]: {
                fontSize: '34px',
            },
        },
        container: {
            width: '100%',
            maxWidth: '1175px',
            margin: '0 auto',

            [theme.breakpoints.down('md')]: {
                maxWidth: '900px',
            },
        },
        gridContainer: {
            marginBottom: '85px',

            '&:first-of-type': {
                marginTop: '120px',

                [theme.breakpoints.down('sm')]: {
                    marginTop: '40px',
                },
            },
        },
        imgHolder: {
            width: '410px',
            maxWidth: '100%',
            position: 'relative',

            [theme.breakpoints.down('sm')]: {
                width: '320px',
                position: 'relative',
                margin: '0 auto',
                marginBottom: '40px',
            },
        },
        masks: {
            width: '375px',
            height: '375px',
            borderRadius: '50%',
            overflow: 'hidden',
            boxShadow: '5px 8.66px 17.46px 0.54px rgb(51 51 51 / 40%)',
            backgroundColor: '#898989',

            [theme.breakpoints.down('sm')]: {
                width: '300px',
                height: '300px',
            },
        },
        maskImg: {
            WebkitTransition: '-webkit-transform .3s linear',
            transition: 'transform .3s linear, -webkit-transform .3s linear',
            maxWidth: '100%',
            borderStyle: 'none',

            [theme.breakpoints.down('sm')]: {
                maxWidth: '300px',
            },
        },
        icon: {
            width: '144px',
            height: '144px',
            position: 'absolute',
            right: 0,
            bottom: '-25px',

            [theme.breakpoints.down('sm')]: {
                width: '100px',
                height: '100px',
            },
        },
        itemTitle: {
            fontSize: '34px',
            fontWeight: 'bold',
            margin: '0 0 20px',

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                margin: '15px 0',
            },
        },
        itemList: {
            fontSize: '20px',
            paddingLeft: 0,

            [theme.breakpoints.down('sm')]: {
                width: '80%',
                maxWidth: '530px',
                margin: '0 auto',
            },
        },
        itemListItem: {
            marginBottom: '20px',
            listStyleType: 'none',
            position: 'relative',
        },
        tick: {
            height: '22px',
            width: '22px',
        },
        itemListItemTitle: {
            textAlign: 'left',
            fontSize: '24px',
            fontWeight: 700,

            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
            },
        },
        itemListItemDescription: {
            textAlign: 'left',
            color: '#3C3C3C',
            fontSize: '20px',
            paddingLeft: '40px',

            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
            },
        },
    })
);

export default SolutoSections;
