import { Box, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { rem } from '../../../common/functions';
import { ProductItem } from './types';

export interface ProductStripProps {
    products: Array<ProductItem>;
}

const useStyle = makeStyles(theme => ({
    container: {
        padding: 40,
        display: 'flex',
        gap: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        color: theme.palette.primary.main,
        fontSize: rem(1.25),
        letterSpacing: rem(0.15 / 16),
    },
    iconRoot: {
        height: 40,
    },
}));

export const ProductStrip = (props: ProductStripProps) => {
    const { products } = props;
    const classes = useStyle();

    return (
        <Box className={classes.container}>
            {products.map((product, i) => (
                <Box key={product.key ? product.key : `product-${i}`} className={classes.content}>
                    <img src={product.icon} alt={product.key} className={classes.iconRoot} />
                    {product.title}
                </Box>
            ))}
        </Box>
    );
};

export default ProductStrip;
