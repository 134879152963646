import React, { useEffect, useState } from 'react';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import ContactInfo, { ContactInfoProps } from '../Contact/ContactInfo';
import ContactForm, { ContactFormProps } from '../Contact/ContactForm';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';

const pageEntryId: string = process.env.REACT_APP_CONTACT_ENTRY_ID;

// const heroMainDetails: HeroMainProps = {
//     title: 'Contact Asurion',
//     backgroundImage: BannerImage,
//     backgroundImageSmall: BannerImageSmall,
// };

// const help: HelpType[] = [
//     {
//         title: 'Job Seeker',
//         description: 'Interested in joining our team? Check out our career page and our open positions.',
//         image: JobSeekerIcon,
//         color: '#E6E6EB',
//     },
//     {
//         title: 'Partner',
//         description: 'Looking for device protection and techcare solutions for your business? Explore how your company can leverage Asurion’s services globally.',
//         image: PartnerIcon,
//         color: '#D5D6DA',
//     },
//     {
//         title: 'Press',
//         description: 'For press and media inquiries, view our media kit or contact us.',
//         image: PressIcon,
//         color: '#E6E6EB',
//     },
//     // {
//     //     title: 'Complaints',
//     //     description: 'If there’s something we can improve or help with, please let us know. We’re here to listen and  help if possible.',
//     //     image: ComplaintsIcon,
//     //     color: '#F0F0F5',
//     // },
// ];

// const helpDetailsLocal: ContactInfoProps = {
//     helpDetails: help,
// };

// const buttonChoices: ButtonChoices[] = [
//     {
//         text: 'Career opportunity',
//         value: 'Job Seeker',
//     },
//     {
//         text: 'Partnership inquiry',
//         value: 'Partner',
//     },
//     {
//         text: 'Press inquiry',
//         value: 'Press',
//     },
//     {
//         text: 'Feedback',
//         value: 'Feedback',
//     },
//     {
//         text: 'Others',
//         value: 'Other',
//     },
// ];

// const addresses: LocalAddresses = {
//     US: ['Asurion Australia Pty Ltd', 'Building 10, Level 1', '658 Church Street', 'Cremorne VIC Australia 3121'],
//     CN: ['Asurion China', 'A2305, PingAn International Financial Center, No.1-3 XinYuan Nan Road, Chaoyang District, Beijing 100027'],
//     HK: ['Asurion Hong Kong', '10/F, YF Life Tower', '33 Lockhart Road, Wanchai', 'Hong Kong'],
//     KR: ['10F, Centopolis Bldg., Tower-A', '26, Ujeongguk-ro, Jongno-gu, Seoul, 03161', 'Republic of Korea'],
//     MY: ['19-5, Q Sentral, 2A, Jalan Stesen Sentral 2, KL Sentral, 50470 Kuala Lumpur'],
//     NZ: ['Level 22, Pwc Tower', '15 Customs Street West', 'Auckland, 1010'],
//     SG: ['7 Temasek Boulevard Suntec Tower One', '#09-01 Singapore 038987'],
//     TH: ['6/10 Pipatanasin Building, Unit No. 9B, 9th Floor', 'Narathiwat-Rachanakarin Rd', 'Tungmahamek Sub-district', 'Sathorn District Bangkok 10120'],
//     TW: ['20F, No. 460, Sec. 4', 'Xinyi Road, Xinyi Dist.', 'Taipei City, Taiwan'],
// };

// const formDetailsLocal: ContactFormProps = {
//     address: addresses[process.env.REACT_APP_LOCALE.split('-').slice(-1)[0]],
//     email: ['australia@asurion.com'],
//     buttonChoices: buttonChoices,
// };

// const footerBannerDescription: footerDescriptionText[] = [
//     { text: 'We’re at the forefront of ', color: 'white' },
//     { text: 'protecting technology', color: '#37E7A7' },
//     { text: ', from smartphone to smart home', color: 'white' },
// ];

// const footerDetailsLocal: FooterBannerProps = {
//     bannerTitle: 'Want to learn more about our products?',
//     bannerDescription: footerBannerDescription,
//     bannerImage: FooterPerson,
//     buttonText: 'Learn More',
//     buttonLink: '/what-we-do',
// };

function PartnersPage() {
    const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [contactInfoContent, setContactInfoContent] = useState<ContactInfoProps>();
    const [contactFormContent, setContactFormContent] = useState<ContactFormProps>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const loadPageContent = React.useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                console.log(entry);
                let bannerImageReferences: ContentfulReference[];
                let smallBannerImageReferences: ContentfulReference[];
                let inquiryTypeImage1ImageReferences: ContentfulReference[];
                let inquiryTypeImage2ImageReferences: ContentfulReference[];
                let inquiryTypeImage3ImageReferences: ContentfulReference[];
                let footerBannerImageReferences: ContentfulReference[];

                bannerImageReferences = entry.getReferences('bannerImage');
                smallBannerImageReferences = entry.getReferences('bannerImageSmall');
                inquiryTypeImage1ImageReferences = entry.getReferences('inquiryTypeImage1');
                inquiryTypeImage2ImageReferences = entry.getReferences('inquiryTypeImage2');
                inquiryTypeImage3ImageReferences = entry.getReferences('inquiryTypeImage3');
                footerBannerImageReferences = entry.getReferences('footerBannerPersonImage');

                const promise1 = bannerImageReferences[0].getData(contentfulConfig);
                const promise2 = smallBannerImageReferences[0].getData(contentfulConfig);
                const promise3 = inquiryTypeImage1ImageReferences[0].getData(contentfulConfig);
                const promise4 = inquiryTypeImage2ImageReferences[0].getData(contentfulConfig);
                const promise5 = inquiryTypeImage3ImageReferences[0].getData(contentfulConfig);
                const promise6 = footerBannerImageReferences[0].getData(contentfulConfig);

                Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then((values: ContentfulEntry[]) => {
                    entry.fields.inquiryTypes[0]['image'] = values[2].fields['file'].url;
                    entry.fields.inquiryTypes[1]['image'] = values[3].fields['file'].url;
                    entry.fields.inquiryTypes[2]['image'] = values[4].fields['file'].url;

                    setHero({ title: entry.fields.bannerText, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                    setContactInfoContent({ helpDetails: entry.fields.inquiryTypes, headerTitle: entry.fields.title });
                    setContactFormContent({
                        address: entry.fields.address.address,
                        email: [],
                        buttonChoices: entry.fields.howCanWeHelpButtonChoices,
                        formLabels: entry.fields.formLabels,
                        title: entry.fields.title,
                        officeLocationTitle: entry.fields.officeLocationTitle,
                        askUsTitle: entry.fields.askUsTitle,
                        askUsDescription: entry.fields.askUsDescription,
                        submitFormButtonText: entry.fields.submitFormButtonText,
                    });
                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[5].fields['file'].url,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...hero} />
                    <ContactInfo {...contactInfoContent} />
                    <ContactForm {...contactFormContent} />
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </div>
    );
}

export default PartnersPage;
