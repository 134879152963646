import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player/lazy';
import parse from 'html-react-parser';

import VideoImage from '../../../images/about/Video.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface AboutStoryProps {
    buttonText: string;
    buttonUrl: string;
    description: ContentfulDocument;
    image: string;
    title: ContentfulDocument;
    videoUrl: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '35em',
            [theme.breakpoints.down('sm')]: {
                height: '40em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        storyTextBox: {
            [theme.breakpoints.up('md')]: {
                order: 1,
            },
            [theme.breakpoints.down('sm')]: {
                order: 2,
            },
        },
        storyTextTitle: {
            fontSize: '3rem',
            color: theme.palette.primary.main,

            [theme.breakpoints.up('md')]: {
                '&>p': {
                    marginTop: '.5em',
                },
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.125rem',
            },
        },
        storyTextDescription: {
            fontSize: '1rem',
            lineHeight: '24px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
            },
        },
        storyVideoBox: {
            [theme.breakpoints.up('md')]: {
                order: 2,
            },
            [theme.breakpoints.down('sm')]: {
                order: 1,
            },
        },
        storyVideo: {
            maxWidth: '100%',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    })
);

function AboutStory(aboutStoryDetails: AboutStoryProps) {
    const classes = useStyles();
    const [viewVideo, setViewVideo] = useState<boolean>(false);
    console.log(aboutStoryDetails);

    useEffect(() => {}, [aboutStoryDetails]);

    const Bold = ({ children }: any) => <span>{children}</span>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderStory = (aboutStoryDetails: AboutStoryProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid container direction="row" className={classes.containerCompress} id="video">
                <Grid item md={6} sm={12} className={clsx(classes.storyTextBox)}>
                    <Box className={classes.storyTextTitle}>
                        <p>{aboutStoryDetails.title}</p>
                    </Box>
                    <Box className={classes.storyTextDescription}>{documentToReactComponents(aboutStoryDetails.description, options)}</Box>
                </Grid>
                <Grid container item md={6} sm={12} className={clsx(classes.storyVideoBox)} justifyContent="center" alignContent="center">
                    {viewVideo ? (
                        <ReactPlayer
                            url={aboutStoryDetails.videoUrl}
                            playing={true}
                            controls={true}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                    },
                                },
                            }}
                        />
                    ) : (
                        <img src={VideoImage} className={classes.storyVideo} alt="video-thumbnail" onClick={() => setViewVideo(true)} />
                    )}
                </Grid>
            </Grid>
        );
        return jsx;
    };
    return <div className={classes.root}>{renderStory(aboutStoryDetails)}</div>;
}

export default AboutStory;
