import React, { Fragment } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface Facts {
    title: string;
    description: string;
}

export interface CareersStatisticsProps {
    title: ContentfulDocument;
    contentTitle: string;
    contentDescription: ContentfulDocument;
    contentImage: string;
    contentTitle2: string;
    contentImage2: string;
    contentDescription2: ContentfulDocument;
    facts: Facts[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // height: '90em',
            [theme.breakpoints.down('sm')]: {
                // height: '135em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        spaceBottom: {
            marginBottom: theme.spacing(1),
        },
        segmentBox: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            
            [theme.breakpoints.up('md')]: {},

            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(0),
            },
        },
        storyTextBox: {
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'flex-start',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),

            [theme.breakpoints.up('md')]: {
                paddingRight: theme.spacing(20),
            },

            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(0),
            },

            '&.bottom': {
                [theme.breakpoints.up('md')]: {
                    paddingRight: theme.spacing(10),
                    paddingLeft: theme.spacing(10),
                },
            },
        },
        storyTextTitle: {
            fontSize: '2.125rem',
            color: 'black',
            lineHeight: '2.25rem',
            paddingBottom: theme.spacing(5),
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.75rem',

                paddingBottom: theme.spacing(2),
            },
        },
        storyTextDescription: {
            fontSize: '1rem',
            lineHeight: '24px',
        },
        storyImageBox: {
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                paddingBottom: theme.spacing(5),
            },
        },
        storyImage: {
            maxWidth: '100%',
            marginBottom: theme.spacing(2),
            boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
        },
        factsBox: {
            textAlign: 'center',
        },
        factsTitle: {
            fontFamily: 'sans-serif',
            fontSize: '4.5rem',
            background: '-webkit-linear-gradient(#8223D2 0%, #3700B3 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            '&.smaller': {
                fontSize: '3rem !important',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '4.25rem',
                '&.smaller': {
                    fontSize: '3rem !important',
                },
            },
        },
        factsDescription: {
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        divider: {
            marginRight: '-1px',
        },
        environmentTextTitle: {
            color: theme.palette.primary.main,
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            marginBottom: theme.spacing(2),
        },
        environmentTextDescription: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        environmentTextTitle2: {
            fontSize: '2.125rem',
            lineHeight: '2.5rem',
            fontWeight: 'bold',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.75rem',
            },
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        swiper: {
            height: '100%',
            '&>.swiper-pagination-progressbar': {
                top: 'initial',
                bottom: 0,
            },
        },
        slideContainer: {
            // height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    })
);

function CareersStatistics(careersStatisticsDetails: CareersStatisticsProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    var factsLength: GridSize;

    if (12 / careersStatisticsDetails.facts.length === 4) {
        factsLength = 4;
    }
    if (12 / careersStatisticsDetails.facts.length === 3) {
        factsLength = 3;
    }
    if (12 / careersStatisticsDetails.facts.length === 2) {
        factsLength = 2;
    }
    if (12 / careersStatisticsDetails.facts.length === 1) {
        factsLength = 1;
    }

    SwiperCore.use([Pagination, Autoplay]);

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderStory = (careersStatisticsDetails: CareersStatisticsProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <div className={clsx(classes.containerCompress)}>
                <Grid container direction="row" className={classes.segmentBox}>
                    <Grid item md={6} sm={12} className={clsx(classes.storyTextBox)} style={{ order: isMobile ? 2 : 1 }}>
                        <Box className={classes.storyTextTitle}>{parse(`${careersStatisticsDetails.contentTitle}`)}</Box>
                        <Box className={classes.storyTextDescription}>{documentToReactComponents(careersStatisticsDetails.contentDescription, options)}</Box>
                    </Grid>
                    <Grid item md={6} sm={12} className={clsx(classes.storyImageBox)} style={{ order: isMobile ? 1 : 2 }}>
                        <Box>
                            <img src={careersStatisticsDetails.contentImage} alt="alt" className={classes.storyImage} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={clsx(!isMobile && classes.containerCompress)}>
                    {isMobile ? (
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            spaceBetween={50}
                            slidesPerView={1}
                            pagination={{
                                type: 'progressbar',
                                clickable: true,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {careersStatisticsDetails.facts.map((fact, i) => (
                                <SwiperSlide className={clsx(classes.slideContainer)}>
                                    <Grid item md={4} sm={12} container direction="column" className={classes.factsBox}>
                                        <Grid item>
                                            <Box>
                                                <span className={clsx(classes.factsTitle, process.env.REACT_APP_LOCALE === 'ko' ? 'smaller' : '')}>{fact.title}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box>
                                                <span className={classes.factsDescription}>{fact.description}</span>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Fragment>
                            {careersStatisticsDetails.facts.map((fact, i) => (
                                <Fragment key={fact.title + i}>
                                    <Grid item md={factsLength} xs={12} container direction="column" className={classes.factsBox}>
                                        <Grid item>
                                            <Box>
                                                <span className={clsx(classes.factsTitle, process.env.REACT_APP_LOCALE === 'ko' ? 'smaller' : '')}>{fact.title}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box>
                                                <span className={classes.factsDescription}>{fact.description}</span>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {i < careersStatisticsDetails.facts.length - 1 && <Divider orientation="vertical" flexItem className={classes.divider}></Divider>}
                                </Fragment>
                            ))}
                        </Fragment>
                    )}
                </Grid>
                <Grid container direction="row" className={classes.segmentBox}>
                    <Grid item md={6} xs={12} className={clsx(classes.storyImageBox)} style={{ order: isMobile ? 2 : 1 }}>
                        <Box className={classes.spaceBottom}>
                            <img src={careersStatisticsDetails.contentImage2} alt="alt" className={classes.storyImage} />
                        </Box>
                        <Box>
                            <Box className={classes.environmentTextTitle}>{careersStatisticsDetails.contentTitle2}</Box>
                            <Box className={classes.environmentTextDescription}>{documentToReactComponents(careersStatisticsDetails.contentDescription2, options)}</Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} className={clsx(classes.storyTextBox, 'bottom')} style={{ order: isMobile ? 1 : 2 }}>
                        <Box className={classes.environmentTextTitle2}>{documentToReactComponents(careersStatisticsDetails.title, options)}</Box>
                    </Grid>
                </Grid>
            </div>
        );
        return jsx;
    };
    return <div className={classes.root}>{renderStory(careersStatisticsDetails)}</div>;
}

export default CareersStatistics;
