import React from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as Dot } from '../../../images/about/TimelineDot.svg';
import parse from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface Experience {
    text: string;
    year: string;
    color: string;
}

export interface AboutTimelineProps {
    experiences: Experience[];
    experienceText: ContentfulDocument;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '40em',
            background: 'rgba(0, 0, 0, 0.02)',
            [theme.breakpoints.down('sm')]: {
                height: '45em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        experienceTextBox: {
            textAlign: 'center',
            width: '100%',
        },
        experienceText: {
            fontSize: '2.125rem',
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        timelineBox: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly',
            textAlign: 'left',
        },
        timelineItem: {},
        timelineItemYear: {
            fontSize: '2.125rem',
            minHeight: theme.spacing(10),
        },
        timelineItemDotBox: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            // marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            '&>.MuiDivider-middle': {
                marginTop: '-27px',
                marginRight: '-40px',
                marginLeft: '30px',
            },
            [theme.breakpoints.down('sm')]: {
                '&>.MuiDivider-middle': {
                    marginRight: '-70px',
                },
            },
        },
        timelineItemDot: {
            background: '#fafafa',
        },
        timelineItemText: {
            fontSize: '1rem',
            minHeight: theme.spacing(16),
            [theme.breakpoints.down('xs')]: {
                minHeight: theme.spacing(16),
            },
        },
        holeCover: {
            background: 'pink',
            height: '25px',
            width: '25px',
            position: 'absolute',
            top: '8px',
            left: '23px',
            zIndex: -1,
        },
        swiper: {
            height: '100%',
            '&>.swiper-pagination-progressbar': {
                top: 'initial',
                bottom: 0,
            },
        },
        slideContainer: {
            // height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        pagination: {
            bottom: 0,
            width: '100%',
            height: '4px',
            left: 0,
            background: 'rgba(0, 0, 0, 0.25)',
            // position: absolute,
        },

        divider: {
            backgroundColor: '#A5AAAF',

            '&.end': {
                backgroundColor: 'transparent',
            },
        },
    })
);

function AboutTimeline(aboutTimelineDetails: AboutTimelineProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },
    };

    const renderTimeline = (aboutTimelineDetails: AboutTimelineProps): JSX.Element => {
        let jsx: JSX.Element;
        const rows = [...Array(Math.ceil(aboutTimelineDetails.experiences.length / 3))];
        const timelineRows = rows.map((row, idx) => aboutTimelineDetails.experiences.slice(idx * 3, idx * 3 + 3));

        jsx = (
            <Grid container direction="row" className={clsx(classes.containerCompress)}>
                <Grid item xs={12} className={classes.experienceTextBox}>
                    <Box className={classes.experienceText}>{documentToReactComponents(aboutTimelineDetails.experienceText, options)}</Box>
                </Grid>
                <Grid item xs={12} className={classes.experienceTextBox}>
                    <Grid container direction="row" className={classes.timelineBox}>
                        {isMobile ? (
                            <Swiper
                                className={clsx(classes.swiper)}
                                slidesPerView={1}
                                pagination={{
                                    type: 'progressbar',
                                    clickable: true,
                                }}
                                observeParents={true}
                                observer={true}
                            >
                                {timelineRows.map((timelineRow, rowIndex) => (
                                    <SwiperSlide className={classes.slideContainer}>
                                        {timelineRow.map((timeline, timelineIndex) => (
                                            <Grid container direction="column" key={timelineIndex} className={classes.timelineItem}>
                                                <Box order={(rowIndex % 2 === 0 && timelineIndex % 2 === 0) || (rowIndex % 2 !== 0 && timelineIndex % 2 === 0) ? 1 : 3}>
                                                    <Box className={classes.timelineItemYear}>{timeline.year}</Box>

                                                    <Box className={classes.timelineItemText}>{timeline.text}</Box>
                                                </Box>
                                                <Box order={(rowIndex % 2 === 0 && timelineIndex % 2 === 0) || (rowIndex % 2 !== 0 && timelineIndex % 2 === 0) ? 3 : 1}>
                                                    <Box className={classes.timelineItemYear}></Box>

                                                    <Box className={classes.timelineItemText}></Box>
                                                </Box>

                                                <Box order={2} className={classes.timelineItemDotBox}>
                                                    <Dot className={classes.timelineItemDot} style={{ fill: timeline.color, zIndex: 5 }} />
                                                    {/* <Box className={classes.holeCover}></Box> */}
                                                    {rowIndex < timelineRows.length - 1 && <Divider variant="middle" />}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            aboutTimelineDetails.experiences.map((experience, i) => (
                                <Grid container direction="column" key={i} className={classes.timelineItem}>
                                    <Box order={i % 2 === 0 ? 1 : 3} minHeight={'300px'}>
                                        <Box className={classes.timelineItemYear}>{experience.year}</Box>

                                        <Box className={classes.timelineItemText}>{experience.text}</Box>
                                    </Box>
                                    <Box order={i % 2 === 0 ? 3 : 1} minHeight={'300px'}>
                                        <Box className={classes.timelineItemYear}></Box>

                                        <Box className={classes.timelineItemText}></Box>
                                    </Box>

                                    <Box order={2} className={classes.timelineItemDotBox} >
                                        <Dot className={classes.timelineItemDot} style={{ fill: experience.color }} />
                                        <Divider variant="middle" className={clsx(classes.divider, i < aboutTimelineDetails.experiences.length - 1 ? '' : 'end')} />
                                    </Box>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderTimeline(aboutTimelineDetails)}</div>;
}

export default AboutTimeline;
