import React, { Fragment, useEffect, useState } from 'react';
import HeroBanner from '../Homepage/HeroBanner';
import HeroDescription from '../Homepage/HeroDescription';
import HeroReviews, { HeroReviewsProps } from '../Homepage/HeroReviews';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';

import { ContentfulConfig, ContentfulLoader, ContentfulReference, ContentfulEntry, ContentfulFields, getContentfulElements } from '../../../utils/ContentfulLoader';
import { useCallback } from 'react';

const pageEntryId: string = process.env.REACT_APP_HOMEPAGE_ENTRY_ID;

function Home() {
    var [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [bannerDetails, setBannerDetails] = useState<ContentfulFields[]>([]);
    const [descriptionDetails, setDescriptionDetails] = useState<ContentfulDocument>();
    const [reviewDetails, setReviewDetails] = useState<HeroReviewsProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [bannerVideo, setBannerVideo] = useState<string>();

    const loadPageContent = useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((content: any) => {
                let bannersReferences: ContentfulReference[] = content.getReferences('banners');
                let bannerVideoReferences: ContentfulReference[] = content.getReferences('bannerVideo');
                let bannersPromise: Promise<ContentfulEntry[]> = getContentfulElements(bannersReferences, contentfulConfig);
                let bannerVideoPromise: Promise<ContentfulEntry> = bannerVideoReferences[0].getData(contentfulConfig);

                Promise.all([bannersPromise])
                    .then((values2: ContentfulEntry[][]) => {
                        let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                        let valueIterations: number = values2[0].length;

                        if (valueIterations < maxIterations) {
                            for (let i = 0; i < valueIterations; i++) {
                                let imageReference: ContentfulReference[] = values2[0][i].getReferences('image');
                                let imageSmallReference: ContentfulReference[] = values2[0][i].getReferences('imageSmall');
                                let imagePromise: Promise<ContentfulEntry> = imageReference[0].getData(contentfulConfig);
                                let imageSmallPromise: Promise<ContentfulEntry> = imageSmallReference[0].getData(contentfulConfig);

                                Promise.all([imagePromise, imageSmallPromise]).then((values3: ContentfulEntry[]) => {
                                    values2[0][i].fields.image = values3[0].fields['file'].url;
                                    values2[0][i].fields.imageSmall = values3[1].fields['file'].url;
                                    setBannerDetails(bannerDetails => [...bannerDetails, values2[0][i].fields]);
                                });
                            }
                            setDescriptionDetails(content.fields.mainText);
                            setReviewDetails({
                                title: content.fields.reviewTitle,
                                ratings: content.fields.reviews,
                            });
                        }
                    })
                    .then(() => {
                        Promise.all([bannerVideoPromise]).then((values4: ContentfulEntry[]) => {
                            setBannerVideo(values4[0].fields['file'].url);
                        });
                    })
                    .finally(() => {
                        setDataLoaded(true);
                    });
            })
            .catch((error: any) => {
                setDataLoaded(true);
            });
    }, [contentfulConfig]);

    const sortBannerDetails = (data: ContentfulFields[]) => {
        data = data.sort((a, b) => a.id.localeCompare(b.id));
        return data;
    };

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <Fragment>
            {(!dataLoaded && <Spinner />) || (
                <Fragment>
                    <HeroBanner bannerDetails={sortBannerDetails(bannerDetails)} bannerVideo={bannerVideo} />
                    <HeroDescription descriptionDetails={descriptionDetails} />
                    <HeroReviews {...reviewDetails} />
                </Fragment>
            )}
        </Fragment>
    );
}

export default Home;
