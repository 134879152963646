import React, { useEffect, useState } from 'react';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';

import CareersStatistics, { CareersStatisticsProps } from './CareersStatistics';
import CareersTestimonials, { Testimonial } from './CareersTestimonials';
import CareersCulture, { CareersCultureProps } from './CareersCulture';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, getContentfulImages, getContentfulElements } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';

//DEV NOTES: WE ARE USING TH SPACE FOR CAREERS BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageEntryId: string = process.env.REACT_APP_CAREERS_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

function CareersPage() {
    // const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [statisticsDetails, setStatisticsDetails] = useState<CareersStatisticsProps>();
    const [testimonialsDetails, setTestimonialsDetails] = useState<Testimonial[]>([]);
    const [cultureContent, setCultureContent] = useState<CareersCultureProps>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    var contentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();

    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let bannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = entry.getReferences('footerBannerPersonImage');
                let storyImageReferences: ContentfulReference[] = entry.getReferences('storyImage');
                let storyImage2References: ContentfulReference[] = entry.getReferences('storyImage2');
                let testimonialsReferences: ContentfulReference[] = entry.getReferences('testimonials');
                let whyTypesIconsReferences: ContentfulReference[] = entry.getReferences('whyTypesIcons');

                let bannerImagePromise: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let bannerImageSmallPromise: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                let storyImage1ImagePromise: Promise<ContentfulEntry> = storyImageReferences[0].getData(contentfulConfig);
                let storyImage2ImagePromise: Promise<ContentfulEntry> = storyImage2References[0].getData(contentfulConfig);
                let testimonialsPromise: Promise<ContentfulEntry[]> = getContentfulElements(testimonialsReferences, contentfulConfig);
                let whyTypesIconsPromise: Promise<ContentfulEntry[]> = getContentfulImages(whyTypesIconsReferences, contentfulConfig);

                Promise.all([bannerImagePromise, bannerImageSmallPromise, footerBannerImagePromise, storyImage1ImagePromise, storyImage2ImagePromise]).then((values: ContentfulEntry[]) => {
                    setHero({ title: entry.fields.bannerText, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[2].fields['file'].url,
                    });
                    setStatisticsDetails({
                        title: entry.fields.description,
                        contentTitle: entry.fields.storyTitle,
                        contentDescription: entry.fields.storyDescription,
                        contentImage: values[3].fields['file'].url,
                        contentTitle2: entry.fields.storyTitle2,
                        contentImage2: values[4].fields['file'].url,
                        contentDescription2: entry.fields.storyDescription2,
                        facts: entry.fields.statistics,
                    });
                });

                Promise.all([testimonialsPromise, whyTypesIconsPromise]).then((values: ContentfulEntry[][]) => {
                    let testimonials: Testimonial | null = null;
                    let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                    let valueIterations: number = values[0].length;
                    let whyTypesIterations: number = entry.fields.whyTypes.length;
                    if (valueIterations < maxIterations) {
                        for (let i = 0; i < valueIterations; i++) {
                            let photoReference: ContentfulReference[] = ContentfulLoader.getReferences(values[0][i], 'photo');
                            let photoPromise: Promise<ContentfulEntry> = photoReference[0].getData(contentfulConfig);

                            Promise.all([photoPromise]).then((values2: ContentfulEntry[]) => {
                                values[0][i].fields['photo'] = values2[0].fields['file'].url;
                                testimonials = {
                                    image: values2[0].fields['file'].url,
                                    headerText: values[0][i].fields.headerText,
                                    quote: values[0][i].fields.quote,
                                    from: values[0][i].fields.fromName,
                                };
                                setTestimonialsDetails(testimonialsDetails => [...testimonialsDetails, testimonials]);
                            });
                        }
                    }
                    if (whyTypesIterations < maxIterations) {
                        for (let i = 0; i < whyTypesIterations; i++) {
                            entry.fields.whyTypes[i]['icon'] = values[1][i] ? values[1][i] : '';
                        }
                    }
                    setCultureContent({
                        title: entry.fields.whyTitle,
                        subtitle: entry.fields.whySubtitle,
                        description: entry.fields.whyDescriptionNew,
                        cultureDetails: entry.fields.whyTypes,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
        // }, [contentfulConfig]);
    }, []);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...hero} />
                    <CareersStatistics {...statisticsDetails} />
                    <CareersTestimonials testimonialsDetails={testimonialsDetails} />
                    <CareersCulture {...cultureContent} />
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </div>
    );
}

export default CareersPage;
