import * as React from 'react';
import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { ProductDetailed } from './types';
import { oneLine } from 'common-tags';
import { rem, rgba, sv } from '../../../common/functions';
import parse from 'html-react-parser';

const useStyle = makeStyles(theme => ({
    cardRoot: {
        width: 320,
        height: 400,
        backgroundColor: rgba(240, 240, 245, 0.5),
        boxShadow: oneLine`
      0px 16px 24px rgba(0, 0, 0, 0.14),
      0px 6px 30px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.2)
    `,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            overflow: 'unset',
        },
    },
    titleContainer: {
        padding: sv(25, 20),
        border: 0,
        borderBottom: 4,
        borderBottomStyle: 'solid',
        borderImage: `linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%) 1`,
        backgroundColor: theme.palette.common.white,
    },
    title: {
        fontSize: rem(1.5),
        lineHeight: 1.25,
    },
    contentContainer: {
        padding: sv(25, 20),
    },
    content: {
        fontSize: rem(1),
        lineHeight: 1.5,
    },
    list: {
        paddingLeft: theme.spacing(2),
    },
    listItem: {
        marginBottom: theme.spacing(2),
        '&:last-child': {
            margin: 0,
        },
    },
}));

export const ProductDetail = (props: ProductDetailed) => {
    const classes = useStyle();

    return (
        <Card
            square
            classes={{
                root: classes.cardRoot,
            }}
        >
            <CardHeader
                title={parse(`${props.title}`)}
                classes={{
                    root: classes.titleContainer,
                    title: classes.title,
                }}
            />
            <CardContent
                classes={{
                    root: classes.contentContainer,
                }}
            >
                <Typography className={classes.content}>
                    <ul className={classes.list}>
                        {props.details.map(detail => (
                            <li className={classes.listItem}>{detail}</li>
                        ))}
                    </ul>
                </Typography>
            </CardContent>
        </Card>
    );
};
