import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

export interface TermsContentProps {
    title: string;
    dateUpdated: string;
    content: ContentfulDocument;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            fontSize: theme.spacing(4.25),
            marginBottom: theme.spacing(1),
            marginTop: 0,
            fontWeight: 'bold',
        },
        containerCompress: {
            padding: theme.spacing(6),
            paddingLeft: '7vw;',
            paddingRight: '7vw',
        },
        date: {
            fontSize: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(1),
            },
        },
        notice: {
            fontSize: theme.spacing(2),
            margin: 0,
        },
        content: {
            fontSize: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
    })
);

function TermsContent(contentDetails: TermsContentProps) {
    const classes = useStyles();
    const Bold = ({ children }: any) => <b className="">{children}</b>;
    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderSection = (termsContent: TermsContentProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid container direction="row" className={classes.containerCompress}>
                <Grid md={4} xs={12}>
                    <p className={classes.title}>{termsContent.title}</p>
                    <p className={classes.date}>{termsContent.dateUpdated}</p>
                    {process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && <Link to="/tha/terms-of-use">View in Thai</Link>}
                    {process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'MY' && <Link to="/bah/terms-of-use">View in Bahasa</Link>}
                </Grid>
                <Grid md={8} xs={12}>
                    <Box>{documentToReactComponents(termsContent.content, options)}</Box>
                </Grid>
            </Grid>
        );

        return jsx;
    };

    return <div className={classes.root}>{renderSection(contentDetails)}</div>;
}

export default TermsContent;
