import React from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const StyledBox = withStyles((theme: Theme) => ({
    root: {
        height: '54em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))(Box);
const TextBox = withStyles((theme: Theme) => ({
    root: {
        textAlign: 'center',
    },
}))(Box);

const StyledTypography = withStyles((theme: Theme) => ({
    root: {
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '2em',
    },
}))(Typography);

const StyledLink = withStyles((theme: Theme) => ({
    root: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#8223d2 !important',
    },
}))(Link);

function NoMatch() {
    return (
        <StyledBox>
            <TextBox>
                <StyledTypography variant="h1">The page you are looking for cannot be found.</StyledTypography>
                <StyledLink href="/">Go back to home</StyledLink>
            </TextBox>
        </StyledBox>
    );
}

export default NoMatch;
