import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { vw } from '../../../../common/functions';

export interface ImageProps {
    src?: string;
}

const useStyle = makeStyles(theme => ({
    img: {
        [theme.breakpoints.down('xs')]: {
            width: vw(80),
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

export const Image = (props: ImageProps) => {
    const classes = useStyle();
    return <img src={props.src} alt={props.src} className={classes.img} />;
};

export default Image;
