import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Box,
    TextField,
    FormControl,
    Input,
    Button,
    Modal,
    Fade,
    Backdrop,
    FormHelperText,
    Hidden,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Typography,
    IconButton,
} from '@material-ui/core';
import { ReactComponent as CloseIconWhite } from '../../../images/leadership/close-white.svg';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sendEmail, SendMessageParameters, EmailProps } from '../../../actions/emailSender';
// import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import ThankYouIcon from '../../../images/contact/thankyou-icon.svg';
import { ReactComponent as CircledArrowIcon } from '../../../images/common/circledarrow.svg';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';
import { createStyles, makeStyles, Theme, withStyles, useTheme } from '@material-ui/core/styles';

export interface ButtonChoices {
    text: string;
    value: string;
}

export interface FormLabels {
    help: string;
    more: string;
    email: string;
    contact: string;
    subject: string;
}

export interface ContactFormProps {
    address: string[];
    email: string[];
    buttonChoices: ButtonChoices[];
    formLabels: FormLabels;
    title: string;
    officeLocationTitle: string;
    askUsTitle: string;
    askUsDescription: ContentfulDocument;
    submitFormButtonText: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '20em',
        },
        h100: {
            height: '100% !important',
        },
        containerCompress: {
            paddingLeft: '7.5vw;',
            paddingRight: '7.5vw',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        textPrimary: {
            color: theme.palette.primary.main + ` !important`,
            textDecoration: 'none',
        },
        spaceBelow: {
            marginBottom: theme.spacing(2),
        },
        titleBox: {
            fontSize: '2.125rem',
            lineHeight: '2.5rem',
            paddingRight: theme.spacing(3),

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.875rem',
                padding: 0,
            },
        },
        subtitleBox: {
            fontSize: theme.spacing(3),
            marginBottom: theme.spacing(2),
            color: 'black',
        },
        addressBox: {
            '&.bold': {
                fontWeight: 'bold',
            },
            paddingRight: theme.spacing(3),
        },
        label: {
            fontSize: theme.spacing(3),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(2),
            },
        },
        buttonBox: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        margin: {
            marginBottom: theme.spacing(5),
        },
        submitBox: {
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
            },
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#F0F0F5',
            boxShadow: theme.shadows[5],
            // height: '60%',
            // width: '75%',
            height: '600px',
            width: '1000px',

            [theme.breakpoints.down('sm')]: {
                width: '95%',
                height: '60%',
            },
        },
        modalContentBox: {
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            textAlign: 'center',
        },
        modalImageBox: {
            // width: 'auto',

            '&>img': {
                width: '100%',
            },
        },
        errorText: {
            color: 'red',
        },
        detailBox: {
            order: 1,

            [theme.breakpoints.down('sm')]: {
                order: 2,
            },
        },
        formBox: {
            order: 2,

            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(5),
                order: 1,
            },
        },
        accordion: {
            boxShadow: 'none',
            background: 'none',
            // height: '275px',
            marginBottom: theme.spacing(2),
            '&>.MuiButtonBase-root': {
                padding: 0,
            },
        },
        accordionDetails: {
            padding: 0,
        },
        expandIcon: {
            transform: 'rotate(90deg)',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        buttonText: {
            textTransform: 'initial',
            paddingTop: '5px',
        },
        modalHeader: {
            position: 'absolute',
            right: 0,
            top: '70px',
            stroke: 'white',
            '&span>svg>circle': {
                stroke: 'white',
            },
            '&span>svg>circle>path': {
                fill: 'white',
            },
        },
        askUsDescriptionBox: {
            '&>span>a': {
                textDecoration: 'none',
            },
        },
    })
);

const SelectButton = withStyles((theme: Theme) => ({
    root: {
        color: 'black',
        borderRadius: '2em',
        border: '2px solid #A5AAAF',
        backgroundColor: 'transparent',
        fontSize: theme.spacing(2),
        textTransform: 'capitalize',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '&:hover': {
            borderColor: '#8223d2',
            color: '#8223d2',
            background: 'none',
        },
        '&.selected': {
            borderColor: '#8223d2',
            color: '#8223d2',
            background: 'none',
        },
    },
}))(Button);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        borderRadius: '2em',
        backgroundColor: '#8223d2',
        '&:hover': {
            backgroundColor: '#8223d2',
        },
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
}))(Button);

export interface FormState {
    topic: string;
    subject: string;
    email: string;
    contact: string;
    description: string;
}

interface FormValid {
    topic: boolean;
    subject: boolean;
    email: boolean;
    contact: boolean;
    description: boolean;
}

interface ValidationRules {
    required: boolean;
    minLength: number;
    maxLength: number;
    pattern?: RegExp;
}
interface ValueErrorMessages {
    topic: string[];
    subject: string[];
    email: string[];
    contact: string[];
    description: string[];
}

interface ValueValidationRules {
    topic: ValidationRules;
    subject: ValidationRules;
    email: ValidationRules;
    contact: ValidationRules;
    description: ValidationRules;
}

const valueValidationRules: ValueValidationRules = {
    topic: { required: true, minLength: 1, maxLength: 100, pattern: null },
    subject: { required: true, minLength: 2, maxLength: 100, pattern: null },
    email: {
        required: true,
        minLength: 5,
        maxLength: 320,
        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    contact: { required: false, minLength: 0, maxLength: 31, pattern: /^[0-9\.\-\+]+$/ },
    description: { required: true, minLength: 2, maxLength: 3000, pattern: null },
};

function ContactForm(formDetails: ContactFormProps) {
    const [selected, setSelected] = useState<string | null>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [blanketFormValidCheck, setBlanketFormValidCheck] = useState<boolean>(false);
    const [expand, setExpand] = useState<boolean>(false);
    const [formValidCheck, setFormValidCheck] = useState<FormValid>({
        topic: false,
        subject: false,
        email: false,
        contact: false,
        description: false,
    });
    const [formValues, setFormValues] = useState<FormState>({
        topic: '',
        subject: '',
        email: '',
        contact: '',
        description: '',
    });
    const [formValuesValid, setFormValuesValid] = useState<FormValid>({
        topic: false,
        subject: false,
        email: false,
        contact: false,
        description: false,
    });
    const [errorMessages, setErrorMessages] = useState<ValueErrorMessages>({
        topic: ['This is required'],
        subject: ['This is required'],
        email: ['This is required'],
        contact: [],
        description: ['This is required'],
    });

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <b className={classes.textPrimary}>{children}</b>;
    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const asterisk = <span className={classes.textPrimary}>*</span>;

    const handleChange = (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({ ...formValues, [prop]: event.target.value });
        setBlanketFormValidCheck(false);
        validateProp(prop, event);
    };

    const validateProp = (prop: keyof FormState, event: React.ChangeEvent<HTMLInputElement>) => {
        let propRequired: boolean = valueValidationRules[prop].required;
        let propMinLength: number = valueValidationRules[prop].minLength;
        let propMaxLength: number = valueValidationRules[prop].maxLength;
        let propPattern: RegExp = valueValidationRules[prop].pattern;
        let requiredMessage: string = `This is required`;
        let minLengthMessage: string = `Value is too short`;
        let maxLengthMessage: string = `Value is too long`;
        let patternMessage: string = `${[prop]} is not valid`;
        let messages: string[] = errorMessages[prop];
        let requiredValid: boolean = false;
        let minLengthValid: boolean = false;
        let maxLengthValid: boolean = false;
        let patternValid: boolean = false;
        let keyValid: boolean = false;

        if (event.target.value.length < propMinLength) {
            minLengthValid = false;
            if (messages.indexOf(minLengthMessage) === -1) {
                messages.push(minLengthMessage);
            }
        } else if (event.target.value.length >= propMinLength) {
            minLengthValid = true;
            if (messages.indexOf(minLengthMessage) !== -1) {
                messages = messages.filter(message => message !== minLengthMessage);
            }
        }

        if (event.target.value.length > propMaxLength) {
            maxLengthValid = false;
            if (messages.indexOf(maxLengthMessage) === -1) {
                messages.push(maxLengthMessage);
            }
        } else if (event.target.value.length <= propMaxLength) {
            maxLengthValid = true;
            if (messages.indexOf(maxLengthMessage) !== -1) {
                messages = messages.filter(message => message !== maxLengthMessage);
            }
        }

        if (propPattern !== null && event.target.value.length > 0) {
            if (propPattern.test(event.target.value)) {
                patternValid = true;
                if (messages.indexOf(patternMessage) !== -1) {
                    messages = messages.filter(message => message !== patternMessage);
                }
            } else if (!propPattern.test(event.target.value) && event.target.value.length > 0) {
                patternValid = false;
                if (messages.indexOf(patternMessage) === -1) {
                    messages.push(patternMessage);
                }
            }
        } else {
            // if (event.target.id === 'form-contact') {
            //     console.log('isPossiblePhoneNumber ' + isPossiblePhoneNumber(event.target.value));
            //     console.log('isValidPhoneNumber ' + isValidPhoneNumber(event.target.value));
            //     console.log('validatePhoneNumberLength ' + validatePhoneNumberLength(event.target.value));
            // }
            patternValid = true;
            if (messages.indexOf(patternMessage) !== -1) {
                messages = messages.filter(message => message !== patternMessage);
            }
        }

        if (propRequired && event.target.value.length === 0) {
            messages = [];
            requiredValid = false;
            if (messages.indexOf(requiredMessage) === -1) {
                messages.push(requiredMessage);
            }
        } else {
            requiredValid = true;
            if (messages.indexOf(requiredMessage) !== -1) {
                messages = messages.filter(message => message !== requiredMessage);
            }
        }

        keyValid = requiredValid && minLengthValid && maxLengthValid && patternValid;
        setFormValidCheck({ ...formValidCheck, [prop]: true });
        setErrorMessages({ ...errorMessages, [prop]: messages });
        setFormValuesValid({ ...formValuesValid, [prop]: keyValid });
    };

    const handleButtonSelect = (value: string) => () => {
        setFormValues({ ...formValues, topic: value });
        setSelected(value);
        setFormValuesValid({ ...formValuesValid, topic: value.length > 0 });
        setErrorMessages({ ...errorMessages, topic: [] });
    };

    const handleThanksModal = () => {
        setShowModal(!showModal);
    };

    const resetValues = () => {
        setFormValues({
            topic: '',
            subject: '',
            email: '',
            contact: '',
            description: '',
        });
        setSelected(null);
        setFormValuesValid({
            topic: false,
            subject: false,
            email: false,
            contact: false,
            description: false,
        });
        setFormValidCheck({
            topic: false,
            subject: false,
            email: false,
            contact: false,
            description: false,
        });
        setErrorMessages({
            topic: ['This is required'],
            subject: ['This is required'],
            email: ['This is required'],
            contact: [''],
            description: ['This is required'],
        });
        setBlanketFormValidCheck(false);
    };

    const handleSubmit = async () => {
        setShowLoading(true);
        let emailParams: SendMessageParameters = {
            MessageType: 'EMAIL',
            ClientName: 'australia_digital',
            TemplateName: process.env.REACT_APP_LOCALE === 'ko' ? 'CORPSITE_CONTACT_ASURION_KR' : 'CORPSITE_CONTACT_ASURION',
            ReferenceType: 'MDN',
            ReferenceNBR: '',
            Subject: formValues.subject,
            Language: 'en-US',
            ExtendedDynamicValues: {
                Customer_Email: formValues.email,
                Message_Category: formValues.topic,
                Contact_Number: formValues.contact,
                Tell_Us: formValues.description,
                Site_Source: window.location.hostname,
            },
        };

        let sendMessageParameters: EmailProps = {
            SendMessageParameters: emailParams,
        };
        let res = await sendEmail(sendMessageParameters);

        if (res.toString() === '200') {
            setShowModal(!showModal);
            resetValues();
        } else {
            alert('An Error Has Occurred');
        }
        setShowLoading(false);
    };

    const validateForm = () => {
        let valid: boolean = formValuesValid.topic && formValuesValid.subject && formValuesValid.email && formValuesValid.description;
        console.log('I AM FORM VALID ', valid);
        setBlanketFormValidCheck(true);
        return valid;
    };

    const renderSection = (formDetails: ContactFormProps): JSX.Element => {
        let jsx: JSX.Element;
        jsx = (
            <Grid container direction="row" className={clsx(classes.root, classes.containerCompress)}>
                <Grid item md={3} xs={12} container direction="column" className={classes.detailBox}>
                    <Hidden xsDown>
                        <Box className={clsx(classes.titleBox, classes.spaceBelow)}>
                            <b>{parse(`${formDetails.title}`)}</b>
                        </Box>
                    </Hidden>
                    <Box className={clsx(classes.subtitleBox, classes.textPrimary)}>{formDetails.officeLocationTitle}</Box>
                    <Box className={classes.spaceBelow}>
                        {formDetails.address.map((address, i) => (
                            <Box className={clsx(classes.addressBox, i === 0 && 'bold')}>{parse(`${address}`)}</Box>
                        ))}
                    </Box>
                    {/* <Box className={clsx(classes.subtitleBox, classes.textPrimary)}>Email Us</Box>
                    <Box className={classes.spaceBelow}>
                        {formDetails.email.map((email, i) => (
                            <Box>{email}</Box>
                        ))}
                    </Box> */}
                    <Box className={clsx(classes.subtitleBox, classes.textPrimary)}>{formDetails.askUsTitle}</Box>
                    <Box className={classes.askUsDescriptionBox}>{documentToReactComponents(formDetails.askUsDescription, options)}</Box>
                </Grid>
                <Grid item md={9} xs={12} container direction="column" className={classes.formBox}>
                    <Grid container direction="column">
                        <Grid>
                            {isMobile ? (
                                <Fragment>
                                    <Accordion expanded={expand} onChange={() => setExpand(!expand)} className={classes.accordion}>
                                        <AccordionSummary expandIcon={<CircledArrowIcon className={classes.expandIcon} />} aria-controls="panel1bh-content" id="panel1bh-header">
                                            <Box className={classes.label}>{formDetails.formLabels.help}</Box>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container direction="row" className={classes.buttonBox}>
                                                {formDetails.buttonChoices.map(button => (
                                                    <Fragment>
                                                        <SelectButton onClick={handleButtonSelect(button.value)} className={clsx(selected === button.value ? 'selected' : '')}>
                                                            <Typography className={classes.buttonText}>{button.text}</Typography>
                                                        </SelectButton>
                                                    </Fragment>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Grid xs={12}>
                                        {!formValuesValid.topic && (formValidCheck.topic || blanketFormValidCheck) && <FormHelperText className={classes.errorText}>This is required</FormHelperText>}
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Box className={classes.label}>{formDetails.formLabels.help}</Box>
                                    <Grid container direction="row" className={classes.buttonBox}>
                                        {formDetails.buttonChoices.map(button => (
                                            <Fragment>
                                                <SelectButton onClick={handleButtonSelect(button.value)} className={clsx(selected === button.value ? 'selected' : '')}>
                                                    <Typography className={classes.buttonText}>{button.text}</Typography>
                                                </SelectButton>
                                            </Fragment>
                                        ))}
                                        <Grid xs={12}>
                                            {!formValuesValid.topic && (formValidCheck.topic || blanketFormValidCheck) && (
                                                <FormHelperText className={classes.errorText}>This is required</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                        <Grid>
                            <div>
                                <form autoComplete="off">
                                    <FormControl fullWidth className={classes.margin}>
                                        <Box margin="dense" className={classes.label}>
                                            {formDetails.formLabels.subject}
                                            {asterisk}
                                        </Box>
                                        <Input
                                            id="form-subject"
                                            value={formValues.subject}
                                            onChange={handleChange('subject')}
                                            inputProps={{
                                                maxlength: valueValidationRules.subject.maxLength + 1,
                                            }}
                                        />
                                        {!formValuesValid.subject &&
                                            (formValidCheck.subject || blanketFormValidCheck) &&
                                            errorMessages.subject.map(message => <FormHelperText className={classes.errorText}>{message}</FormHelperText>)}
                                    </FormControl>
                                    <FormControl fullWidth className={classes.margin}>
                                        <Box margin="dense" className={classes.label}>
                                            {formDetails.formLabels.email}
                                            {asterisk}
                                        </Box>
                                        <Input
                                            id="form-email"
                                            value={formValues.email}
                                            onChange={handleChange('email')}
                                            inputProps={{
                                                maxlength: valueValidationRules.email.maxLength + 1,
                                            }}
                                        />
                                        {!formValuesValid.email &&
                                            (formValidCheck.email || blanketFormValidCheck) &&
                                            errorMessages.email.map(message => <FormHelperText className={classes.errorText}>{message}</FormHelperText>)}
                                    </FormControl>
                                    <FormControl fullWidth className={classes.margin}>
                                        <Box margin="dense" className={classes.label}>
                                            {formDetails.formLabels.contact}
                                        </Box>
                                        <Input
                                            id="form-contact"
                                            value={formValues.contact}
                                            onChange={handleChange('contact')}
                                            inputProps={{
                                                maxlength: valueValidationRules.contact.maxLength + 1,
                                            }}
                                        />
                                        {!formValuesValid.contact &&
                                            (formValidCheck.contact || blanketFormValidCheck) &&
                                            errorMessages.contact.map(message => <FormHelperText className={classes.errorText}>{message}</FormHelperText>)}
                                    </FormControl>
                                    <FormControl fullWidth className={classes.margin}>
                                        <Box margin="dense" className={classes.label}>
                                            {formDetails.formLabels.more}
                                            {asterisk}
                                        </Box>
                                        <TextField
                                            multiline
                                            rows={5}
                                            id="form-description"
                                            value={formValues.description}
                                            onChange={handleChange('description')}
                                            inputProps={{
                                                maxlength: valueValidationRules.description.maxLength + 1,
                                            }}
                                        />
                                        {!formValuesValid.description &&
                                            (formValidCheck.description || blanketFormValidCheck) &&
                                            errorMessages.description.map(message => <FormHelperText className={classes.errorText}>{message}</FormHelperText>)}
                                    </FormControl>
                                    <Box className={classes.submitBox}>
                                        <ColorButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                let formValid = validateForm();
                                                if (formValid === true) {
                                                    handleSubmit();
                                                }
                                            }}
                                        >
                                            {formDetails.submitFormButtonText}
                                        </ColorButton>
                                    </Box>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showModal}
                    onClose={handleThanksModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showModal}>
                        <div className={classes.paper} onClick={handleThanksModal}>
                            <Grid container direction="column" className={clsx(classes.modalContentBox, classes.containerCompress)}>
                                {isMobile && (
                                    <IconButton onClick={handleThanksModal} className={classes.modalHeader}>
                                        <CloseIconWhite />
                                    </IconButton>
                                )}

                                <Box className={clsx(classes.titleBox, classes.textPrimary)}>
                                    Thank you for contacting us! <br />
                                    We will get back to you as soon as possible.
                                </Box>
                                <Box className={classes.modalImageBox}>
                                    <img src={ThankYouIcon} alt="thank you" />
                                </Box>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <Backdrop className={classes.backdrop} open={showLoading}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(formDetails)}</div>;
}

export default ContactForm;
