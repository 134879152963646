import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import parse from 'html-react-parser';

const Bold = ({ children }) => (
  <Typography component="span" >
      {children}
  </Typography>
);
const Italic = ({ children }) => (
  <Typography component="span">
      {children}
  </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

export const options = {
  renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
      [MARKS.CODE]: (text) => parse(`${text}`),
  },
  renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
          node.content.some(
              (childNode) => childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
          ) ? (
              children
          ) : (
              <Text>{children}</Text>
          ),
  },
};


const ToastBar = ({ toastBarInfo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
      <Box bgcolor="#3863ff" textAlign="center" color="white" fontSize={isMobile ? '1rem' : '1.5rem'} display="flex" flexDirection="row" justifyContent="center" alignItems="center" padding="8px" style={{ color: 'white'}}>
          <Typography component="span" color="primary">
            {toastBarInfo && documentToReactComponents(JSON.parse(toastBarInfo ?? ''), options)}
          </Typography>
      </Box>
  );
}

export default ToastBar