import React, { useEffect } from 'react';
import Spinner from './Spinner';

function PageRedirect({ url }) {
    useEffect(() => {
        window.location.href = url || 'https://b2bypinkezz.bitbucket.io/asurion-event/';
    });

    return <Spinner />;
}

export default PageRedirect;
