import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, CardHeader, CardMedia } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactCardFlip from 'react-card-flip';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import { ReactComponent as LocationIcon } from '../../../images/about/location-icon.svg';
// import { ReactComponent as LetterIcon } from '../../../images/about/letter-icon.svg';
import { ReactComponent as WebIcon } from '../../../images/about/web-icon.svg';
import { ReactComponent as CircledArrowIcon } from '../../../images/common/circledarrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

export interface CountryDetails {
    name: string;
    address: ContentfulDocument;
    email: string;
    website: string;
    image: string;
    index: number;
}

export interface HeaderText {
    text: string;
    color: string;
}

export interface AboutAddressesProps {
    headerText: ContentfulDocument;
    countries: CountryDetails[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '650px',
            [theme.breakpoints.down('sm')]: {
                height: '750px',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(10),
                paddingLeft: '7vw',
            },
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        centerContents: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sectionBox: {
            height: '472px',
        },
        infoBox: {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-around',
        },
        titleBox: {
            fontSize: '2.125rem',
            fontWeight: 'bold',
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(5),
                textAlign: 'center',
                fontSize: '1.5rem',
            },
            margin: 0,
        },
        navigationBox: {
            position: 'relative',
        },
        swiperContainer: {
            width: '100%',
        },
        swiper: {
            height: '100%',
            '&>.swiper-wrapper': {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: '1em',
                },
            },
        },
        slideContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paginationMod: {
            [theme.breakpoints.up('md')]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
                display: 'none',
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: '#D5D6DA !important',
            margin: '0px 10px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#8223D2 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
        previousButton: {
            marginRight: theme.spacing(1),
            '&:hover': {
                fill: theme.palette.primary.main,
                color: 'white',
                '&>g>path': {
                    fill: 'white',
                },
            },
        },
        nextButton: {
            transform: 'scaleX(-1)',
            '&:hover': {
                fill: theme.palette.primary.main,
                color: 'white',
                '&>g>path': {
                    fill: 'white',
                },
            },
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        card: {
            width: '250px',
            height: '400px',
            boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
            marginBottom: theme.spacing(5),

            '&:hover': {
                cursor: 'pointer',
            },

            '&.selected': {
                [theme.breakpoints.up('md')]: {
                    width: '295px',
                    height: '472px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
        },
        cardHeader: {
            borderLeft: '5px solid',
            borderColor: theme.palette.primary.main,
            '&.selected': {
                background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)',
                color: 'white',
                borderLeft: 0,
            },
        },
        cardLink: {
            textDecoration: 'none',
            color: theme.palette.primary.main,
        },
        imageBox: {
            padding: 0,
            paddingTop: theme.spacing(2),
        },
        image: {
            height: '340px',
            backgroundPosition: 'bottom center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        contentBox: {
            padding: theme.spacing(2),
        },
        addressBox: {
            height: theme.spacing(20),
        },
        emailBox: {
            height: theme.spacing(7),
        },
        websiteBox: {
            height: theme.spacing(7),
            color: theme.palette.primary.main,
        },
        address: {
            fontSize: '.75rem',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
    })
);

function AboutAddresses(addressDetails: AboutAddressesProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
    const [, setLoadAddresses] = useState<boolean>(true);

    const renderAddresses = () => {
        setTimeout(() => {
            setLoadAddresses(false);
        }, 10);
    };

    useEffect(() => {
        renderAddresses();
    }, [renderAddresses]);

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    SwiperCore.use([Navigation, Pagination, Autoplay]);

    let swiper = new SwiperCore('.swiper-container', {
        // spaceBetween: 1,
        slidesPerView: isMobile ? 1 : 3,
        navigation: {
            nextEl: '.swiper-button-next-unique',
            prevEl: '.swiper-button-prev-unique',
        },
        observeParents: true,
        observer: true,
    });

    const handleSelect = (value: number) => () => {
        if (value === selectedCountry) {
            setSelectedCountry(null);
            return;
        }
        setSelectedCountry(value);
    };

    // alert(addressDetails.countries.length);

    return (
        <Grid container direction="row" className={clsx(classes.root, classes.containerCompress)}>
            <Grid item md={3} sm={12} className={classes.infoBox}>
                <Box component="p" className={classes.titleBox}>
                    {documentToReactComponents(addressDetails.headerText, options)}
                </Box>
                {!isMobile && (
                    <Grid container direction="row" className={classes.navigationBox}>
                        <Grid className="swiper-button-prev-unique">
                            <CircledArrowIcon className={classes.previousButton} />
                        </Grid>
                        <Grid className="swiper-button-next-unique">
                            <CircledArrowIcon className={classes.nextButton} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item md={9} sm={12} className={classes.swiperContainer}>
                <Swiper
                    className={clsx(classes.swiper)}
                    autoplay={isMobile ? { delay: 2500, disableOnInteraction: true, pauseOnMouseEnter: true } : false}
                    pagination={{
                        clickable: true,
                        bulletClass: `` + classes.paginationBullet + ``,
                        bulletActiveClass: `` + classes.paginationBulletActive + ``,
                        clickableClass: `` + classes.paginationMod + ``,
                    }}
                    // observeParents={true}
                    // observer={true}
                >
                    <Grid container direction="row" className={classes.sectionBox}>
                        {addressDetails.countries.map((country, i) => (
                            <SwiperSlide className={classes.slideContainer}>
                                <Box onClick={handleSelect(i)} padding={0}>
                                    <ReactCardFlip isFlipped={selectedCountry !== i} flipDirection="horizontal">
                                        <Card className={clsx(classes.card, selectedCountry === i ? 'selected' : '')} variant="outlined" onClick={handleSelect(i)}>
                                            <CardHeader title={country.name} className={clsx(classes.cardHeader, 'selected')} />
                                            <CardContent className={classes.contentBox}>
                                                <Grid container direction="row">
                                                    <Grid item xs={2}>
                                                        <LocationIcon />
                                                    </Grid>
                                                    <Grid item xs={10} className={classes.addressBox}>
                                                        {documentToReactComponents(country.address, options)}
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" className={classes.websiteBox}>
                                                    <Grid item xs={2}>
                                                        <WebIcon />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Fragment>
                                                            <a href={country.website} target="_blank" rel="noreferrer" className={classes.cardLink}>
                                                                <Typography variant="h5" className={classes.address}>
                                                                    {country.website}
                                                                </Typography>
                                                            </a>
                                                        </Fragment>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        <Card className={clsx(classes.card, selectedCountry === i ? 'selected' : '')} variant="outlined" onClick={handleSelect(i)}>
                                            <CardHeader title={country.name} className={clsx(classes.cardHeader)} />
                                            <CardContent className={classes.imageBox}>
                                                <CardMedia className={classes.image} image={country.image} />
                                            </CardContent>
                                        </Card>
                                    </ReactCardFlip>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Grid>
                </Swiper>
            </Grid>
        </Grid>
    );
}

export default AboutAddresses;
