import { createTheme } from '@material-ui/core';
import { BASE_WIDTH } from '../common/constants';
import { rem } from '../common/functions';

export const sizes = {
    baseWidth: BASE_WIDTH,
};

export const colors = {
    lavender: '#EFEBFD',
    ghostWhite: '#F2F2FA',
    indigo: '#3700B3',
};

export const theme = createTheme({
    palette: {
        primary: {
            main: '#8223d2',
        },
        secondary: {
            main: '#bb86fc',
        },
        grey: {
            A100: '#D5D6DA',
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Apercu Pro', 'sans-serif'].join(','),
        h3: {
            fontWeight: 'bold',
            fontSize: rem(2.125),
        },
        subtitle1: {
            fontSize: rem(1.25),
            lineHeight: 1.2,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                padding: '16px 20px',
                borderRadius: 28,
            },
            label: {
                fontSize: rem(0.875),
                lineHeight: 8 / 7,
                letterSpacing: rem(1.25 / 16),
            },
        },
    },
    props: {
        MuiUseMediaQuery: {
            noSsr: true,
        },
    },
});

theme.typography.h3 = {
    ...theme.typography.h3,
    [theme.breakpoints.down('xs')]: {
        fontSize: rem(1.5),
        lineHeight: 7 / 6,
        letterSpacing: rem(0.18 / 16),
    },
};

theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    [theme.breakpoints.down('xs')]: {
        fontSize: rem(1.25),
        lineHeight: 1.2,
    },
};

export default theme;
