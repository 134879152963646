import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { BASE_WIDTH } from '../../../common/constants';
import { rem } from '../../../common/functions';
import { oneLine } from 'common-tags';
import { colors } from '../../../themes/default';
import parse from 'html-react-parser';

import iPhoneBackground from '../../../images/what-we-do/iPhone-background.png';
import SamsungBackground from '../../../images/what-we-do/Samsung-background.png';
import iPhoneBackgroundSmall from '../../../images/what-we-do/iPhone-background-small.png';
import SamsungBackgroundSmall from '../../../images/what-we-do/Samsung-background-small.png';

export interface LeadProps {
    title: string;
    content: string;
}

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 37,
        textAlign: 'center',
        whiteSpace: 'break-spaces',
        padding: `81px ${(190 / BASE_WIDTH) * 100}vw 62px`,
        backgroundColor: colors.lavender,
        background: oneLine`
      url(${iPhoneBackground}) left bottom no-repeat,
      url(${SamsungBackground}) right bottom no-repeat
    `,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: colors.ghostWhite,
            padding: '50px 18px 160px',
            lineHeight: 1.25,
            gap: 20,
            background: oneLine`
        url(${iPhoneBackgroundSmall}) left bottom no-repeat,
        url(${SamsungBackgroundSmall}) right bottom no-repeat
      `,
        },
    },
    title: {
        fontWeight: 'normal',
        lineHeight: 18 / 17,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            lineHeight: 1.25,
        },
    },
    content: {
        padding: `0 100px 0`,
        letterSpacing: rem(0.15 / 16),
        [theme.breakpoints.down('xs')]: {
            padding: '0 20px 0',
            fontSize: rem(1),
            lineHeight: 1.5,
        },
    },
}));

export const Lead = (props: LeadProps) => {
    const classes = useStyle();

    return (
        <Box className={classes.root}>
            <Typography variant="h3" className={classes.title}>
                {parse(props.title)}
            </Typography>
            <Typography variant="subtitle1" className={classes.content}>
                {parse(props.content)}
            </Typography>
        </Box>
    );
};

export default Lead;
