import React, { useEffect } from 'react';
import { Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CookieIcon } from './CookieIcon/cookie.svg'
 
const useStyles = makeStyles((theme) => ({
  floatButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    zIndex: 10000,
    height: 'auto',
    border: '1px solid #8223d2 !important',
    padding: '12px 15px !important',
    backgroundColor: '#8223d2',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#7d1dcf !important',
    }
  },
  cookieIcon: {
    width: 33,
    height: 33,
  },
  
}));
const allowedRegions = ['AU', 'SG', 'TW'];
const CookieConsent = () => {
 
  const classes = useStyles();
 
  const region = process.env.REACT_APP_COUNTRY_CODE|| 'default';
  const domainScript = process.env.REACT_APP_COOKIE_DATA_DOMAIN_SCRIPT
 
 
  useEffect(() => {
    console.log(domainScript)
  
 
    if(!region || !domainScript || !allowedRegions.includes(region)) return;
 
    const script = document.createElement('script')
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    script.charset = 'UTF-8'
    script.setAttribute('data-domain-script', domainScript)
    script.type = 'text/javascript'
    document.head.appendChild(script)
 
    function OptanonWrapper (){
            
    }
    
      return () => {
        document.head.removeChild(script)
      }
 
    },[region, domainScript])
    
  return (
    <>
      {allowedRegions.includes(region) && domainScript && (
        <Button variant='contained' id="ot-sdk-btn" className={`ot-sdk-show-settings ${classes.floatButton}`}>
          <CookieIcon className={classes.cookieIcon} />
        </Button>
      )}
    </>
  )
}
 
export default CookieConsent