import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import PurpleBackground from '../../images/common/PurpleBackground.jpg';
import SmallBackground from '../../images/common/SmallBackground.jpg';
import { ReactComponent as ABackground } from '../../images/common/a-bg.svg';

export interface footerDescriptionText {
    text: string;
    color: string;
}

export interface FooterBannerProps {
    bannerTitle: string;
    bannerDescription: ContentfulDocument | any;
    bannerImage: string;
    buttonText: string;
    buttonLink: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '28.5em',

            [theme.breakpoints.up('md')]: {
                background: 'url(' + PurpleBackground + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            },
            [theme.breakpoints.down('sm')]: {
                height: '23em',
                background: 'url(' + SmallBackground + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '7.5vw;',
                paddingRight: '7.5vw',
            },
        },
        imageBox: {
            textAlign: 'center',
        },
        imageWrapper: {
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        image: {
            height: '100%',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
        },
        aBackground: {
            position: 'absolute',
            maxWidth: '71%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        contentBox: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'left',
            color: 'white',

            [theme.breakpoints.up('md')]: {
                marginLeft: '-50px',
            },
        },
        bannerTitle: {
            fontSize: '3rem',
            fontWeight: 'bold',
            lineHeight: '3.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.125rem',
                lineHeight: '2.25rem',
            },
        },
        bannerDescription: {
            fontSize: '2.125rem',
            lineHeight: '2.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
            },
        },
        bannerDescriptionBold: {
            color: '#37E7A7',
        },
        link: {
            textDecoration: 'none',
        },
    })
);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderRadius: '2em',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textDecoration: 'none',
    },
}))(Button);

function FooterBanner(bannerDetails: FooterBannerProps) {
    const classes = useStyles();

    const Bold = ({ children }: any) => <span className={classes.bannerDescriptionBold}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderBanner = (bannerDetails: FooterBannerProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid container direction="row" className={clsx(classes.h100)}>
                <Grid item md={7} className={classes.imageBox}>
                    <ABackground className={classes.aBackground} />

                    <Box className={classes.imageWrapper}>
                        <img src={bannerDetails.bannerImage} alt={bannerDetails.bannerTitle} className={classes.image} />
                    </Box>
                </Grid>
                <Grid item md={5} container direction="column" className={clsx(classes.contentBox, classes.containerCompress)}>
                    <Box className={classes.bannerTitle}>{parse(bannerDetails.bannerTitle)}</Box>
                    <Box className={classes.bannerDescription}>{documentToReactComponents(bannerDetails.bannerDescription, options)}</Box>
                    <Box>
                        <Link to={bannerDetails.buttonLink} className={classes.link}>
                            <ColorButton size="large">{bannerDetails.buttonText}</ColorButton>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderBanner(bannerDetails)}</div>;
}

export default FooterBanner;
