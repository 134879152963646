import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, getContentfulElements } from '../../../utils/ContentfulLoader';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import AboutStory, { AboutStoryProps } from './AboutStory';
import AboutStatistics, { AboutStatisticsProps } from './AboutStatistics';
import AboutTimeline, { AboutTimelineProps } from './AboutTimeline';
import AboutAddresses, { CountryDetails, AboutAddressesProps } from './AboutAddresses';
import WhatWeDoBanner, { WhatWeDoBannerProps } from '../../common/WhatWeDoBanner';
import Spinner from '../../utils/Spinner';
import { sortByIndex } from '../Leadership/LeadershipPage';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const pageEntryId: string = process.env.REACT_APP_ABOUT_ENTRY_ID;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '50em',
        },
        h100: {
            // height: '100%',
        },
        containerCompress: {
            // paddingLeft: '7.5vw;',
            // paddingRight: '7.5vw',
        },
    })
);

// const footerBannerDescription: footerDescriptionText[] = [
//     { text: 'We’re working with ', color: 'white' },
//     { text: '116 partners ', color: '#37E7A7' },
//     { text: 'worldwide', color: 'white' },
// ];

// const footerDetailsLocal: FooterBannerProps = {
//     bannerTitle: 'Looking to partner with us?',
//     bannerDescription: footerBannerDescription,
//     bannerImage: FooterPerson,
//     buttonText: 'Contact Us',
//     buttonLink: '/contact-us',
// };

// const whatWeDoDetailsLocal: WhatWeDoBannerProps = {
//     title: 'Asurion is constantly innovating and developing new products and tech solutions for our partners',
//     bannerImage: 'string',
//     bannerImageSmall: 'string',
//     buttonText: 'What We Do',
//     buttonLink: '/what-we-do',
// };

function AboutPage() {
    const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [heroMainDetails, setHeroMainDetails] = useState<HeroMainProps>();
    const [aboutStoryDetails, setAboutStoryDetails] = useState<AboutStoryProps>();
    const [aboutStatisticsDetails, setAboutStatisticsDetails] = useState<AboutStatisticsProps>();
    const [aboutTimelineDetails, setAboutTimelineDetails] = useState<AboutTimelineProps>();
    const [aboutAddressDetails, setAboutAddressDetails] = useState<AboutAddressesProps>();
    const [whatWeDoBannerContent, setWhatWeDoBannerContent] = useState<WhatWeDoBannerProps>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const classes = useStyles();

    const loadPageContent = useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                console.log(entry);
                let addressesRefences: ContentfulReference[] = entry.getReferences('addresses');
                let bannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = entry.getReferences('footerBannerPersonImage');
                let storyReferences: ContentfulReference[] = entry.getReferences('story');
                let whatWeDoBannerImageReferences = entry.getReferences('whatWeDoBannerImage');
                let whatWeDoBannerImageSmallReferences = entry.getReferences('whatWeDoBannerImageSmall');
                let aboutStoryVideoReferences = entry.getReferences('aboutStoryVideo');
                let aboutStoryVideoThumbnailReferences = entry.getReferences('videoThumbnail');

                let storyPromise: Promise<ContentfulEntry> = storyReferences[0].getData(contentfulConfig);
                let addressPromises: Promise<ContentfulEntry[]> = getContentfulElements(addressesRefences, contentfulConfig);
                let bannerImagePromise: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let bannerImageSmallPromise: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                let whatWeDoBannerImagePromise = whatWeDoBannerImageReferences[0].getData(contentfulConfig);
                let whatWeDoBannerImageSmallPromise = whatWeDoBannerImageSmallReferences[0].getData(contentfulConfig);
                let aboutStoryVideoPromise = aboutStoryVideoReferences[0].getData(contentfulConfig);
                let aboutStoryVideoThumbnailPromise = aboutStoryVideoThumbnailReferences[0].getData(contentfulConfig);

                let storyVideoThumbnail: string;
                let addresses: CountryDetails[] = [];

                Promise.all([
                    bannerImagePromise,
                    bannerImageSmallPromise,
                    footerBannerImagePromise,
                    whatWeDoBannerImagePromise,
                    whatWeDoBannerImageSmallPromise,
                    storyPromise,
                    aboutStoryVideoPromise,
                    aboutStoryVideoThumbnailPromise,
                    footerBannerImagePromise,
                ]).then((values: ContentfulEntry[]) => {
                    setHeroMainDetails({
                        title: entry.fields.bannerText,
                        backgroundImage: values[0].fields['file'].url,
                        backgroundImageSmall: values[1].fields['file'].url,
                    });

                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[2].fields['file'].url,
                    });

                    setWhatWeDoBannerContent({
                        title: entry.fields.whatWeDoBannerTitle,
                        bannerImage: values[3].fields['file'].url,
                        bannerImageSmall: values[4].fields['file'].url,
                        buttonText: entry.fields.whatWeDoButtonText,
                        buttonLink: entry.fields.whatWeDoBannerButtonLink,
                    });
                    console.log(values[5].fields.buttonText);
                    console.log(values[5].fields.buttonUrl);
                    console.log(entry.fields.storyDescription);
                    console.log(entry.fields.storyTitle);
                    console.log(storyVideoThumbnail);
                    console.log(values[6].fields['file'].url);
                    setAboutStoryDetails({
                        buttonText: entry.fields.buttonText,
                        buttonUrl: entry.fields.buttonUrl,
                        description: entry.fields.storyDescription,
                        title: entry.fields.storyTitle,
                        videoUrl: values[6].fields['file'].url,
                        image: values[7].fields['file'].url,
                    });
                });

                Promise.all([addressPromises])
                    .then((value: ContentfulEntry[][]) => {
                        let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                        let valueIterations: number = value[0].length;
                        if (value[0].length < maxIterations) {
                            for (let i = 0; i < valueIterations; i++) {
                                let imageReference: ContentfulReference[] = value[0][i].getReferences('image');
                                let imagePromise: Promise<ContentfulEntry> = imageReference[0].getData(contentfulConfig);

                                Promise.all([imagePromise]).then((value2: ContentfulEntry[]) => {
                                    addresses.push({
                                        name: value[0][i].fields.title,
                                        address: value[0][i].fields.streetAddress,
                                        email: value[0][i].fields.email,
                                        website: value[0][i].fields.url,
                                        image: value2[0].fields['file'].url,
                                        index: i,
                                    });
                                });
                            }
                        }
                    })
                    .then(() => {
                        setAboutAddressDetails({
                            headerText: entry.fields.addressesText,
                            countries: sortByIndex(addresses),
                        });
                        setAboutTimelineDetails({
                            experiences: entry.fields.experiences,
                            experienceText: entry.fields.experienceText,
                        });
                        setAboutStatisticsDetails({
                            quickFacts: entry.fields.quickFacts,
                            ratingsText: entry.fields.ratingsText,
                            ratings: entry.fields.ratings,
                        });
                        setTimeout(() => {
                            setDataLoaded(true);
                        }, 1000);
                    });
            })
            .catch((error: any) => {
                setDataLoaded(true);
            })
            .finally(() => {});
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div className={classes.h100}>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...heroMainDetails} />
                    <AboutStory {...aboutStoryDetails} />
                    <AboutStatistics {...aboutStatisticsDetails} />
                    <AboutTimeline {...aboutTimelineDetails} />
                    <AboutAddresses {...aboutAddressDetails} />
                    <WhatWeDoBanner {...whatWeDoBannerContent} />
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </div>
    );
}

export default AboutPage;
