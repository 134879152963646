import axios from 'axios';

export class ContentfulEntry {
    fields: ContentfulFields;
    metadata: any;
    sys: any;

    constructor(contentfulData: any) {
        this.fields = contentfulData.fields;
        this.metadata = contentfulData.fields;
        this.sys = contentfulData.fields;
    }

    public getReferences(fieldname: string): ContentfulReference[] {
        return ContentfulLoader.getReferences(this, fieldname);
    }
}

export class ContentfulEntryWithIncludes {
    includes: ContentfulIncludes;
    fields: ContentfulFields;

    constructor(contentfulData: any) {
        this.includes = contentfulData.includes;
        this.fields = contentfulData.items[0].fields;
    }

    public getReferences(fieldname: string): ContentfulEntry[] {
        return ContentfulLoaderContentTypeId.getIncludeData(this, fieldname);
    }
}

export class ContentfulIncludes {
    Asset: ContentfulEntry[];
    Entry: ContentfulEntry[];
}

export class ContentfulFields {
    [propName: string]: any;
}

type EntryType = 'Entry' | 'Asset';

export class ContentfulReference {
    id: string;
    linkType: EntryType;
    config: ContentfulConfig;

    constructor(id: string, linkType: EntryType, config: ContentfulConfig = ContentfulConfig.getDefault()) {
        this.id = id;
        this.linkType = linkType;
        this.config = config;
    }

    public getData(config: ContentfulConfig): Promise<ContentfulEntry> {
        return ContentfulLoader.loadEntity(this.id, config, this.linkType);
    }
}
export class ContentfulConfig {
    baseUrl: string = 'https://cdn.contentful.com/';
    spaceId: string = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
    environment: string = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
    accessToken: string = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

    //`https://cdn.contentful.com/spaces/rcqun2fh2hqb/environments/master/entries/5R0LNa2pU5i9f0bA4JsM0Y?access_token=sYz8IrSfAwI0NgOLAOK2inA2IaQx2E_g8WAgIiVxxFc&content_type=` +

    public static getProduction(): ContentfulConfig {
        return new ContentfulConfig();
    }

    public static getDev(): ContentfulConfig {
        return new ContentfulConfig();
    }

    public static getQA(): ContentfulConfig {
        return new ContentfulConfig();
    }

    public static getUAT(): ContentfulConfig {
        return new ContentfulConfig();
    }

    public static getDefault(): ContentfulConfig {
        return ContentfulConfig.getProduction();
    }
}

export class ContentfulLoader {
    public static loadEntity(entryId: string, config: ContentfulConfig, type: EntryType): Promise<ContentfulEntry> {
        // public static loadEntity(entryId: string, config: ContentfulConfig, type: EntryType): Promise<ContentfulEntry> {
        //public static loadEntry(entryId: string, config: ContentfulConfig): Promise<any> {
        let url: string;
        let contentfulType: string;

        switch (type) {
            case 'Entry':
                contentfulType = 'entries';
                break;
            case 'Asset':
                contentfulType = 'assets';
                break;
        }

        url =
            config.baseUrl +
            'spaces/' +
            config.spaceId +
            '/environments/' +
            config.environment +
            '/' +
            contentfulType +
            '/' +
            entryId +
            '?access_token=' +
            config.accessToken +
            `&order=id&locale=${process.env.REACT_APP_LOCALE}` +
            `&include=1`;

        return new Promise<ContentfulEntry>((resolve: any, reject: any) => {
            let p: Promise<ContentfulEntry>;
            p = axios.get(url);
            p.then((result: any) => {
                let { data } = result;
                resolve(new ContentfulEntry(data));
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static getReferences(contentfulData: ContentfulEntry, fieldname: string): ContentfulReference[] {
        let field: any = contentfulData?.fields?.[fieldname];
        let references: ContentfulReference[] = null;
        let reference: ContentfulReference;
        let element: any;

        if (field != null) {
            references = []; // create a new arrays
            if (Array.isArray(field)) {
                // the field is an array
                //checkmarx conformity
                let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                let fieldIterations: number = field.length;
                if (fieldIterations < maxIterations) {
                    for (let s = 0; s < fieldIterations; s++) {
                        element = field[s] as any;
                        reference = new ContentfulReference(element.sys.id, element.sys.linkType);
                        references.push(reference);
                    }
                }
            } else {
                // the field is not an array
                element = field as any;
                reference = new ContentfulReference(element.sys.id, element.sys.linkType);
                references.push(reference);
            }
        }

        return references;
    }
}

export class ContentfulLoaderContentTypeId {
    public static loadEntity(contentTypeId: string, config: ContentfulConfig): Promise<ContentfulEntry> {
        let url: string;
        //https://cdn.contentful.com/spaces/rcqun2fh2hqb/environments/master/entries/?access_token=sYz8IrSfAwI0NgOLAOK2inA2IaQx2E_g8WAgIiVxxFc&content_type=auCorpInsightsArticle&locale=en-US&include=1
        url =
            config.baseUrl +
            'spaces/' +
            config.spaceId +
            '/environments/' +
            config.environment +
            '/entries/' +
            '?access_token=' +
            config.accessToken +
            `&content_type=${contentTypeId}` +
            `&locale=${process.env.REACT_APP_LOCALE}` +
            `&include=1`;

        return new Promise<ContentfulEntry>((resolve: any, reject: any) => {
            let p: Promise<ContentfulEntry>;

            p = axios.get(url);
            p.then((result: any) => {
                let { data } = result;
                resolve(new ContentfulEntryWithIncludes(data));
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static getIncludeData(contentfulData: ContentfulEntryWithIncludes, fieldName: string): ContentfulEntry[] {
        let field: ContentfulEntry[] = contentfulData?.fields?.[fieldName];
        let includes: ContentfulIncludes = contentfulData?.includes;

        if (field) {
            for (let i = 0; i < field.length; i++) {
                if (field[i].sys.linkType === 'Entry') {
                    let entryData: any = includes.Entry.find(entry => entry.sys.id.includes(field[i].sys.id));
                    field[i] = entryData;
                }
                if (field[i].sys.linkType === 'Asset') {
                    let assetData: any = includes.Asset.find(asset => asset.sys.id.includes(field[i].sys.id));
                    field[i] = assetData;
                }
            }
        }

        return field;
    }

    public static getIncludeDataSingle(contentfulData: ContentfulEntryWithIncludes, fieldName: string): ContentfulEntry {
        let field: ContentfulEntry = contentfulData?.fields?.[fieldName];
        let includes: ContentfulIncludes = contentfulData?.includes;

        if (field) {
            if (field.sys.linkType === 'Entry') {
                let entryData: any = includes.Entry.find(entry => entry.sys.id.includes(field.sys.id));
                field = entryData;
            }
            if (field.sys.linkType === 'Asset') {
                let assetData: any = includes.Asset.find(asset => asset.sys.id.includes(field.sys.id));
                field = assetData;
            }
        }

        return field;
    }

    public static getRecursiveReferenceData(contentfulEntryData: ContentfulEntry[], includeData: ContentfulIncludes, fieldName: string): ContentfulEntry[] {
        let includes: ContentfulIncludes = includeData;
        if (contentfulEntryData) {
            for (let i = 0; i < contentfulEntryData.length; i++) {
                let field: ContentfulEntry = contentfulEntryData[i].fields?.[fieldName];
                if (field.sys.linkType === 'Entry') {
                    let entryData: ContentfulEntry = includes.Entry.find(entry => entry.sys.id.includes(field.sys.id));
                    contentfulEntryData[i].fields[fieldName] = entryData;
                }
                if (field.sys.linkType === 'Asset') {
                    let assetData: ContentfulEntry = includes.Asset.find(asset => asset.sys.id.includes(field.sys.id));
                    contentfulEntryData[i].fields[fieldName] = assetData;
                }
            }
        }

        return contentfulEntryData;
    }
}

export class ContentfulLoaderByField {
    public static loadEntity(contentTypeId: string, field: string, value: string, config: ContentfulConfig): Promise<ContentfulEntry> {
        let url: string;

        url = `${config.baseUrl}spaces/${config.spaceId}/environments/${config.environment}/entries/?access_token=${config.accessToken}&content_type=${contentTypeId}&${field}=${value}&locale=${process.env.REACT_APP_LOCALE}&include=1`;

        return new Promise<ContentfulEntry>((resolve: any, reject: any) => {
            let p: Promise<ContentfulEntry>;

            p = axios.get(url);
            p.then((result: any) => {
                let { data } = result;
                resolve(new ContentfulEntryWithIncludes(data));
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static getIncludeData(contentfulData: ContentfulEntryWithIncludes, fieldName: string): ContentfulEntry[] {
        let field: ContentfulEntry[] = contentfulData?.fields?.[fieldName];
        let includes: ContentfulIncludes = contentfulData?.includes;

        if (field) {
            for (let i = 0; i < field.length; i++) {
                if (field[i].sys.linkType === 'Entry') {
                    let entryData: any = includes.Entry.find(entry => entry.sys.id.includes(field[i].sys.id));
                    field[i] = entryData;
                }
                if (field[i].sys.linkType === 'Asset') {
                    let assetData: any = includes.Asset.find(asset => asset.sys.id.includes(field[i].sys.id));
                    field[i] = assetData;
                }
            }
        }

        return field;
    }

    public static getRecursiveReferenceData(contentfulEntryData: ContentfulEntry, includeData: ContentfulIncludes, fieldName: string): ContentfulEntry {
        let includes: ContentfulIncludes = includeData;
        if (contentfulEntryData) {
            let field: ContentfulEntry = contentfulEntryData?.[fieldName];
            if (field) {
                if (field.sys.linkType === 'Entry') {
                    let entryData: ContentfulEntry = includes.Entry.find(entry => entry.sys.id.includes(field.sys.id));
                    contentfulEntryData[fieldName] = entryData;
                }
                if (field.sys.linkType === 'Asset') {
                    let assetData: ContentfulEntry = includes.Asset.find(asset => asset.sys.id.includes(field.sys.id));
                    contentfulEntryData[fieldName] = assetData;
                }
            }
        }

        return contentfulEntryData;
    }
}

export const getContentfulImages = async (references: ContentfulReference[], config: ContentfulConfig) => {
    let imageUrls: ContentfulEntry[] = [];
    for (let i = 0; i < references.length; i++) {
        let promise = await references[i].getData(config);
        Promise.all([promise]).then((values: ContentfulEntry[]) => {
            imageUrls.push(values[0].fields.file.url);
        });
    }
    return imageUrls;
};
export const getContentfulElements = async (references: ContentfulReference[], config: ContentfulConfig) => {
    let entries: ContentfulEntry[] = [];
    for (let i = 0; i < references.length; i++) {
        let promise = await references[i].getData(config);
        Promise.all([promise]).then((values: ContentfulEntry[]) => {
            entries.push(values[0]);
        });
    }
    return entries;
};

export const getImageFromIncludes = (entry: ContentfulEntry, includes: ContentfulIncludes) => {
    if (entry.sys.linkType === 'Entry') {
        let entryData: ContentfulEntry = includes.Entry.find(entry => entry.sys.id.includes(entry.sys.id));
        entry = entryData;
    }
    if (entry.sys.linkType === 'Asset') {
        let assetData: ContentfulEntry = includes.Asset.find(asset => asset.sys.id.includes(entry.sys.id));
        entry = assetData;
    }

    return entry;
};
