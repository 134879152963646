import { Text } from '../ContentTypes/Text';
import { List } from '../ContentTypes/List';
import { NormalList } from '../ContentTypes/NormalList';
import { Image } from '../ContentTypes/Image';
import { SmartphoneAccordion } from '../ContentTypes/SmartphoneAccordion';

export const contentMapping = {
    img: {
        type: Image,
        paramKey: 'src',
    },
    list: {
        type: List,
        paramKey: 'content',
    },
    normalList: {
        type: NormalList,
        paramKey: 'content',
    },
    text: {
        type: Text,
        paramKey: 'content',
    },
    accordion: {
        type: '',
        paramKey: 'data',
    },
    smartphoneAccordion: {
        type: SmartphoneAccordion,
        paramKey: 'data',
    },
};

export interface ServiceItem {
    type?: keyof typeof contentMapping;
    belowTheLineType?: keyof typeof contentMapping;

    icon: string;
    name: string;
    title: string;
    aboveTheLine: string;
    belowTheLine: string | Array<string>;
    showcase: string | Array<any>;

    // Styling
    backgroundColor?: string;
}
