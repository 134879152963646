import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import imgPlayStore from '../../../images/soluto/play_store.svg';
import imgGooglePlay from '../../../images/soluto/app_store.svg';
import imgHand from '../../../images/soluto/hand.png';
import imgQrCode from '../../../images/soluto/qr_code.svg';
import imgStoreText from '../../../images/soluto/store-text.svg';

const SolutoDownload = ({ appStoreUrl, googlePlayUrl }) => {
    const classes = useStyles();
    return (
        <Box component={'section'} className={classes.root}>
            <div className={classes.container}>
                <div className={classes.holder}>
                    <img src={imgQrCode} alt="QR code" className={classes.imgQrCode} />
                    <div className={classes.store}>
                        <img src={imgStoreText} alt="store text" className={classes.imgStoreText} />
                        <div className={classes.storeContentHolder}>
                            <a href={appStoreUrl}>
                                <img src={imgPlayStore} alt="playstore" className={classes.imgPlayStore} />
                            </a>
                            <a href={googlePlayUrl}>
                                <img src={imgGooglePlay} alt="google play" className={classes.imgGooglePlay} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: `linear-gradient(180deg,#8223d2 0%,#6469e1 100%)`,
            padding: `50px 0`,

            [theme.breakpoints.down('md')]: {
                padding: `35px 0`,
            },

            [theme.breakpoints.down('sm')]: {
                padding: `20px 0`,
            },
        },
        container: {
            maxWidth: '1175px',
            margin: '0 auto',
            justifyContent: 'center',
            position: 'relative',
            display: 'flex',

            [theme.breakpoints.down('md')]: {
                maxWidth: '900px',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },

            '&::after': {
                position: 'absolute',
                width: '246px',
                height: '298px',
                bottom: '-52px',
                left: '120px',
                content: '""',
                backgroundImage: `url(${imgHand})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',

                [theme.breakpoints.down('md')]: {
                    width: '180px',
                    height: '218px',
                    bottom: '-37px',
                    left: 0,
                },

                [theme.breakpoints.down('sm')]: {
                    width: '140px',
                    height: '169px',
                    bottom: '-21px',
                    left: 0,
                },
            },
        },
        holder: {
            width: '500px',
            display: 'flex',
            transform: `translateX(100px)`,
        },
        store: {
            width: `calc(100% - 175px)`,
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'flex-end',
            marginLeft: '15px',

            [theme.breakpoints.down('sm')]: {
                width: `calc(100% - 160px)`,
                marginLeft: '40px',
            },
        },
        imgQrCode: {
            width: '160px',
            borderStyle: 'none',

            [theme.breakpoints.down('md')]: {
                width: '120px',
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        imgStoreText: {
            marginBottom: '10px',
            borderStyle: 'none',

            [theme.breakpoints.down('md')]: {
                maxWidth: '200px',
            },
        },
        storeContentHolder: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        linkPlayStore: {
            width: '48%',
            display: 'inline-block',
        },
        imgPlayStore: {
            width: '100%',
        },
        linkGooglePlay: {
            width: '48%',
            display: 'inline-block',
        },
        imgGooglePlay: {
            width: '100%',
        },
    })
);

export default SolutoDownload;
