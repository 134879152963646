import * as React from 'react';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { relativeToBaseWidth } from '../../common/functions';
import { useProgressiveImage } from '../utils/LazyBackground';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Spinner from '../utils/Spinner';
import parse from 'html-react-parser';

export type HeroMainProps = {
    title: string;
    backgroundImage: string;
    backgroundImageSmall?: string;
};

const useStyles = makeStyles(theme => ({
    container: (props: HeroMainProps) => ({
        height: 420,
        display: 'flex',
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            height: 400,
            // height: 250,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            ...(props.backgroundImageSmall ? { backgroundImage: `url(${props.backgroundImageSmall})` } : {}),
        },
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column-reverse',
            // height: 400,
            height: 250,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            ...(props.backgroundImageSmall ? { backgroundImage: `url(${props.backgroundImageSmall})` } : {}),
        },
    }),
    lead: {
        width: relativeToBaseWidth(630),
        backgroundImage: `linear-gradient(90.13deg, ${theme.palette.primary.main} 0%, #3700B3 100%);`,
        clipPath: 'polygon(0 0, 100% 0, 72.65% 100%, 0% 100%)',
        display: 'flex',
        alignItems: 'center',
        fontSize: '3rem',
        lineHeight: '3.5rem',
        color: theme.palette.common.white,
        paddingLeft: relativeToBaseWidth(120),
        paddingRight: relativeToBaseWidth(90),
        whiteSpace: 'break-spaces',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            clipPath: 'none',
            fontSize: '2.125rem',
            lineHeight: '2.25rem',
            whiteSpace: 'inherit',
            height: 150,
        },
    },
    placeholder: {
        background: 'gray !important',
    },
}));

const Banner = (props: HeroMainProps) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const loaded = useProgressiveImage(isMobile ? props.backgroundImageSmall : props.backgroundImage);

    console.log(window.location.pathname);
    return loaded ? (
        isMobile ? (
            <Grid>
                <div className={classes.container} style={{ backgroundImage: `url(${loaded})` }}></div>
                <div className={classes.lead}>{parse(`${props.title}`)}</div>
            </Grid>
        ) : (
            <div
                className={classes.container}
                style={{
                    backgroundImage: `url(${loaded})`,
                    backgroundSize: window.location.pathname === '/company/leadership' && 'cover',
                    // backgroundPosition: window.location.pathname === '/company/leadership' && '50% center',
                }}
            >
                <div className={classes.lead}>{parse(`${props.title}`)}</div>
            </div>
        )
    ) : (
        <Spinner />
    );
};

export const HeroMain = (props: HeroMainProps) => {
    return <Banner {...props} />;
};

export default HeroMain;
