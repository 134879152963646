import * as React from 'react';
import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import parse from 'html-react-parser';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const Bold = ({ children }) => (
    <Typography component="span" style={{ fontWeight: 'bold' }} >
        {children}
    </Typography>
  );
  const Italic = ({ children }) => (
    <Typography component="span" style={{ fontWeight: 'italic' }}>
        {children}
    </Typography>
  );
const Text = ({ children }) => <Typography className="" style={{ whiteSpace: 'normal' }}>{children}</Typography>;
export interface FaqItem {
    key: string;
    title: string;
    content: string;
}

export interface FaqAccordionProps {
    faqs: Array<FaqItem>;
    asurionMobilePlusFaq?: string;
}

export const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
        [MARKS.CODE]: (text) => parse(`${text}`),
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            console.log(node.content);
            return node.content.some(
                (childNode) => childNode.nodeType === `text` && childNode.marks.some((mark) => mark.type === MARKS.CODE)
            ) ? (
                {children}
            ) : (
                <Text>{children}</Text>
            )
        }
            
    },
    // renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    renderText: (text) => {
        if (!text) return <br />;
        return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    }
  };

const useStyles = makeStyles(theme => ({
    ordinality: {
        color: theme.palette.primary.main,
    },
    link: {
        textDecorationColor: 'black',
    },
    linkText: {
        color: 'black',
    },
}));

const useAccordionStyles = makeStyles(theme => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        background: 'none',
        borderBottom: `2px solid ${theme.palette.grey.A100}`,
        padding: '62px 10px',
        '&:first-child': {
            padding: '0 10px 62px 10px',
        },
        '&.Mui-expanded': {
            paddingBottom: 52,
            margin: 0,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 43,
            paddingBottom: 43,
        },
    },
    expanded: {
        maxHeight: 'unset',
        paddingBottom: 52,
    },
}));

const useAccordionSummaryStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        minHeight: 'unset',
        '&.Mui-expanded': {
            minHeight: 'unset',
        },
        [theme.breakpoints.down('xs')]: {
            alignItems: 'start',
        },
    },
    content: {
        fontSize: '2.125rem',
        lineHeight: 2.25,
        margin: 0,
        '& > .MuiTypography-root': {
            fontWeight: 'bold',
            fontSize: 'inherit',
            lineHeight: 1,
            letterSpacing: `${0.15 / 16}rem`,
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.5rem',
                lineHeight: 1,
            },
        },
        '&.Mui-expanded': {
            margin: 0,
        },
        // [theme.breakpoints.down('xs')]: {
        //   paddingBottom: 43,
        // }
    },
    expanded: {
        minHeight: 'unset',
    },
    expandIcon: {
        color: theme.palette.primary.main,
        width: 30,
        height: 30,
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            width: 25,
            height: 25,
        },
    },
}));

const useAccordionDetailsStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        paddingTop: 60,
        '& .MuiTypography-root': {
            fontSize: '1rem',
            lineHeight: 1.5,
        },
    },
}));

export const FaqAccordion = (props: FaqAccordionProps) => {
    const { faqs } = props;
    const [expanded, setExpanded] = useState<string | null>(null);

    const classes = useStyles();
    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();
    const accordionDetailsStyles = useAccordionDetailsStyles();

    const handleChange = (panel: string) => (_event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <>
            {faqs.map((faq, i) => (
                <Accordion
                    expanded={expanded === faq.key}
                    onChange={handleChange(faq.key)}
                    classes={{
                        root: accordionClasses.root,
                        expanded: accordionClasses.expanded,
                    }}
                    key={faq.key}
                >
                    <AccordionSummary
                        expandIcon={expanded === faq.key ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                        classes={{
                            root: accordionSummaryClasses.root,
                            content: accordionSummaryClasses.content,
                            expanded: accordionSummaryClasses.expanded,
                            expandIcon: accordionSummaryClasses.expandIcon,
                        }}
                    >
                        <Typography>
                            <span className={classes.ordinality}>Q{i + 1}.</span> {faq.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{
                            root: accordionDetailsStyles.root,
                        }}
                    >
                        {
                            // (faq.key === 'job-openings' && (
                            //     <Typography>
                            //         You can visit our{' '}
                            //         <a target="_blank" href="http://www.linkedin.com/company/asurion/mycompany" rel="noreferrer" className={classes.link}>
                            //             <span className={classes.linkText}>careers page</span>
                            //         </a>{' '}
                            //         on LinkedIn by searching your country or functional area of interest and apply for our open roles. The best way to have your resume reviewed by our recruiting team is
                            //         to apply for an open position you believe your interest and experience would match. Our recruiting team is reviewing new applications every day.
                            //     </Typography>
                            // )) ||
                            //     (faq.key === 'terms-and-conditions' && (
                            //         <Typography>
                            //             You can visit our{' '}
                            //             <Link
                            //                 to={
                            //                     (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && '/tha/terms-of-use') ||
                            //                     (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'MY' && '/bah/terms-of-use') ||
                            //                     '/eng/terms-of-use'
                            //                 }
                            //                 className={classes.link}
                            //             >
                            //                 <span className={classes.linkText}>terms of use page</span>
                            //             </Link>{' '}
                            //             to find out more.
                            //         </Typography>
                            //     )) ||

                            <Typography>{props?.asurionMobilePlusFaq && faq.key === 'asurion-mobile-plus' ? documentToReactComponents(JSON.parse(props.asurionMobilePlusFaq), options) : parse(faq.content)}</Typography>
                            // <Typography>{props?.asurionMobilePlusFaq && faq.key === 'asurion-mobile-plus' ? <Box display="flex" flexDirection="column" style={{ gap: '1rem' }}>{documentToReactComponents(JSON.parse(props.asurionMobilePlusFaq), options)}</Box> : parse(faq.content)}</Typography>
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default FaqAccordion;
