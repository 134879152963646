import React, { Fragment } from 'react';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Grid, Box } from '@material-ui/core/';
import parse from 'html-react-parser';
import { HeroDescriptionStyles } from './HeroDescriptionStyles';

interface HeroDescriptionProps {
    descriptionDetails: ContentfulDocument;
}

const HeroDescription = ({ descriptionDetails }: HeroDescriptionProps) => {
    const classes = HeroDescriptionStyles();

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <span className="text-center">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },
    };

    return (
        <Fragment>
            <Box className={classes.root}>
                <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.descriptionBox}>
                    {documentToReactComponents(descriptionDetails, options)}
                </Grid>
            </Box>
        </Fragment>
    );
};

export default HeroDescription;
