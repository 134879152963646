import React, { Fragment, useState } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Link } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Grid, Button, Box, Modal, Fade, Backdrop } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles, useTheme } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import PurpleBackground from '../../../images/common/PurpleBackground.jpg';
import { useProgressiveImage } from '../../utils/LazyBackground';
import { ContentfulFields } from '../../../utils/ContentfulLoader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import parse from 'html-react-parser';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '35em',
            [theme.breakpoints.down('sm')]: {
                height: '650px',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(3),
            paddingLeft: '7vw;',
            paddingRight: '7vw',
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
        },
        swiper: {
            background: 'black',
            height: '100%',
        },
        slideContainer: {
            height: '100%',
            position: 'relative',
        },
        overlay: {
            height: '100%',
            width: '100%',
            background: 'linear-gradient(to right, black 0%, transparent 60%), linear-gradient(to left, black 0%, transparent 20%)',
            [theme.breakpoints.down('sm')]: {
                background: 'linear-gradient(to top, black 40%, transparent 55%) ',
            },
        },
        slideImageBox: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: 'black',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        slideImage: {
            // filter: 'grayscale(1)',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // backgroundPosition: 'right center',
            marginLeft: theme.spacing(2),
            width: '70em',

            [theme.breakpoints.down('sm')]: {
                backgroundSize: 'contain',
                margin: 0,
            },
        },
        slideTextBox: {
            position: 'absolute',
            top: '9%',
            zIndex: 2,
            color: 'white',

            [theme.breakpoints.up('md')]: {
                width: '35%',
            },
            [theme.breakpoints.only('sm')]: {
                width: '-webkit-fill-available',
            },
            [theme.breakpoints.down('sm')]: {
                height: '80%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end',
            },
        },
        heroTitle: {
            fontSize: '3.75rem',
            margin: 0,
            lineHeight: '72px',
            '&.smallerMargin': {
                '&>p': {
                    marginBottom: '3px',
                },
            },

            '&.largerMargin': {
                '&>p': {
                    marginBottom: '2em',
                },
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '2.125rem',
                lineHeight: '36px',
            },
        },
        heroDescription: {
            fontSize: theme.spacing(2.25),
            lineHeight: '24px',
        },
        heroButton: {
            // backgroundColor: "#8223d2",
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        buttonLink: {
            textDecoration: 'none',
        },
        paginationMod: {
            [theme.breakpoints.up('md')]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: 'white !important',
            margin: '0px 10px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#03dac5 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        player: {
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                width: '100%',
            },
        },
    })
);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        borderRadius: '2em',
        backgroundColor: '#8223d2',
        '&:hover': {
            backgroundColor: '#8223d2',
        },
    },
}))(Button);

interface HeroBannerProps {
    bannerDetails: ContentfulFields[];
    bannerVideo: string;
}

const BannerImage = (value: ContentfulFields) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const loaded = useProgressiveImage(isMobile ? value['imageSmall'] : value['image']);

    return (
        <Box
            component="div"
            className={classes.slideImage}
            id={value['id']}
            style={{
                backgroundImage: `url(${loaded || PurpleBackground})`,
            }}
        >
            <Box className={classes.overlay}></Box>
        </Box>
    );
};

const isSKT: boolean = process.env.REACT_APP_LOCALE === 'ko';

function HeroBanner({ bannerDetails, bannerVideo }: HeroBannerProps) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    SwiperCore.use([Pagination, Autoplay]);

    const Bold = ({ children }: any) => <span className={classes.colorPrimary}>{children}</span>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },
    };

    const handleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div className={classes.root}>
            {(bannerDetails.length === 0 && <p></p>) || (
                <Fragment>
                    <Swiper
                        className={classes.swiper}
                        autoplay
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{
                            clickable: true,
                            bulletClass: `` + classes.paginationBullet + ``,
                            bulletActiveClass: `` + classes.paginationBulletActive + ``,
                            clickableClass: `` + classes.paginationMod + ``,
                        }}
                        observeParents={true}
                        observer={true}
                    >
                        {bannerDetails.map((value, index) => (
                            <Fragment key={value.id}>
                                <SwiperSlide>
                                    <Box className={classes.slideContainer}>
                                        <Box className={classes.slideImageBox}>
                                            <BannerImage {...value} />
                                        </Box>
                                        <Box className={clsx(classes.slideTextBox, classes.containerCompress)} sx={{ width: isMobile && isSKT ? '90%' : '' }}>
                                            <Box className={clsx(classes.heroTitle, value.description ? 'smallerMargin' : 'largerMargin')}>{documentToReactComponents(value.title, options)}</Box>
                                            <Box className={classes.heroDescription}>{documentToReactComponents(value.description, options)}</Box>
                                            <Box className={classes.heroButton}>
                                                {value.buttonUrl === 'playVideo' ? (
                                                    <ColorButton variant="contained" color="primary" onClick={handleModal}>
                                                        {value.buttonText}
                                                    </ColorButton>
                                                ) : (
                                                    <Link to={value.buttonUrl} className={classes.buttonLink}>
                                                        <ColorButton variant="contained" color="primary">
                                                            {value.buttonText}
                                                        </ColorButton>
                                                    </Link>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            </Fragment>
                        ))}
                    </Swiper>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={showModal}
                        onClose={handleModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={showModal}>
                            <div>
                                <Grid container direction="row">
                                    <ReactPlayer
                                        url={bannerVideo}
                                        playing={showModal}
                                        controls={true}
                                        preload="auto"
                                        className={classes.player}
                                        height={isMobile ? '100%' : '360px'}
                                        width={isMobile ? '100%' : '640px'}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </div>
                        </Fade>
                    </Modal>
                </Fragment>
            )}
        </div>
    );
}

export default HeroBanner;
