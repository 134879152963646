import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { rem, sv, toPercentage } from '../../../../common/functions';
import { contentMapping, ServiceItem } from './types';
import parse from 'html-react-parser';

export interface ServiceListProps {
    services: Array<ServiceItem>;
}

const useStyle = makeStyles(theme => ({
    container: {
        '&:nth-of-type(1n) > :first-child': {
            padding: sv(35, 0, 35, 135),
        },
    },
    contentWrapper: {
        display: 'flex',
        padding: sv(35, 124),
        '&:nth-of-type(even)': {
            flexDirection: 'row-reverse',
        },
        '&:nth-of-type(odd) > :first-child': {
            flexBasis: toPercentage(271, 772),
        },
        '& > *': {
            flexBasis: toPercentage(1, 2),
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 23,
    },
    title: {
        lineHeight: 18 / 17,
    },
    serviceIdentifier: {
        color: theme.palette.primary.main,
        fontSize: rem(1.25),
        display: 'flex',
        alignItems: 'center',
        gap: 15,
        fontWeight: 'bold',
        letterSpacing: rem(0.15 / 16),
    },
    serviceIcon: {
        height: 40,
    },
    aboveTheLine: {
        color: '#3700B3',
        fontSize: '1.5rem',
    },
    showcase: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: 'auto',

        '&.smartphoneAccordion': {
            flexGrow: '1',
            alignItems: 'stretch',
        },

        '&.img': {
            flex: 1,
        },

        '& ul': {
            columnCount: 2,
            marginLeft: 45,
            width: toPercentage(1, 1),
        },
        '& li': {
            fontSize: rem(1),
        },
    },
}));

export const ServiceList = ({ services }: ServiceListProps) => {
    const classes = useStyle();

    return (
        <Box className={classes.container}>
            {services.map(service => {
                const Content = contentMapping[service.belowTheLineType || 'text'].type;
                const belowTheLineProps = {
                    [contentMapping[service.belowTheLineType || 'text'].paramKey]: service.belowTheLine,
                };

                const Showcase = contentMapping[service.type || 'img'].type;
                const showcaseProps = {
                    [contentMapping[service.type || 'img'].paramKey]: service.showcase,
                };

                return (
                    <Box
                        key={service.name}
                        className={classes.contentWrapper}
                        style={{
                            backgroundColor: service.backgroundColor || 'unset',
                        }}
                    >
                        <Box className={classes.content}>
                            <Typography className={classes.serviceIdentifier}>
                                <img src={service.icon} alt={service.name} className={classes.serviceIcon} /> {service.name}
                            </Typography>
                            <Typography variant="h3" className={classes.title}>
                                {parse(`${service.title}`)}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.aboveTheLine}>
                                {parse(`${service.aboveTheLine}`)}
                            </Typography>
                            <Content {...belowTheLineProps} />
                        </Box>
                        <Box className={`${classes.showcase} ${service.type || 'img'}`}>
                            <Showcase {...showcaseProps} />
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
