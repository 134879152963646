import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, EffectFade, Navigation } from 'swiper';

import 'swiper/swiper-bundle.css';

const SolutoBanner = ({ banners, isMobile }) => {
    console.log('BANNERS', banners);

    const classes = useStyles();
    const theme = useTheme();

    console.log(theme.breakpoints.down('sm'));

    SwiperCore.use([Pagination, Autoplay, EffectFade, Navigation]);

    return (
        <Box component={'section'} className="soluto-banner">
            <Swiper
                effect="fade"
                loop={true}
                autoplay={true}
                spaceBetween={0}
                slidesPerView={1}
                navigation={isMobile ? false : true}
                pagination={{
                    clickable: true,
                    bulletClass: `${classes.paginationBullet}`,
                    bulletActiveClass: `${classes.paginationActiveBullet}`,
                }}
            >
                {banners.map((banner, index) => {
                    return (
                        <SwiperSlide key={`banner-${index}`}>
                            <div className={classes.banner}>
                                {isMobile ? (
                                    <img src={banner.mobile.url} alt={banner.mobile.title} className={classes.imgBanner} />
                                ) : (
                                    <img src={banner.image.url} alt={banner.image.title} className={classes.imgBanner} />
                                )}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        banner: {
            width: '100%',
            maxWidth: '1680px',
            position: 'relative',

            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0 auto',
            },
        },
        imgBanner: {
            width: '100%',
        },
        paginationBullet: {
            width: '12px',
            height: '12px',
            display: 'inline-block',
            borderRadius: '50%',
            background: '#000',
            opacity: '0.2',
            margin: '0 5px',
        },
        paginationActiveBullet: {
            width: '12px',
            height: '12px',
            display: 'inline-block',
            borderRadius: '50%',
            backgroundColor: '#8223d2',
            opacity: 1,
            margin: '0 5px',
        },
    })
);

export default SolutoBanner;
