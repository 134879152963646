import { makeStyles } from '@material-ui/core/styles';

export const HeroDescriptionStyles = makeStyles(theme => ({
    root: {
        // paddingLeft: '7.5vw',
        // paddingRight: '7.5vw',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        borderTop: '5px solid #BB86FC;',
        paddingLeft: '7vw;',
        paddingRight: '7vw',

        [theme.breakpoints.only('lg')]: {
            // paddingTop: '95px',
            // paddingBottom: '95px',
        },

        [theme.breakpoints.only('xl')]: {
            paddingTop: '95px',
            paddingBottom: '95px',
        },
    },
    descriptionBox: {
        fontSize: '2.125rem',
        lineHeight: '2.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.875rem',
        },
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    textPrimary: {
        color: theme.palette.primary.main,
    },
}));
