import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface Foundations {
    name: string;
}

const isSKT: boolean = process.env.REACT_APP_LOCALE === 'ko';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
        },
        orbitContainer: {
            position: 'relative',
            width: '260px',
            height: '260px',
            margin: 'auto auto 0',
        },
        planet: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 6px 30px 0px rgba(0,0,0,0.25)',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',

            '&:before': {
                content: '',
                position: 'absolute',
                width: '200px',
                height: '200px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                boxShadow: '0px 8px 10px 0px rgba(0,0,0,0.25)',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            },
        },
        title: {
            color: '#8223D2',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '1.0',
        },
        orbitBG: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '260px',
            height: '260px',
            border: '2px dashed #8223D2',
            borderRadius: '50%',
            boxSizing: 'border-box',
        },
        orbit: {
            position: 'relative',
            width: '260px',
            height: '260px',
            borderRadius: '50%',
            transform: 'rotate(0deg)',
            transition: 'transform 0.5s linear',
            boxSizing: 'border-box',
        },
        moon: {
            position: 'absolute',
            width: '18px',
            height: '18px',
            padding: '3px',
            transform: 'rotate(0deg)',
            transition: 'transform 0s linear',
            backgroundColor: '#333F48',
            borderRadius: '50%',
            cursor: 'pointer',
            color: 'white',
            textAlign: 'center',
            border: 0,

            '&.selected': {
                backgroundColor: '#8223D2',
                boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.25)',
            },

            '&:not(.selected):before': {
                content: '""',
                position: 'absolute',
                display: 'inline-block',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                width: '8px',
                height: '1px',
                top: '50%',
                left: 'calc(50% - 4px)',
            },

            '&:not(.selected):after': {
                content: '""',
                position: 'absolute',
                display: 'inline-block',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                width: '1px',
                height: '8px',
                top: 'calc(50% - 4px)',
                left: '50%',
            },

            '&:nth-child(1)': {
                bottom: '-9px' /* -child size/2 */,
                left: '121px' /* parent size/2 - child size/2 */,
            },
            '&:nth-child(2)': {
                top: '121px' /* -child size/2 */,
                left: '-9px' /* parent size/2 - child size/2 */,
            },
            '&:nth-child(3)': {
                top: '-9px' /* -child size/2 */,
                left: '121px' /* parent size/2 - child size/2 */,
            },
            '&:nth-child(4)': {
                top: '121px' /* -child size/2 */,
                right: '-9px' /* parent size/2 - child size/2 */,
            },
        },
        productTitle: {
            display: 'inline-block',
            textAlign: 'center',
            margin: '40px auto auto',
            padding: '10px 15px',
            color: 'white',
            textTransform: 'uppercase',
            backgroundColor: '#333F48',
            borderRadius: '50px',
            width: '140px',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '1.1',
            boxSizing: 'border-box',
        },
    })
);

function ProductWheel(foundations: Foundations[]) {
    const classes = useStyles();
    const [currentAngle, setCurrentAngle] = useState(0);
    const [childOrder, setChildOrder] = useState([0, 1, 2, 3]);
    const [orbitStyle, setOrbitStyle] = useState({});
    const [selectedMoon, setSelectedMoon] = useState(0);

    var moonDisplayText: Foundations[] = foundations;
    // moonDisplayText = ;

    const [displayText, setDisplayText] = useState(moonDisplayText[0].name);

    const handleMoonClick = (event: any) => {
        let selectedChild = parseInt(event.target.name.split('-')[1]);
        let indexInOrder = childOrder.indexOf(selectedChild);

        if (indexInOrder) {
            setSelectedMoon(null);
            let angleIncrement = 360 - 90 * indexInOrder;
            setOrbitStyle({ transform: `rotate(${currentAngle + angleIncrement}deg)` });
            setCurrentAngle(currentAngle + angleIncrement);
            let arr1 = childOrder.slice(indexInOrder);
            let arr2 = childOrder.slice(0, indexInOrder);
            arr1.push(...arr2);
            setChildOrder(arr1);
        }
    };

    const handleOrbitTransitionEnd = () => {
        setSelectedMoon(childOrder[0]);
        setDisplayText(moonDisplayText[childOrder[0]].name);
    };

    // const handleLabelChange = () => {
    //     if (process.env.REACT_APP_LOCALE === 'ko') {
    //         return  (<p className={classes.title}>Our Product <br />Foundations</p>)
    //         console.log(process.env.REACT_APP_LOCALE)
    //     } else {
    //         return  <p className={classes.title}>Our Product <br />TESTING</p>
    //     }
    // }

    return (
        <div className={classes.root}>
            <div className={classes.orbitContainer}>
                <div className={classes.planet}>
                    {isSKT ? <p className={classes.title}>아슈리온 <br />상품 원칙</p>:<p className={classes.title}>Our Product <br />Foundations</p>}   
                </div>

                <div className={classes.orbitBG}></div>

                <div className={classes.orbit} style={orbitStyle} onTransitionEnd={handleOrbitTransitionEnd}>
                    <button
                        type="button"
                        className={clsx(classes.moon, `${selectedMoon === 0 ? 'selected' : ''}`)}
                        style={{ transform: `rotate(-${currentAngle}deg) ${selectedMoon === 0 ? 'scale(1.5)' : ''}` }}
                        name="moon-0"
                        onClick={handleMoonClick}
                    ></button>

                    <button
                        type="button"
                        className={clsx(classes.moon, `${selectedMoon === 1 ? 'selected' : ''}`)}
                        style={{ transform: `rotate(-${currentAngle}deg) ${selectedMoon === 1 ? 'scale(1.5)' : ''}` }}
                        name="moon-1"
                        onClick={handleMoonClick}
                    ></button>

                    <button
                        type="button"
                        className={clsx(classes.moon, `${selectedMoon === 2 ? 'selected' : ''}`)}
                        style={{ transform: `rotate(-${currentAngle}deg) ${selectedMoon === 2 ? 'scale(1.5)' : ''}` }}
                        name="moon-2"
                        onClick={handleMoonClick}
                    ></button>

                    <button
                        type="button"
                        className={clsx(classes.moon, `${selectedMoon === 3 ? 'selected' : ''}`)}
                        style={{ transform: `rotate(-${currentAngle}deg) ${selectedMoon === 3 ? 'scale(1.5)' : ''}` }}
                        name="moon-3"
                        onClick={handleMoonClick}
                    ></button>
                </div>
            </div>

            <p className={classes.productTitle} dangerouslySetInnerHTML={{ __html: displayText }}></p>
        </div>
    );
}

export default ProductWheel;
