import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';

const Bold = ({ children }) => <span>{children}</span>;

const Text = ({ children }) => <span>{children}</span>;

export const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.CODE]: text => parse(`${text[1]}`),
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },

    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
