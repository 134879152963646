import React from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import 'swiper/swiper-bundle.css';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface CardDetails {
    title: string;
    image: string;
}

export interface CSRTeamProps {
    title: string;
    description: ContentfulDocument;
    cardDetails: CardDetails[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '3em 0',
            // height: '30em',
            [theme.breakpoints.down('xs')]: {
                // minHeight: '40em',
                margin: '2em 0',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            // paddingLeft: '7.5vw;',
            // paddingRight: '7.5vw',
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        centerContents: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        textBox: {
            [theme.breakpoints.up('md')]: {
                paddingRight: theme.spacing(2),
            },
        },
        textTitle: {
            fontSize: '2.125rem',
            fontWeight: 'bold',
            lineHeight: '2.25rem',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.75rem',
            },
        },
        textDescription: {
            fontSize: '1.25rem',
        },
        cardsContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        cardBox: {
            background: '#F0F0F580',
            // height: '20em',
            // width: '20%',
            padding: theme.spacing(2),
            height: '300px',
            width: '185px',
            justifyContent: 'space-around',

            [theme.breakpoints.down('sm')]: {
                height: '200px',
                width: '170px',
            },
        },
        cardTitleBox: {
            // height: '30%',
            fontSize: '1.5rem',
            textAlign: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
        },
        cardImageBox: {
            // height: '70%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                minHeight: '130px',
            },

            [theme.breakpoints.down('sm')]: {
                '&>img': {
                    width: '5em',
                    height: '5em',
                },
            },
        },
        swiper: {
            height: '100%',
            marginBottom: '6em',
            '&>.swiper-pagination-progressbar': {
                top: 'initial',
                bottom: 0,
            },
        },
        slideContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paginationMod: {
            [theme.breakpoints.up('md')]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: '#D5D6DA !important',
            margin: '0px 10px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#8223D2 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
    })
);

function CSRTeam(teamDetails: CSRTeamProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    SwiperCore.use([Pagination, Autoplay]);

    const renderSection = (teamDetails: CSRTeamProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid container direction="row" className={clsx(classes.containerCompress, classes.h100)}>
                <Grid item container direction="column" md={4} className={clsx(classes.centerContents, classes.textBox)}>
                    <Box className={classes.textTitle} style={{width: isMobile ? "100%" : ""}}>{parse(`${teamDetails.title}`)}</Box>
                    <Box className={classes.textDescription}>{documentToReactComponents(teamDetails.description, options)}</Box>
                </Grid>
                <Grid item container direction="row" md={8} className={classes.cardsContainer}>
                    {isMobile ? (
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                                bulletClass: `` + classes.paginationBullet + ``,
                                bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                clickableClass: `` + classes.paginationMod + ``,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {teamDetails.cardDetails.map((card, i) => (
                                <SwiperSlide className={classes.slideContainer} key={card.title}>
                                    <Grid container direction="column" className={classes.cardBox} key={i}>
                                        <Box className={clsx(classes.cardTitleBox, classes.centerContents)}>{card.title}</Box>
                                        <Box className={clsx(classes.cardImageBox, classes.centerContents)}>
                                            <img src={card.image} alt={card.title} />
                                        </Box>
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        teamDetails.cardDetails.map((card, i) => (
                            <Grid container direction="column" className={classes.cardBox} key={card.title}>
                                <Box className={clsx(classes.cardTitleBox, classes.centerContents)}>{card.title}</Box>
                                <Box className={clsx(classes.cardImageBox, classes.centerContents)}>
                                    <img src={card.image} alt={card.title} />
                                </Box>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(teamDetails)}</div>;
}

export default CSRTeam;
