import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography, Grid } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { contentMapping, ServiceItem } from './types';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import { rem, sv } from '../../../../common/functions';
import { colors } from '../../../../themes/default';
import parse from 'html-react-parser';

export interface ServiceListProps {
    services: Array<ServiceItem>;
}

const useStyle = makeStyles(theme => ({
    container: {
        padding: sv(0, 16),
    },
    serviceIdentifier: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        fontSize: rem(1.5),
        lineHeight: 1,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    serviceIcon: {
        height: 30,
    },
    title: {
        fontSize: rem(1.25),
        lineHeight: 1.2,
        letterSpacing: rem(0.15 / 16),
    },
    aboveTheLine: {
        color: colors.indigo,
        lineHeight: 4 / 3,
        fontSize: rem(1.125),
        letterSpacing: rem(0.15 / 16),
    },
}));

const useAccordionStyles = makeStyles(theme => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        background: 'none',
        borderBottom: `2px solid ${theme.palette.grey.A100}`,
        padding: '30px 10px 50px 10px',
        '&:first-child': {
            padding: '0 10px 62px 10px',
        },
        '&.Mui-expanded': {
            paddingBottom: 52,
            margin: 0,
        },
    },
    expanded: {
        maxHeight: 'unset',
        paddingBottom: 52,
    },
}));

const useAccordionSummaryStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        '&.Mui-expanded': {
            margin: sv(12, 0),
        },
    },
    expandIcon: {
        color: theme.palette.primary.main,
        width: 30,
        height: 30,
        padding: 0,
    },
    accordionDetails: {
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0 0',
        },
    },
    accordionContent: {
        marginBottom: theme.spacing(3),
    },
    showcaseContent: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const ServiceListAccordion = ({ services }: ServiceListProps) => {
    const [expanded, setExpanded] = useState<Array<ServiceItem['name']>>([]);

    const classes = useStyle();
    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();

    const handleChange = (panel: string) => (_event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter(item => item !== panel));
        // setExpanded(expanded);
    };

    return (
        <Box className={classes.container}>
            {services.map((service, i) => {
                const Content = contentMapping[service.belowTheLineType || 'text'].type;
                const belowTheLineProps = {
                    [contentMapping[service.belowTheLineType || 'text'].paramKey]: service.belowTheLine,
                };

                const Showcase = contentMapping[service.type || 'img'].type;
                const showcaseProps = {
                    [contentMapping[service.type || 'img'].paramKey]: service.showcase,
                };

                return (
                    <Accordion
                        expanded={expanded.includes(service.name)}
                        onChange={handleChange(service.name)}
                        key={`${service}-${i}`}
                        classes={{
                            root: accordionClasses.root,
                            expanded: accordionClasses.expanded,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownCircleOutlinedIcon color="primary" />}
                            classes={{
                                root: accordionSummaryClasses.root,
                                content: accordionSummaryClasses.content,
                            }}
                        >
                            <Typography className={classes.serviceIdentifier}>
                                <img src={service.icon} alt={service.name} className={classes.serviceIcon} />
                                {service.name}
                            </Typography>
                            <Typography variant="h3" className={classes.title}>
                                {parse(`${service.title}`)}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.aboveTheLine}>
                                {parse(`${service.aboveTheLine}`)}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{
                                root: `${accordionSummaryClasses.accordionDetails}`,
                            }}
                        >
                            <Grid container direction="row">
                                <Grid item xs={12} className={accordionSummaryClasses.accordionContent}>
                                    <Content {...belowTheLineProps} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Showcase {...showcaseProps} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};
