import React, { Fragment, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Box, AppBar, Toolbar, Typography, IconButton, Container } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NavLink, Link } from 'react-router-dom';
import { NavBarStyles } from './NavBarStyles';

import clsx from 'clsx';

export interface NavbarProps {
    companyLabel: string;
    whatWeDoLabel: string;
    partnersLabel: string;
    contactLabel: string;
    faqLabel: string;
    outStoryLabel: string;
    leadershipLabel: string;
    csrLabel: string;
    careersLabel: string;
    headerLogo: string;
}

function NavBar(navbarContent: NavbarProps) {
    const classes = NavBarStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [overlayNavigationOpen, setOverlayNavigationOpen] = useState<boolean>(false);
    const isTaiwan: boolean = process.env.REACT_APP_LOCALE === 'zh-Hant-TW';

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const handleOpenMenu = () => {
        setMenuOpen(true);
    };

    const handleOverlayNavigationOpen = () => {
        setOverlayNavigationOpen(!overlayNavigationOpen);
    };

    return (
        <Fragment>
            <Grid container direction="row">
                <AppBar position="static" className={classes.appBar}>
                    <Container className={classes.containerCompress}>
                        <Toolbar disableGutters={true}>
                            <Grid container direction="row">
                                <Grid item md={5} sm={4} xs={6} container direction="row" className={classes.logoBox}>
                                    <Link to="/" className={classes.logoLink}>
                                        <img src={navbarContent.headerLogo} alt="headerLogo" />
                                    </Link>
                                </Grid>
                                <Grid item md={7} sm={8} xs={6} container direction="row" className={classes.linkBox}>
                                    {isMobile ? (
                                        <Fragment>
                                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOverlayNavigationOpen}>
                                                <MenuIcon />
                                            </IconButton>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Typography variant="h6" className={classes.link}>
                                                <Link to="/company/our-story" className={classes.navLink} onMouseOver={handleOpenMenu}>
                                                    {navbarContent.companyLabel}
                                                </Link>
                                            </Typography>
                                            {/* {!isTaiwan && (
                                                <Typography variant="h6" className={classes.link}>
                                                    <Link to="/insights" className={classes.navLink}>
                                                        Insights
                                                    </Link>
                                                </Typography>
                                            )} */}
                                            <Typography variant="h6" onClick={handleMenuClose} className={classes.link}>
                                                <Link to="/what-we-do" className={classes.navLink}>
                                                    {navbarContent.whatWeDoLabel}
                                                </Link>
                                            </Typography>

                                            <Typography variant="h6" onClick={handleMenuClose} className={classes.link}>
                                                <Link to="/partners" className={classes.navLink}>
                                                    {navbarContent.partnersLabel}
                                                </Link>
                                            </Typography>

                                            <Typography variant="h6" onClick={handleMenuClose} className={classes.link}>
                                                <Link to="/contact-us" className={classes.navLink}>
                                                    {navbarContent.contactLabel}
                                                </Link>
                                            </Typography>

                                            <Typography variant="h6" onClick={handleMenuClose} className={classes.link}>
                                                <Link to="/faqs" className={classes.navLink}>
                                                    {navbarContent.faqLabel}
                                                </Link>
                                            </Typography>
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </Container>
                    <Box className="mini-nav"></Box>
                </AppBar>
            </Grid>
            {menuOpen && !isMobile && (
                <Box className={classes.subMenu} onMouseLeave={handleMenuClose} zIndex="tooltip">
                    <Typography className={classes.link}>
                        <NavLink to="/company/our-story" className={clsx(classes.navLink, classes.subMenuLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.outStoryLabel}
                        </NavLink>
                    </Typography>

                    <Typography className={classes.link}>
                        <NavLink to="/company/leadership" className={clsx(classes.navLink, classes.subMenuLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.leadershipLabel}
                        </NavLink>
                    </Typography>

                    <Typography className={classes.link}>
                        <NavLink to="/company/csr" className={clsx(classes.navLink, classes.subMenuLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.csrLabel}
                        </NavLink>
                    </Typography>

                    <Typography className={classes.link}>
                        <NavLink to="/company/careers" className={clsx(classes.navLink, classes.subMenuLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.careersLabel}
                        </NavLink>
                    </Typography>
                </Box>
            )}
            {overlayNavigationOpen && isMobile && (
                <Box zIndex="tooltip" className={classes.dialogBox}>
                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                        <Link to="/company/our-story" className={clsx(classes.navLink)}>
                            {navbarContent.companyLabel}
                        </Link>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.nestedLink, classes.link)}>
                        <NavLink to="/company/our-story" className={classes.navLink} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.outStoryLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.nestedLink, classes.link)}>
                        <NavLink to="/company/leadership" className={classes.navLink} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.leadershipLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.nestedLink, classes.link)}>
                        <NavLink to="/company/csr" className={classes.navLink} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.csrLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.nestedLink, classes.link)}>
                        <NavLink to="/company/careers" className={classes.navLink} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.careersLabel}
                        </NavLink>
                    </Typography>
                    {!isTaiwan && (
                        <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                            <NavLink to="/insights" className={clsx(classes.navLink)} activeClassName={classes.subMenuActiveLink}>
                                Insights
                            </NavLink>
                        </Typography>
                    )}
                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                        <NavLink to="/what-we-do" className={clsx(classes.navLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.whatWeDoLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                        <NavLink to="/partners" className={clsx(classes.navLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.partnersLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                        <NavLink to="/contact-us" className={clsx(classes.navLink)} activeClassName={classes.subMenuActiveLink}>
                            {navbarContent.contactLabel}
                        </NavLink>
                    </Typography>

                    <Typography variant="h6" onClick={handleOverlayNavigationOpen} className={clsx(classes.dialogLink, classes.link)}>
                        <Link to="/faqs" className={clsx(classes.navLink)}>
                            {navbarContent.faqLabel}
                        </Link>
                    </Typography>
                </Box>
            )}
        </Fragment>
    );
}

export default NavBar;
