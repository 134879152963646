import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import CircleIcon from '../../../../images/what-we-do/check-circle.svg';
import { rem, sv } from '../../../../common/functions';

export interface ListProps {
    content?: Array<string>;
    columnCount?: number;
}

const useStyle = makeStyles(theme => ({
    list: {
        padding: 0,
        margin: 0,
        columnCount: (props: ListProps) => props.columnCount || 1,
        '& li': {
            display: 'flex',
            alignItems: 'center',
            fontSize: rem(1.125),
            listStyleType: 'none',
            padding: sv(0, 0, 0, 38.75),
            backgroundImage: `url(${CircleIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            backgroundSize: 28.75,
            minHeight: 38.75,

            [theme.breakpoints.down('sm')]: {
                fontSize: rem(0.875),
                backgroundSize: 20,
                padding: sv(0, 0, 0, 30),
            },
        },
    },
}));

export const List = (props: ListProps) => {
    const classes = useStyle(props);
    return (
        <ul className={classes.list}>
            {props.content.map(item => (
                <li>{item}</li>
            ))}
        </ul>
    );
};

export default List;
