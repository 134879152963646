import React, { Fragment, useState } from 'react';
import { Box, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import parse from 'html-react-parser';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils/Utils';

interface CultureDetails {
    title: string;
    description: string;
    icon: string;
}

export interface CareersCultureProps {
    title: string;
    subtitle: string;
    description: ContentfulDocument;
    cultureDetails: CultureDetails[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '30em',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        centerContents: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        imageBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(3),

            '&.large': {
                paddingTop: theme.spacing(5),
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        textBox: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginBottom: '1em',
            },
        },
        textBoxTitle: {
            fontSize: '2.125rem',
            marginBottom: theme.spacing(2),
            fontWeight: 'bold',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        textBoxSubTitle: {
            fontSize: '1.25rem',
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(2),
        },
        textBoxDescription: {
            fontSize: '1rem',
        },
        textPrimary: {
            '& > span > a': {
                color: theme.palette.primary.main,
                textDecoration: 'none !important',
            },
        },
        heading: {
            fontSize: '2.125rem',
            fontWeight: 'bold',
            lineHeight: '2.25rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '2.0rem',
                // marginBottom: '1em',
            },
        },
        accordion: {
            margin: 'auto',
            boxShadow: 'none',
            background: 'transparent',
        },
        accordionHeader: {
            margin: theme.spacing(2),
        },
        accordionIcon: {
            height: '100%',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        accordionDetails: {},
        expandIcon: {
            fill: theme.palette.primary.main,
        },
        accordionSummary: {
            [theme.breakpoints.up('md')]: {
                '&>.MuiAccordionSummary-content': {
                    margin: '32px 0',
                },
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '1em',
                marginTop: '1em',
            },
        },
    })
);

function CareersCulture(cultureContent: CareersCultureProps) {
    const [expanded, setExpanded] = useState<number | null>(null);
    const classes = useStyles();

    const handleExpand = (selected: number) => {
        if (selected !== expanded) {
            setExpanded(selected);
            return;
        }
        setExpanded(null);
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" className={clsx(classes.containerCompress, classes.h100)}>
                <Grid item md={3} className={clsx(classes.textBox)}>
                    <Box className={classes.textBoxTitle}>{cultureContent.title}</Box>
                    <Box className={classes.textBoxSubTitle}>{cultureContent.subtitle}</Box>
                    <Box className={classes.textPrimary}>{documentToReactComponents(cultureContent.description, options)}</Box>
                </Grid>
                <Grid item md={9} className={clsx()}>
                    {cultureContent.cultureDetails.map((experience: CultureDetails, i) => {
                        return (
                            <Fragment key={i}>
                                <Grid container direction="row">
                                    <Grid item md={1} sm={1} className={clsx(classes.imageBox, i === 1 ? 'large' : '')}>
                                        <img src={experience.icon} alt={experience.title} />
                                    </Grid>
                                    <Grid item md={11} sm={11}>
                                        <Accordion key={i} className={classes.accordion} onChange={() => handleExpand(i)} expanded={expanded === i}>
                                            <AccordionSummary
                                                expandIcon={expanded === i ? <RemoveCircleOutlineIcon className={classes.expandIcon} /> : <AddCircleOutlineIcon className={classes.expandIcon} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                className={clsx(classes.centerContents, classes.accordionSummary)}
                                            >
                                                <Typography className={classes.heading}>{parse(experience.title)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Typography>{parse(experience.description)}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
}

export default CareersCulture;
