import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, Link, Typography, Modal, Backdrop, Fade, Box, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useProgressiveImage } from '../../utils/LazyBackground';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import Spinner from '../../utils/Spinner';

import Trapezoid from '../../../images/leadership/modaltrapezoid.svg';
import { ReactComponent as CloseIcon } from '../../../images/leadership/close.svg';
import { ReactComponent as CloseIconWhite } from '../../../images/leadership/close-white.svg';
import 'swiper/swiper-bundle.css';
import { ContentfulEntry } from '../../../utils/ContentfulLoader';

export interface LeaderProfile {
    name: string;
    position: string;
    image: ContentfulEntry;
    statement: ContentfulDocument | null;
    index: number | null;
}

export interface LeaderBoardProps {
    title: string;
    regionalLabel: string;
    countryLabel: string;
    regionalLeaders: LeaderProfile[];
    countryLeaders: LeaderProfile[];
}

interface LeaderImageProps {
    image: ContentfulEntry;
}

const LeaderImage = ({ image }: LeaderImageProps) => {
    const loaded = useProgressiveImage(image.fields?.file?.url);
    const classes = LeadershipBoardStyles();

    return loaded ? <img src={loaded} /> : <div className={classes.placeholder}></div>;
};

const LeadershipBoard: React.FunctionComponent<LeaderBoardProps> = (leadshipBoardContent: LeaderBoardProps) => {
    const classes = LeadershipBoardStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={classes.textBold}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    SwiperCore.use([Pagination, Autoplay]);

    const [activeBoard, setActiveBoard] = useState<string>('regional');
    const [selectedLeader, setSelectedLeader] = useState<LeaderProfile>({ name: '', position: '', image: null, statement: null, index: null });
    const [mobileRegionalLeaders, setMobileRegionalLeaders] = useState<LeaderProfile[][]>([]);
    const [mobileCountryLeaders, setMobileCountryLeaders] = useState<LeaderProfile[][]>([]);
    const [onHover, setOnHover] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(true);

    const breakdownLeaders = () => {
        const regionalRows = [...Array(Math.ceil(leadshipBoardContent.regionalLeaders.length / 4))];
        const countryRows = [...Array(Math.ceil(leadshipBoardContent.countryLeaders.length / 4))];
        const mobileRegionalLeaders: LeaderProfile[][] = regionalRows.map((row, idx) => leadshipBoardContent.regionalLeaders.slice(idx * 4, idx * 4 + 4));
        const mobileCountryLeaders: LeaderProfile[][] = countryRows.map((row, idx) => leadshipBoardContent.countryLeaders.slice(idx * 4, idx * 4 + 4));
        setMobileRegionalLeaders(mobileRegionalLeaders);
        setMobileCountryLeaders(mobileCountryLeaders);
        setPageLoading(false);
    };

    useEffect(() => {
        breakdownLeaders();
    }, []);

    const handleBoardLinkClick = useCallback(() => {
        setActiveBoard(activeBoard === 'regional' ? 'country' : 'regional');
    }, [activeBoard]);

    const handleMemberMouseOver = () => {
        setOnHover(!onHover);
    };

    const handleMemberClick = (selected: LeaderProfile) => {
        if (selected.statement) {
            setSelectedLeader(selected);
            setShowModal(true);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {pageLoading ? (
                <Spinner />
            ) : (
                <div>
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'} className={classes.title}>
                                {leadshipBoardContent.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.boardLinkRoot}>
                                <div className={classes.boardLinkItem}>
                                    <Link
                                        onClick={handleBoardLinkClick}
                                        className={clsx({
                                            [classes.boardLink]: true,
                                            [classes.boardLinkActive]: activeBoard === 'regional',
                                        })}
                                    >
                                        {leadshipBoardContent.regionalLabel}
                                    </Link>
                                </div>
                                <div className={classes.boardLinkItemSeperator}>|</div>
                                <div className={classes.boardLinkItem}>
                                    <Link
                                        onClick={handleBoardLinkClick}
                                        className={clsx({
                                            [classes.boardLink]: true,
                                            [classes.boardLinkActive]: activeBoard === 'country',
                                        })}
                                    >
                                        {leadshipBoardContent.countryLabel}
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {isMobile ? (
                                <Swiper
                                    className={clsx(classes.swiper)}
                                    // autoplay
                                    // spaceBetween={1}
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                        bulletClass: `` + classes.paginationBullet + ``,
                                        bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                        clickableClass: `` + classes.paginationMod + ``,
                                    }}
                                >
                                    {pageLoading ? (
                                        <p>loading</p>
                                    ) : (
                                        (activeBoard === 'regional' ? mobileRegionalLeaders : mobileCountryLeaders).map((leaderRow, i) => (
                                            <SwiperSlide key={`leader-row-${i}`} className={classes.slideContainer}>
                                                <Grid container direction="row">
                                                    {leaderRow.map((leader, i) => (
                                                        <Grid
                                                            key={`regional-leader-${leader.index}`}
                                                            item
                                                            xs={6}
                                                            className={clsx(classes.leaderItem)}
                                                            onMouseOver={handleMemberMouseOver}
                                                            onMouseOut={handleMemberMouseOver}
                                                            onClick={() => handleMemberClick(leader)}
                                                        >
                                                            <div className={classes.leaderPhoto}>
                                                                <LeaderImage image={leader.image} />
                                                            </div>
                                                            <div className={clsx(classes.leaderName, leader.name.length > 20 ? 'longName' : '')}>{leader.name}</div>
                                                            <div className={classes.leaderPosition}>{leader.position}</div>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>
                            ) : (
                                <Grid container className={classes.leaders}>
                                    {pageLoading ? (
                                        <p>loading</p>
                                    ) : (
                                        (activeBoard === 'regional' ? leadshipBoardContent.regionalLeaders : leadshipBoardContent.countryLeaders).map((leader, i) => {
                                            return (
                                                <Grid
                                                    key={`regional-leader-${leader.index}`}
                                                    item
                                                    xs={3}
                                                    className={clsx(classes.leaderItem)}
                                                    onMouseOver={handleMemberMouseOver}
                                                    onMouseOut={handleMemberMouseOver}
                                                    onClick={() => handleMemberClick(leader)}
                                                >
                                                    <div className={classes.leaderPhoto}>
                                                        <LeaderImage image={leader.image} />
                                                    </div>
                                                    <div className={classes.leaderName}>{leader.name}</div>
                                                    <div className={classes.leaderPosition}>{leader.position}</div>
                                                </Grid>
                                            );
                                        })
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={showModal}
                        onClose={handleModalClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={showModal}>
                            {isMobile ? (
                                <Fragment>
                                    <Box className={classes.paper}>
                                        <Box className={classes.modalContentBox}>
                                            <Box className={classes.modalImageBox}>
                                                <IconButton onClick={handleModalClose} className={classes.modalHeader}>
                                                    <CloseIconWhite />
                                                </IconButton>
                                                <LeaderImage image={selectedLeader?.image} />
                                            </Box>
                                            <Box className={classes.modalNameBox}>
                                                <Box className={classes.modalTextName}>{selectedLeader.name}</Box>
                                                <Box className={classes.modalTextPosition}>{selectedLeader.position}</Box>
                                            </Box>
                                            <Box className={classes.modalTextBox}>
                                                <Box className={classes.modalTextDescription}>{documentToReactComponents(selectedLeader.statement, options)}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Fragment>
                            ) : (
                                <div className={classes.paper}>
                                    <Grid container direction="row" className={classes.modalContentBox}>
                                        <Grid item md={3} sm={12} className={classes.imageBox}>
                                            <LeaderImage image={selectedLeader?.image} />
                                        </Grid>
                                        <Grid item md={8} sm={12} className={classes.modalTextBox}>
                                            <Box className={classes.modalTextName}>{selectedLeader.name}</Box>
                                            <Box className={classes.modalTextPosition}>{selectedLeader.position}</Box>
                                            <Box className={classes.modalTextDescription}>{documentToReactComponents(selectedLeader.statement, options)}</Box>
                                        </Grid>
                                        <Grid item md={1} className={classes.modalHeader}>
                                            <IconButton onClick={handleModalClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Fade>
                    </Modal>
                </div>
            )}
        </>
    );
};

const LeadershipBoardStyles = makeStyles(theme => ({
    root: {
        float: 'left',
        width: '100%',
        paddingLeft: '7vw',
        paddingRight: '7vw',
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {},
    },
    title: {
        textAlign: 'center',
        fontSize: '2.125rem',
        lineHeight: '2.25rem',
        marginTop: theme.spacing(11.5),
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.875rem',
        },
    },
    boardLinkRoot: {
        // width: theme.spacing(33.75),
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
    },
    boardLinkItem: {
        float: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
    },
    boardLinkItemSeperator: {
        fontWeight: 'bold',
        float: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    boardLink: {
        color: `#A5AAAF !important`,
        cursor: 'pointer',
    },
    boardLinkActive: {
        color: `${theme.palette.primary.main} !important`,
        cursor: 'default',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    leaders: {
        width: '100%',
        maxWidth: theme.spacing(140),
        margin: '0 auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },

    leaderItem: {
        width: theme.spacing(24.375),
        minHeight: theme.spacing(41.25),
        fontSize: '1rem',
        '&:hover': {
            cursor: 'pointer',
        },
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            minHeight: '300px',
        },
    },

    leaderName: {
        float: 'left',
        width: '100%',
        textAlign: 'center',
        fontSize: theme.spacing(3),
        lineHeight: '28px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',

            '&.longName': {
                fontSize: '1.15rem',
            },
        },
    },
    leaderPhoto: {
        float: 'left',
        width: '100%',
        textAlign: 'center',
        filter: 'grayscale(1)',

        '&:hover': {
            filter: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            // height: '190px',
            // width: '190px',
            '&>img': {
                width: '100%',
                maxWidth: '190px',
            },
        },
    },

    leaderPosition: {
        float: 'left',
        color: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {
            fontWeight: 'normal',
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            fontSize: '0.875rem',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
            paddingBottom: '5vh',
        },
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
        // height: '50%',
        // width: '80%',
        height: '600px',
        width: '1200px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            borderImage: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%) 1',
            borderImageRepeat: 'none',
            border: 0,
            borderTop: '10px',
            borderTopStyle: 'solid',
            height: '500px',
            minWidth: '320px',
            textAlign: 'center',
        },
    },
    modalHeader: {
        [theme.breakpoints.up('md')]: {
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            right: '5%',
            marginTop: '-90px',
            stroke: 'white',
            '&span>svg>circle': {
                stroke: 'white',
            },
            '&span>svg>circle>path': {
                fill: 'white',
            },
            // top: 0,
            // right: 0,
            // marginTop: '10%',
        },
    },
    modalContentBox: {
        height: '100%',
    },
    imageBox: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(2),
            background: 'url(' + Trapezoid + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '-100px',
            '&>img': {
                width: '190px',
            },
        },
    },
    modalTextBox: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            textAlign: 'justify',
            overflow: 'scroll',
        },
    },
    modalTextName: {
        fontSize: '3rem',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },
    modalTextPosition: {
        fontSize: '1.5rem',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(5),
        textTransform: 'uppercase',
        fontWeight: 'bold',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            fontWeight: 'normal',
            marginBottom: theme.spacing(2),
        },
    },
    modalTextDescription: {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '.875rem',
            maxHeight: '23em',
        },
    },
    modalCloseButton: {
        color: theme.palette.primary.main,
        border: '1px solid',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    paginationMod: {
        [theme.breakpoints.up('md')]: {
            bottom: '20px !important',
            right: '100px !important',
            width: 'auto !important',
            left: 'auto !important',
            margin: 0,
        },
    },
    paginationBullet: {
        padding: '0px 8px',
        borderRadius: '0 !important',
        background: '#D5D6DA !important',
        margin: '0px 10px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            padding: '0px 5px',
        },
    },
    paginationBulletActive: {
        backgroundColor: '#8223D2 !important',
        width: '1rem',
        height: '1rem',
        borderRadius: '0.5rem',
    },
    swiper: {
        // background: '#8223D2',
        height: '100%',
    },
    slideContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    placeholder: {
        width: '190px',
        height: '190px',
        background: 'darkgray',
        borderRadius: '50%',
        filter: 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.25)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.25))',
        marginBottom: theme.spacing(6),
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '130px',
            height: '130px',
        },
    },
    modalImageBox: {
        height: 72,
        '&>img': {
            width: '190px',
            marginTop: '-100px',
        },
    },
    modalNameBox: {},
    textBold: {
        fontWeight: 'bold',
    },
}));

export default LeadershipBoard;
