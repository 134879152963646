import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import ProductWheel, { Foundations } from './ProductWheel';
import { createStyles, makeStyles, Theme, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

export interface PartnersProductFoundationProps {
    title: string;
    description: ContentfulDocument;
    buttonText: string;
    buttonUrl: string;
    centerText: string;
    foundations: Foundations[];
    productWheelImage: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '40em',
            [theme.breakpoints.down('sm')]: {
                height: '60em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            // paddingTop: theme.spacing(5),
            // paddingBottom: theme.spacing(5),
            padding: theme.spacing(2),
            paddingLeft: '7vw;',
            paddingRight: '7vw',
        },
        centerContents: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        spaceBelow: {
            marginBottom: theme.spacing(3),
        },
        topSection: {
            height: '70%',
            [theme.breakpoints.down('sm')]: {
                height: '80%',
            },
        },
        productBox: {
            width: '100%',
            magrinTop: theme.spacing(2),
            magrinBottom: theme.spacing(2),
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        textBox: {
            display: 'flex',
            justifyContent: 'center',
        },
        textTitle: {
            fontSize: '2.125rem',
            fontWeight: 'bold',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        textDescription: {
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(3),

            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        imageBox: {
            display: 'flex',
        },
        lowerBox: {},
        lowerTextBox: {
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                width: '60%',
                paddingLeft: '20em',
                paddingRight: '20em',
            },
        },
        buttonLink: {
            textDecoration: 'none',
        },
    })
);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        borderRadius: '2em',
        backgroundColor: '#8223d2',
        '&:hover': {
            backgroundColor: '#8223d2',
        },
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        padding: theme.spacing(2),
    },
}))(Button);

function PartnersProductFoundation(productFoundationDetails: PartnersProductFoundationProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderSection = (productFoundationDetails: PartnersProductFoundationProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid className={clsx(classes.h100, classes.containerCompress)}>
                <Grid container direction="row" className={classes.topSection}>
                    <Grid item md={4} sm={12} container direction="column" className={classes.textBox}>
                        <Box className={classes.textTitle}>{parse(`${productFoundationDetails.title}`)}</Box>
                        <Box className={classes.textDescription}>
                            <Box>{documentToReactComponents(productFoundationDetails.description, options)}</Box>
                        </Box>
                    </Grid>
                    <Grid item md={8} sm={12} className={clsx(classes.centerContents, classes.productBox)}>
                        {isMobile ? <ProductWheel {...productFoundationDetails.foundations} /> : <img src={productFoundationDetails.productWheelImage} alt="Product Foundations" />}
                    </Grid>
                </Grid>
                <Grid container direction={isMobile ? 'column' : 'row'} className={classes.lowerBox}>
                    <Grid item md={12} sm={12} className={classes.lowerTextBox}>
                        <Box component="p" className={clsx(classes.textDescription)}>
                            {productFoundationDetails.centerText}
                        </Box>
                        <Box>
                            <Link to={productFoundationDetails.buttonUrl} className={classes.buttonLink}>
                                <ColorButton size="large" variant="contained" color="primary">
                                    {productFoundationDetails.buttonText}
                                </ColorButton>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(productFoundationDetails)}</div>;
}

export default PartnersProductFoundation;
