import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export interface Content {
    title: string;
    description: string[];
}

export interface PrivacyContentProps {
    title: string;
    dateUpdated: string;
    content: ContentfulDocument;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header: {
            fontSize: theme.spacing(4.25),
            fontWeight: 'bold',
            marginTop: theme.spacing(1),
        },
        title: {
            fontSize: theme.spacing(2.25),
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(2),
            },
        },
        date: {
            fontSize: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(1),
            },
        },
        containerCompress: {
            padding: theme.spacing(6),
            paddingLeft: '7vw;',
            paddingRight: '7vw',
        },
        content: {
            fontSize: theme.spacing(2),
            marinBottom: theme.spacing(3),
        },
    })
);

function PrivacyContent(privacyContent: PrivacyContentProps) {
    const classes = useStyles();

    const Bold = ({ children }: any) => <p className={classes.title}>{children}</p>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderSection = (): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid container direction="row">
                <Grid md={4} xs={12}>
                    <p className={classes.header}>{privacyContent.title}</p>
                    <p className={classes.date}>{privacyContent.dateUpdated}</p>
                    {process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && <Link to="/eng/privacy-policy">View in English</Link>}
                </Grid>
                <Grid md={8} xs={12}>
                    <Box>{documentToReactComponents(privacyContent.content, options)}</Box>
                </Grid>
            </Grid>
        );

        return jsx;
    };

    return <div className={clsx(classes.root, classes.containerCompress)}>{renderSection()}</div>;
}

export default PrivacyContent;
