import React, { Fragment, useState } from 'react';
import { Grid, Box, Card, CardContent, Collapse, Hidden, Grow } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import clsx from 'clsx';
import ReactCardFlip from 'react-card-flip';
import 'swiper/swiper-bundle.css';
import parse from 'html-react-parser';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface HelpType {
    title: string;
    description: string;
    image: string;
    color: string;
}

export interface ContactInfoProps {
    helpDetails: HelpType[];
    headerTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // height: '25em',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
        },
        h100: {
            height: '100% !important',
        },
        containerCompress: {
            justifyContent: 'center',
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        sectionBox: {
            height: '20em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            [theme.breakpoints.down('sm')]: {
                height: '25em',
            },
        },
        card: {
            [theme.breakpoints.up('md')]: {
                maxWidth: '32%',
            },
            display: 'flex',
            borderRadius: 0,
            '&:hover': {
                cursor: 'pointer',
            },

            [theme.breakpoints.down('sm')]: {
                height: '280px',
                width: '220px',
                boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
            },
        },
        imageBox: {
            // height: '70%',
            height: '75%',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        cardContentImage: {
            height: '18em',
            width: '18em',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingBottom: '20px',

            '&.expanded': {
                // height: '10em',
                width: '15em',
            },
        },
        cardContentTitle: {
            color: theme.palette.primary.main,
            fontSize: '1.5rem',
        },
        contentBox: {
            display: 'flex',
        },
        collapseContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '0%',
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),

            '&.expanded': {
                width: '100%',
            },
            '&>.MuiCollapse-wrapper': {
                height: '100%',
            },
        },

        cardContentExpanded: {
            textAlign: 'start',
            height: '100% !important',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            // width: 0,

            [theme.breakpoints.up('md')]: {
                marginLeft: '3em',
            },
            [theme.breakpoints.down('sm')]: {},

            '&.expanded': {
                // width: '100%',
                transition: theme.transitions.create(['width'], {
                    duration: theme.transitions.duration.complex,
                    easing: theme.transitions.easing.easeInOut,
                    delay: '1000ms',
                }),
            },
        },
        expandedTitle: {
            fontSize: '2.125rem',
            marginBottom: theme.spacing(2),

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        divider: {
            height: theme.spacing(1),
            background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)',
            marginBottom: theme.spacing(2),
        },
        swiper: {
            height: '100%',
        },
        slideContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paginationMod: {
            [theme.breakpoints.up('md')]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: '#D5D6DA !important',
            margin: '0px 10px !important',

            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#8223D2 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
        titleBox: {
            fontSize: '1.5rem',
            lineHeight: '1.875rem',
            paddingLeft: '7.5vw',
            paddingRight: '7.5vw',
            textAlign: 'center',
        },
    })
);

function ContactInfo({ helpDetails, headerTitle }: ContactInfoProps) {
    const [expanded, setExpanded] = useState<number>();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    SwiperCore.use([Pagination, Autoplay]);

    const handleExpand = selected => {
        if (selected !== expanded) {
            setExpanded(selected);
            // return;
        } else {
            setExpanded(null);
        }
    };

    const renderSection = ({ helpDetails, headerTitle }: ContactInfoProps): JSX.Element => {
        let jsx: JSX.Element;
        jsx = (
            <Grid container direction="row" className={clsx(classes.sectionBox)}>
                <Grid md={12} sm={12} container direction="row" className={clsx(isMobile && classes.h100, classes.containerCompress)}>
                    <Hidden mdUp>
                        <Box className={classes.titleBox}>
                            <b>{parse(`${headerTitle}`)}</b>
                        </Box>
                    </Hidden>
                    {isMobile ? (
                        <Swiper
                            className={clsx(classes.swiper)}
                            spaceBetween={50}
                            slidesPerView={1}
                            autoplay={isMobile ? { delay: 2500, disableOnInteraction: true, pauseOnMouseEnter: true } : false}
                            pagination={{
                                clickable: true,
                                bulletClass: `` + classes.paginationBullet + ``,
                                bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                clickableClass: `` + classes.paginationMod + ``,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {helpDetails.map((help, i) => (
                                <SwiperSlide className={classes.slideContainer}>
                                    <Grid item xs={12} className={classes.imageBox}>
                                        <Box onClick={() => handleExpand(i)} className={clsx()}>
                                            <Grid container direction="row">
                                                <Fragment>
                                                    <ReactCardFlip isFlipped={expanded === i} flipDirection="horizontal">
                                                        <Card className={clsx(classes.card)} style={{ background: help.color }}>
                                                            <Grid item xs={12} className={classes.imageBox}>
                                                                <CardContent className={clsx(classes.cardContentImage, expanded !== null ? 'expanded' : '')}>
                                                                    <Box className={classes.imageBox}>
                                                                        <img src={help.image} alt={help.title} />
                                                                    </Box>
                                                                    <Box className={classes.cardContentTitle}>{help.title}</Box>
                                                                </CardContent>
                                                            </Grid>
                                                        </Card>
                                                        <Card className={clsx(classes.card)} style={{ background: 'white' }}>
                                                            <Grid item xs={12}>
                                                                <CardContent className={classes.cardContentExpanded}>
                                                                    <Box className={classes.expandedTitle}>{help.title}</Box>
                                                                    <Box className={classes.divider}></Box>
                                                                    <Box>{parse(help.description)}</Box>
                                                                </CardContent>
                                                            </Grid>
                                                        </Card>
                                                    </ReactCardFlip>
                                                </Fragment>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        helpDetails.map((help, i) => (
                            <Card onClick={() => handleExpand(i)} className={classes.card} style={{ background: expanded === i ? 'white' : help.color }}>
                                <Grid container direction="row">
                                    <Fragment>
                                        <Grid item md={4} sm={12} className={classes.imageBox}>
                                            <CardContent className={clsx(classes.cardContentImage, expanded !== null ? 'expanded' : '')}>
                                                <Box className={classes.imageBox}>
                                                    <img src={help.image} alt={help.title} />
                                                </Box>
                                                {expanded !== i && <Box className={classes.cardContentTitle}>{help.title}</Box>}
                                            </CardContent>
                                        </Grid>
                                        <Grid item md={8} sm={12} className={clsx(classes.contentBox, classes.h100)}>
                                            <Grow in={expanded === i} timeout={500}>
                                                <Collapse in={expanded === i} timeout={0} unmountOnExit className={clsx(classes.collapseContainer, expanded === i ? 'expanded' : '')}>
                                                    <CardContent className={clsx(classes.cardContentExpanded, expanded === i ? 'expanded' : '')}>
                                                        <Box className={classes.expandedTitle}>{help.title}</Box>
                                                        <Box className={classes.divider}></Box>
                                                        <Box>{parse(help.description)}</Box>
                                                    </CardContent>
                                                </Collapse>
                                            </Grow>
                                        </Grid>
                                    </Fragment>
                                </Grid>
                            </Card>
                        ))
                    )}
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection({ helpDetails, headerTitle })}</div>;
}

export default ContactInfo;
