import React, { Fragment } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Rating from '@material-ui/lab/Rating';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import parse from 'html-react-parser';

import 'swiper/swiper-bundle.css';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface QuickFacts {
    text: string;
    title: string;
    units: string;
}

interface Ratings {
    rater: string;
    rating: string;
    reviewers: string;
}

export interface AboutStatisticsProps {
    quickFacts: QuickFacts[];
    ratingsText: ContentfulDocument;
    ratings: Ratings[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // height: '40em',
            [theme.breakpoints.down('sm')]: {
                // height: '40em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        spaceBelow: {
            marginBottom: theme.spacing(5),
        },
        factsBox: {
            textAlign: 'center',
            marginBottom: theme.spacing(3),
        },
        divider: {
            marginRight: '-1px',
        },
        factsTitle: {
            fontFamily: 'sans-serif',
            fontSize: '6rem',
            background: '-webkit-linear-gradient(#8223D2 0%, #3700B3 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            // lineHeight: theme.spacing(9),
            '&.smaller': {
                fontSize: '5.5rem !important',
            },
            '&>span': {
                height: '80%',
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '3.75rem',
                '&.smaller': {
                    fontSize: '3.5rem !important',
                },
            },
        },
        factsUnit: {
            verticalAlign: '40px',
            fontSize: '2.5rem',
            background: '-webkit-linear-gradient(#8223D2 0%, #3700B3 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.25rem',
                verticalAlign: '15px',
            },
        },
        factsDescription: {
            fontSize: '1.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
            },
        },
        ratingsBox: {
            textAlign: 'center',
            marginTop: theme.spacing(3),
            // marginBottom: theme.spacing(3),
        },
        ratingsText: {
            fontSize: '2.125rem',
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        rater: {
            fontSize: '2.063rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
        rating: {},
        reviewers: {
            fontSize: '1rem',
            color: '#231F20',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
            },
        },
        swiper: {
            height: '100%',
            '&>.swiper-pagination-progressbar': {
                top: 'initial',
                bottom: 0,
            },
        },
        slideContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

const useRatingStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
    },
}));

function AboutStatistics(aboutStatisticsDetails: AboutStatisticsProps) {
    const classes = useStyles();
    const ratingClasses = useRatingStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var quickFactsLength: GridSize;
    var ratingsLength: GridSize;

    if (12 / aboutStatisticsDetails.ratings.length === 4) {
        ratingsLength = 4;
    }
    if (12 / aboutStatisticsDetails.ratings.length === 3) {
        ratingsLength = 3;
    }
    if (12 / aboutStatisticsDetails.ratings.length === 2) {
        ratingsLength = 2;
    }
    if (12 / aboutStatisticsDetails.ratings.length === 1) {
        ratingsLength = 1;
    }

    if (12 / aboutStatisticsDetails.quickFacts.length === 4) {
        quickFactsLength = 4;
    }
    if (12 / aboutStatisticsDetails.quickFacts.length === 3) {
        quickFactsLength = 3;
    }
    if (12 / aboutStatisticsDetails.quickFacts.length === 2) {
        quickFactsLength = 2;
    }
    if (12 / aboutStatisticsDetails.quickFacts.length === 1) {
        quickFactsLength = 1;
    }

    SwiperCore.use([Pagination, Autoplay]);

    const Bold = ({ children }: any) => <span className={classes.textPrimary}>{children}</span>;

    const Text = ({ children }: any) => <p className={classes.ratingsText}>{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },
    };

    const renderStatistics = (aboutStatisticsDetails: AboutStatisticsProps): JSX.Element => {
        let jsx: JSX.Element;
        jsx = (
            <Grid container direction="row" className={clsx(classes.containerCompress)}>
                <Grid container direction="row" className={classes.spaceBelow}>
                    {isMobile ? (
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            // spaceBetween={50}
                            slidesPerView={1}
                            pagination={{
                                type: 'progressbar',
                                clickable: true,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {aboutStatisticsDetails.quickFacts.map((fact: any, index: any) => (
                                <SwiperSlide className={classes.slideContainer}>
                                    <Grid item container direction="column" className={classes.factsBox}>
                                        <Grid item>
                                            <Box>
                                                <span className={clsx(classes.factsTitle, process.env.REACT_APP_LOCALE === 'ko' ? 'smaller' : '')}>{fact.title}</span>
                                                <span className={clsx(classes.factsTitle, classes.factsUnit)}>
                                                    <span>{fact.units}</span>
                                                </span>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box>
                                                <span className={classes.factsDescription}>{fact.text}</span>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        aboutStatisticsDetails.quickFacts.map((fact: any, index: any) => (
                            <Fragment key={fact.title}>
                                <Grid item md={quickFactsLength} sm={12} container direction="column" className={classes.factsBox}>
                                    <Grid item>
                                        <Box>
                                            <span className={clsx(classes.factsTitle, process.env.REACT_APP_LOCALE === 'ko' ? 'smaller' : '')}>{fact.title}</span>
                                            <span className={clsx(classes.factsTitle, classes.factsUnit)}>
                                                <span>{fact.units}</span>
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box>
                                            <span className={classes.factsDescription}>{fact.text}</span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {(index < aboutStatisticsDetails.quickFacts.length - 1 && <Divider orientation="vertical" flexItem className={classes.divider}></Divider>) || null}
                            </Fragment>
                        ))
                    )}
                </Grid>
                <Grid container direction="row" className={clsx(classes.ratingsBox, classes.spaceBelow)}>
                    <Grid item md={12}>
                        <Box>{documentToReactComponents(aboutStatisticsDetails.ratingsText, options)}</Box>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={clsx(classes.ratingsBox, classes.spaceBelow)}>
                    {isMobile ? (
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            slidesPerView={1}
                            pagination={{
                                type: 'progressbar',
                                clickable: true,
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            <Grid container direction="column">
                                {aboutStatisticsDetails.ratings.map((rating, index) => (
                                    <SwiperSlide className={classes.slideContainer} key={index}>
                                        <Grid item container direction="column" className={classes.factsBox}>
                                            <Grid item>
                                                <Box className={classes.rater}>{rating.rater}</Box>
                                            </Grid>
                                            <Grid item>
                                                <Box className={classes.rating}>
                                                    <Rating name="read-only" value={parseFloat(rating.rating)} precision={0.1} readOnly classes={{ root: ratingClasses.root }} />
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box className={classes.reviewers}>{rating.reviewers}</Box>
                                            </Grid>
                                        </Grid>
                                    </SwiperSlide>
                                ))}
                            </Grid>
                        </Swiper>
                    ) : (
                        aboutStatisticsDetails.ratings.map((rating, index) => (
                            <Fragment key={index}>
                                <Grid item md={ratingsLength} sm={ratingsLength} xs={12} container direction="column" className={classes.factsBox}>
                                    <Grid item>
                                        <Box className={classes.rater}>{rating.rater}</Box>
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.rating}>
                                            <Rating name="read-only" value={parseFloat(rating.rating)} precision={0.1} readOnly classes={{ root: ratingClasses.root }} />
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.reviewers}>{rating.reviewers}</Box>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))
                    )}
                </Grid>
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderStatistics(aboutStatisticsDetails)}</div>;
}

export default AboutStatistics;
