import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import parse from 'html-react-parser';

export interface TextProps {
    content?: Array<string>;
}

const useStyle = makeStyles(theme => ({
    paragraph: {
        whiteSpace: 'break-spaces',

        '&.inUl': {
            marginTop: 0,
        },
    },
    unorderedList: {
        padding: '0 !important',
    },
    listItem: {
        marginLeft: theme.spacing(3),
    },
}));

export const Text = (props: TextProps) => {
    const classes = useStyle();
    return (
        <>
            {typeof props.content == 'object' ? (
                <ul className={classes.unorderedList}>
                    {props.content.map((line, i) => (i >= 1 ? <li className={classes.listItem}>{line}</li> : <p className={clsx(classes.paragraph, 'inUl')}>{line}</p>))}
                </ul>
            ) : (
                <span className={classes.paragraph}>{parse(props.content)}</span>
            )}
        </>
    );
};

export default Text;
