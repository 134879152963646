import React, { useState, useEffect } from 'react';
import './App.css';
import { Box, ThemeProvider, CssBaseline, Container } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference } from './utils/ContentfulLoader';
import Spinner from './components/utils/Spinner';

import NavBar, { NavbarProps } from './components/pages/Layout/NavigationBar';
import Footer, { FooterProps } from './components/pages/Layout/Footer';
import NoMatch from './components/common/NoMatch';
import Home from './components/pages/Homepage/Home';
import AboutPage from './components/pages/AboutUs/AboutPage';
import FaqPage from './components/pages/Faq/FaqPage';
import LeadershipPage from './components/pages/Leadership/LeadershipPage';
import CareersPage from './components/pages/Careers/CareersPage';
import CSRPage from './components/pages/CSR/CSRPage';
import PartnersPage from './components/pages/Partners/PartnersPage';
import ContactPage from './components/pages/Contact/ContactPage';
import WhatWeDoPage from './components/pages/WhatWeDo/WhatWeDoPage';
import TermsPage from './components/pages/TermsOfUse/TermsPage';
import TermsPageBah from './components/pages/TermsOfUse/TermsPageBah';
import TermsPageTha from './components/pages/TermsOfUse/TermsPageTha';
import TermsPageTW from './components/pages/TermsOfUse/TermsPageTW';
import TermsFET from './components/pages/TermsOfUse/TermsFET';
import TermsConditionsPageTW from './components/pages/TermsOfUse/TermsConditionsPageTW';
import PrivacyPageMain from './components/pages/Privacy/PrivacyPageMain';
import PrivacyPageBah from './components/pages/Privacy/PrivacyPageBah';
import PrivacyPageTha from './components/pages/Privacy/PrivacyPageTha';
import PrivacyPageTW from './components/pages/Privacy/PrivacyPageTW';

import SiteMapPage from './components/pages/SiteMap/SiteMap';
import Soluto from './components/pages/Solutopage/Soluto';
import SKTCareersLandingPage from './components/pages/SKTCareers/SKTCareersLandingPage';
import InsightsPage from './components/pages/Insights/InsightsPage';
import InsightsArticlePage from './components/pages/Insights/InsightsArticlePage';
import ScrollToTop from './components/utils/ScrollToTop';
import TermsConditionsDataExpress from './components/pages/TermsOfUse/TermsConditionsDataExpress';
import TermsConditionsSynnex from './components/pages/TermsOfUse/TermsConditionsSynnex';

import { theme } from './themes/default';
import PageRedirect from './components/utils/PageRedirect';
import ToastBar from './components/pages/Layout/ToastBar';
import { get } from 'lodash';
import CookieConsent from './components/common/CookieConsent';

//DEV NOTES: WE ARE USING TH SPACE FOR NAV-FOOTER CONTENT BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageEntryId: string = process.env.REACT_APP_HEADERFOOTER_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

/**
 * @DevNotes - Announcement Entry in APAC space
*/
const d2cPageEntryId: string = process.env.REACT_APP_D2CHOMEPAGE_ENTRY_ID;
const apacSpaceId: string = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const apacAccessToken: string = process.env.REACT_APP_CONTENTFUL_D2CHOMEPAGE_ACCESS_TOKEN;
const d2cHomePageEnv: string = process.env.REACT_APP_CONTENTFUL_D2CHOMEPAGE_ENVIRONMENT;

const isSKT: boolean = process.env.REACT_APP_LOCALE === 'ko';
const isTaiwan: boolean = process.env.REACT_APP_LOCALE === 'zh-Hant-TW';

console.log(pageEntryId);

function App() {
    // const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [headerContent, setHeaderContent] = useState<NavbarProps>();
    const [footerContent, setFooterContent] = useState<FooterProps>();
    const [announcementMessageContent, setAnnouncementMessageContent] = useState<string>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    var contentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();
    var apacContentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();
    
    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry').then((entry: ContentfulEntry) => {
            let headerLogoReferences: ContentfulReference[] = entry.getReferences('headerLogo');
            let footerLogoReferences: ContentfulReference[] = entry.getReferences('footerLogo');
            let headerLogoPromise: Promise<ContentfulEntry> = headerLogoReferences[0].getData(contentfulConfig);
            let footerLogoPromise: Promise<ContentfulEntry> = footerLogoReferences[0].getData(contentfulConfig);

            Promise.all([headerLogoPromise, footerLogoPromise]).then((values: ContentfulEntry[]) => {
                setHeaderContent({
                    companyLabel: entry.fields.headerCompanyLinkText,
                    whatWeDoLabel: entry.fields.headerWhatWeDoLinkText,
                    partnersLabel: entry.fields.headerPartnersLinkText,
                    contactLabel: entry.fields.headerContactLinkText,
                    faqLabel: entry.fields.headerFaqLinkText,
                    outStoryLabel: entry.fields.headerOurStoryLinkText,
                    leadershipLabel: entry.fields.headerLeadershipLinkText,
                    csrLabel: entry.fields.headerCsrLinkText,
                    careersLabel: entry.fields.headerCareerLinkText,
                    headerLogo: values[0].fields['file'].url,
                });
                setFooterContent({
                    aboutAsurionLabel: entry.fields.footerAboutLinkText,
                    productsLabel: entry.fields.footerProductsLinkText,
                    contactLabel: entry.fields.footerContactText,
                    leadershipLabel: entry.fields.footerLeadershipLinkText,
                    partnersLabel: entry.fields.footerPartnersLinkText,
                    faqLabel: entry.fields.footerFAQinkText,
                    termsOfUseLabel: entry.fields.footerTermsOfUseLabel,
                    privacyPolicyLabel: entry.fields.footerPrivacyPolicyLabel,
                    sitemapLabel: entry.fields.footerSitemapLabel,
                    facebookUrl: entry.fields.footerFacebookLink,
                    linkedInUrl: entry.fields.footerLinkedInLink,
                    youtubeUrl: entry.fields.footerYoutubeLink,
                    copyrightText: entry.fields.footerCopyrightText,
                    footerLogo: values[1].fields['file'].url,
                });
                setDataLoaded(true);
            });
        });

        apacContentfulConfig = { ...apacContentfulConfig, environment: d2cHomePageEnv, spaceId: apacSpaceId, accessToken: apacAccessToken };
        ContentfulLoader.loadEntity(d2cPageEntryId, apacContentfulConfig, 'Entry').then((entry: ContentfulEntry) => {
            const announcementMessage: string = get(entry, 'fields.announcementMessage');
            setAnnouncementMessageContent(JSON.stringify(announcementMessage));
        });
        // }, [contentfulConfig]);
    }, []);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ScrollToTop />
                <CssBaseline />

                {(!dataLoaded && <Spinner />) || (
                    <>
                        <Switch>
                            {/* {isSKT && <Route exact path={'/intro'} component={SKTCareersLandingPage} />} */}
                            {isTaiwan && <Route exact path="/what-we-do/easy-tech" component={Soluto} />}

                            <Route path="*">
                                <Box id="nav-grid">
                                    <NavBar {...headerContent} />
                                    {new Date() <= new Date('December 31, 2024') && announcementMessageContent && <ToastBar toastBarInfo={announcementMessageContent} />}
                                </Box>
                                <Box id="body-grid" style={{ wordBreak: isSKT ? 'keep-all' : 'normal' }}>
                                    {/* {isTaiwan && (
                                        <Route exact path="/insights">
                                            <PageRedirect url={'/'} />
                                        </Route>
                                    )} */}
                                    {/* <Route exact path="/insights" component={InsightsPage} />
                                    <Route exact path="/insights/:slug" component={InsightsArticlePage} /> */}
                                    <Container style={{ maxWidth: '1440px', padding: 0 }}>
                                        <Switch>
                                            <Route exact path="/" component={Home} />
                                            <Route exact path="/company/our-story" component={AboutPage} />
                                            <Route exact path="/company/leadership" component={LeadershipPage} />
                                            <Route exact path="/faqs" component={FaqPage} />
                                            <Route exact path="/company/careers" component={CareersPage} />
                                            <Route exact path="/company/csr" component={CSRPage} />
                                            <Route exact path="/partners" component={PartnersPage} />
                                            <Route exact path="/contact-us" component={ContactPage} />
                                            <Route exact path="/what-we-do" component={WhatWeDoPage} />
                                            {!isTaiwan && (
                                                <Route exact path="/what-we-do/easy-tech">
                                                    <PageRedirect url={'/'} />
                                                </Route>
                                            )}
                                            {!isSKT && (
                                                <Route exact path="/intro">
                                                    <PageRedirect url={'/'} />
                                                </Route>
                                            )}
                                            <Route exact path="/eng/terms-of-use" component={TermsPage} />
                                            <Route exact path="/tha/terms-of-use" component={TermsPageTha} />
                                            <Route exact path="/bah/terms-of-use" component={TermsPageBah} />
                                            {isTaiwan && <Route exact path="/tech-support/terms-of-use/" component={TermsPageTW} />}
                                            {isTaiwan && <Route exact path="/fet/terms-of-use/" component={TermsFET} />}
                                            {isTaiwan && <Route exact path="/tech-support/terms-and-conditions/" component={TermsConditionsPageTW} />}
                                            {isTaiwan && <Route exact path="/tech-support/dataexpress/terms-and-conditions/" component={TermsConditionsDataExpress} />}
                                            {isTaiwan && <Route exact path="/tech-support/standard-version/terms-and-conditions/" component={TermsConditionsSynnex} />}
                                            <Route exact path="/eng/privacy-policy" component={PrivacyPageMain} />
                                            <Route exact path="/cn/privacy-policy" component={PrivacyPageMain} />
                                            <Route exact path="/hk/privacy-policy" component={PrivacyPageMain} />
                                            <Route exact path="/tha/privacy-policy" component={PrivacyPageTha} />
                                            <Route exact path="/bah/privacy-policy" component={PrivacyPageBah} />
                                            <Route exact path="/tech-support/privacy-policy/" component={PrivacyPageTW} />
                                            <Route exact path="/sitemap" component={SiteMapPage} />
                                            <Route exact path="*" component={NoMatch} />
                                        </Switch>
                                    </Container>
                                </Box>
                                <CookieConsent />
                                <Box id="footer-grid">
                                    <Footer {...footerContent} />
                                </Box>
                            </Route>
                        </Switch>
                    </>
                )}
            </Router>
        </ThemeProvider>
    );
}

export default App;
