import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { ContentfulConfig, ContentfulLoader, ContentfulReference, ContentfulEntry, getContentfulElements } from '../../../utils/ContentfulLoader';

import Spinner from '../../utils/Spinner';
import SolutoBanner from './SolutoBanner';
import SolutoDownload from './SolutoDownload';
import SolutoHeader from './SolutoHeader';
import SolutoSections from './SolutoSections';
import SolutoLocation from './SolutoLocation';
import { createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import Footer, { FooterProps } from '../Layout/Footer';

import './Soluto.css';

const pageEntryId: string = process.env.REACT_APP_SOLUTOPAGE_ENTRY_ID;
const footerEntryId: string = process.env.REACT_APP_HEADERFOOTER_ENTRY_ID;

interface IPageSectionItemImage {
    title: string;
    url: string;
}

interface IHomeBannerImage {
    title: string;
    url: string;
}

interface IPageSectionItems {
    title: string;
    content: [];
    image: IPageSectionItemImage;
    icon: IPageSectionItemImage;
}

interface IHomeBannerItems {
    title: string;
    image: IHomeBannerImage;
    mobile: IHomeBannerImage;
}

let fetchFooterContents: any = {};

const Soluto = () => {
    const isMobile = useMediaQuery(`@media (max-width:770px)`);

    let [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());

    let [contentfulConfigFooter] = useState<ContentfulConfig>(ContentfulConfig.getProduction());

    const [solutoTitle, setSolutoTitle] = useState(null);
    const [solutoSections, setSolutoSections] = useState(null);
    const [solutoLocationFields, setSolutoLocationFields] = useState(null);
    const [solutoDownloadUrls, setSolutoDownloadUrls] = useState(null);
    const [solutoHomeBanners, setSolutoHomeBanners] = useState(null);
    const [footerContent, setFooterContent] = useState<FooterProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const classes = useStyles();

    const loadPageContent = useCallback(() => {
        ContentfulLoader.loadEntity(footerEntryId, contentfulConfigFooter, 'Entry').then((entry: ContentfulEntry) => {
            let footerLogoReferences: ContentfulReference[] = entry.getReferences('footerLogo');
            let footerLogoPromise: Promise<ContentfulEntry> = footerLogoReferences[0].getData(contentfulConfigFooter);

            Promise.all([footerLogoPromise]).then((values: ContentfulEntry[]) => {
                fetchFooterContents.aboutAsurionLabel = entry.fields.footerAboutLinkText;
                fetchFooterContents.productsLabel = entry.fields.footerProductsLinkText;
                fetchFooterContents.contactLabel = entry.fields.footerContactText;
                fetchFooterContents.leadershipLabel = entry.fields.footerLeadershipLinkText;
                fetchFooterContents.partnersLabel = entry.fields.footerPartnersLinkText;
                fetchFooterContents.faqLabel = entry.fields.footerFAQinkText;
                fetchFooterContents.termsOfUseLabel = entry.fields.footerTermsOfUseLabel;
                fetchFooterContents.privacyPolicyLabel = entry.fields.footerPrivacyPolicyLabel;
                fetchFooterContents.sitemapLabel = entry.fields.footerSitemapLabel;
                fetchFooterContents.facebookUrl = entry.fields.footerFacebookLink;
                fetchFooterContents.linkedInUrl = entry.fields.footerLinkedInLink;
                fetchFooterContents.youtubeUrl = entry.fields.footerYoutubeLink;
                fetchFooterContents.copyrightText = entry.fields.footerCopyrightText;
                fetchFooterContents.footerLogo = values[0].fields['file'].url;
            });
        });

        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((content: any) => {
                let pageSectionsReferences: ContentfulReference[] = content.getReferences('pageSections');
                let pageSectionsPromise: Promise<ContentfulEntry[]> = getContentfulElements(pageSectionsReferences, contentfulConfig);

                let homeBannersReferences: ContentfulReference[] = content.getReferences('homeBanners');
                let homeBannersPromise: Promise<ContentfulEntry[]> = getContentfulElements(homeBannersReferences, contentfulConfig);

                let locationImageReference: ContentfulReference[] = content.getReferences('locationImage');
                let locationImagePromise: Promise<ContentfulEntry> = locationImageReference[0].getData(contentfulConfig);

                fetchFooterContents.solutoSubLinks = content?.fields?.footerLinks?.sub;

                let pageSections = [];
                let homeBannerItems = [];

                let locationSectionProps = {};

                let downloadUrls = {
                    appStoreUrl: content?.fields.downloadAppStoreUrl,
                    googlePlayUrl: content?.fields.downloadGooglePlayUrl,
                };
                Promise.all([locationImagePromise]).then((locs: ContentfulEntry[]) => {
                    const locFields = content?.fields;
                    const locImage = locs[0]?.fields;
                    locationSectionProps = {
                        title: locFields.locationTitle,
                        imageUrl: locImage.file?.url,
                        imageTitle: locImage.title,
                        buttonText: locFields.locationButtonText,
                        buttonUrl: locFields.locationButtonUrl,
                    };
                });

                Promise.all([pageSectionsPromise, homeBannersPromise])
                    .then((values2: ContentfulEntry[][]) => {
                        let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                        let valueIterations: number = values2[0].length;
                        let bannerIterations: number = values2[1].length;

                        if (bannerIterations < maxIterations) {
                            for (let i = 0; i < valueIterations; i++) {
                                let homeBannerItem: IHomeBannerItems = { title: '', image: { title: '', url: '' }, mobile: { title: '', url: '' } };

                                homeBannerItem.title = values2[1][i].fields.bannerTitle;

                                let imageBannerReference: ContentfulReference[] = values2[1][i].getReferences('bannerImage');
                                let mobileBannerReference: ContentfulReference[] = values2[1][i].getReferences('bannerImageMobile');

                                let imageBannerPromise: Promise<ContentfulEntry> = imageBannerReference[0].getData(contentfulConfig);
                                let mobileBannerPromise: Promise<ContentfulEntry> = mobileBannerReference[0].getData(contentfulConfig);

                                Promise.all([imageBannerPromise, mobileBannerPromise]).then((values3: ContentfulEntry[]) => {
                                    console.log('image', values3);
                                    homeBannerItem.image.title = values3[0]?.fields?.title;
                                    homeBannerItem.image.url = values3[0]?.fields?.file?.url;
                                    homeBannerItem.mobile.title = values3[1]?.fields?.title;
                                    homeBannerItem.mobile.url = values3[1]?.fields?.file?.url;
                                });

                                homeBannerItems.push(homeBannerItem);
                            }
                        }

                        if (valueIterations < maxIterations) {
                            for (let i = 0; i < valueIterations; i++) {
                                let pageSectionItems: IPageSectionItems = { title: '', content: [], image: { title: '', url: '' }, icon: { title: '', url: '' } };

                                pageSectionItems.title = values2[0][i].fields.sectionTitle;
                                pageSectionItems.content = values2[0][i].fields.sectionItems;

                                let sectionImageReference: ContentfulReference[] = values2[0][i].getReferences('sectionImage');
                                let sectionIconReference: ContentfulReference[] = values2[0][i].getReferences('sectionIcon');

                                let sectionImagePromise: Promise<ContentfulEntry> = sectionImageReference[0].getData(contentfulConfig);
                                let sectionIconPromise: Promise<ContentfulEntry> = sectionIconReference[0].getData(contentfulConfig);

                                Promise.all([sectionImagePromise, sectionIconPromise]).then((values3: ContentfulEntry[]) => {
                                    pageSectionItems.image.title = values3[0]?.fields?.title;
                                    pageSectionItems.image.url = values3[0]?.fields?.file?.url;
                                    pageSectionItems.icon.title = values3[1]?.fields?.title;
                                    pageSectionItems.icon.url = values3[1]?.fields?.file?.url;
                                });

                                pageSections.push(pageSectionItems);
                            }
                        }
                    })
                    .finally(() => {
                        setSolutoLocationFields(locationSectionProps);
                        setSolutoSections(pageSections);
                        setSolutoTitle(content?.fields?.pageTitle);
                        setSolutoDownloadUrls(downloadUrls);
                        setFooterContent(fetchFooterContents);
                        setSolutoHomeBanners(homeBannerItems);
                        // we need to put a force delay for the image to be loaded properly
                        setTimeout(() => {
                            setDataLoaded(true);
                        }, 500);
                    });
            })
            .catch((error: any) => {
                console.log('error', error);
                setDataLoaded(true);
            });
    }, [contentfulConfig, contentfulConfigFooter]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <Fragment>
            {(!dataLoaded && <Spinner />) || (
                <Fragment>
                    <header className={classes.header}>
                        <SolutoHeader />
                    </header>
                    <main>
                        <SolutoBanner banners={solutoHomeBanners} isMobile={isMobile} />
                        <SolutoSections title={solutoTitle || null} items={solutoSections || null} isMobile={isMobile} />
                        <SolutoLocation fields={solutoLocationFields} />
                        <SolutoDownload appStoreUrl={solutoDownloadUrls.appStoreUrl} googlePlayUrl={solutoDownloadUrls.googlePlayUrl} />
                    </main>
                    <footer>
                        <Footer {...footerContent} />
                    </footer>
                </Fragment>
            )}
        </Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: '20px 0',
            position: 'relative',

            [theme.breakpoints.down('xs')]: {
                padding: `15px 0`,
            },

            '&::after': {
                height: '8px',
                content: '""',
                width: '100%',
                position: 'absolute',
                background: 'linear-gradient(270deg,#8223d2 0%,#6469e1 25%,#6efac3 50%,#6469e1 75%,#8223d2 100%)',
                backgroundSize: '500vw 500vw',
                animation: 'headerGradient 6s infinite linear forwards',

                left: 0,
                bottom: 0,

                [theme.breakpoints.down('xs')]: {
                    height: '4px',
                },
            },
        },
    })
);

export default Soluto;
