import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import imgLogo from '../../../images/soluto/logo.svg';

const SolutoHeader = () => {
    const classes = useStyles();

    return (
        <Box component="div" className={classes.container}>
            <a href="/" className={classes.linkLogo}>
                <img src={imgLogo} alt="easytech" className={classes.imgLogo} />
            </a>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '1175px',
            margin: '0 auto',

            [theme.breakpoints.down('lg')]: {
                maxWidth: '900px',
            },

            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
                padding: '0 10px',
            },
        },
        linkLogo: {
            textDecoration: 'none',
            backgroundColor: 'transparent',
        },
        imgLogo: {
            width: '293px',

            [theme.breakpoints.down('md')]: {
                width: '200px',
            },
        },
    })
);

export default SolutoHeader;
