import React, { useCallback, useEffect, useState } from 'react';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { footerDescriptionText, FooterBannerProps } from '../../common/FooterBanner';
import TermsContent, { TermsContentProps } from './TermsConditionsContentTW';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';

import BannerImage from '../../../images/termsofuse/Banner.png';
import BannerImageSmall from '../../../images/termsofuse/Banner-small.png';
import FooterPerson from '../../../images/common/careersfooterperson.png';

const pageEntryId: string = process.env.REACT_APP_TERMS_AND_PRIVACY_ENTRY_ID;

function TermsPage() {
    const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [heroMainDetails, setHeroMainDetails] = useState<HeroMainProps>();
    const [termsContent, setTermsContent] = useState<TermsContentProps>();
    const [footerDetails, setFooterDetails] = useState<FooterBannerProps>();

    const loadPageContent = useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let footerBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'termsFooterBannerPersonImage');
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                setHeroMainDetails({ title: entry.fields.termsOfUseTitle, backgroundImage: BannerImage, backgroundImageSmall: BannerImageSmall });

                setTermsContent({
                    title: entry.fields.techSupportTermsAndConditionsTitleTWOnly,
                    dateUpdated: entry.fields.techSupportTermsAndConditionsEffectiveDateTWOnly,
                    content: entry.fields.techsupportTermsAndConditionsContentTWOnly,
                });
                Promise.all([footerBannerImagePromise]).then((values: ContentfulEntry[]) => {
                    setFooterDetails({
                        bannerTitle: entry.fields.termsFooterBannerTitle,
                        bannerDescription: entry.fields.termsFooterBannerDescription,
                        bannerImage: values[0].fields['file'].url,
                        buttonText: entry.fields.termsFooterBannerButtonText,
                        buttonLink: entry.fields.termsFooterBannerButtonLink,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {})
            .finally(() => {
                console.log(footerDetails);
            });
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...heroMainDetails} />
                    <TermsContent {...termsContent} />
                    <FooterBanner {...footerDetails} />
                </div>
            )}
        </div>
    );
}

export default TermsPage;
