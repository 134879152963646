import * as React from 'react';
import { Box, Button, makeStyles, Theme, Typography, useMediaQuery, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ProductDetailed } from './types';
import { ProductDetail } from './ProductDetail';
import { sv, toPercentage } from '../../../common/functions';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

import { ReactComponent as CircledArrowIcon } from '../../../images/common/circledarrow.svg';

export interface SummaryProps {
    title: string;
    description: ContentfulDocument;
    buttonText: string;
    buttonLink: string;
    productDetails: Array<ProductDetailed>;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: sv(50, 0, 0, 110),
        [theme.breakpoints.down('sm')]: {
            padding: sv(48, 0),
            flexDirection: 'column',
            gap: 21,
        },
        marginBottom: theme.spacing(2),
    },
    descriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 22,
        flexBasis: toPercentage(587, 1000),

        [theme.breakpoints.down('sm')]: {
            padding: '0 32px',
        },
    },
    description: {
        marginBottom: '2em',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 50,
        },
        fontSize: '1.5rem',
        '&>p': {
            margin: 0,
        },
        '&>.bold': {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
    },
    showcaseContainer: {
        width: '100%',
        display: 'flex',
        // overflow: 'hidden',
        padding: sv(0, 100, 40),
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            // overflow: 'unset',
        },
    },
    link: {
        textDecoration: 'none',

        [theme.breakpoints.down('sm')]: {
            margin: '0 16px',
        },
    },
    navigationBox: {
        position: 'relative',
        paddingLeft: theme.spacing(12),
    },
    previousButton: {
        marginRight: theme.spacing(1),
        '&:hover': {
            fill: theme.palette.primary.main,
            color: 'white',
            '&>g>path': {
                fill: 'white',
            },
        },
    },
    nextButton: {
        transform: 'scaleX(-1)',
        '&:hover': {
            fill: theme.palette.primary.main,
            color: 'white',
            '&>g>path': {
                fill: 'white',
            },
        },
    },
    button: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    paginationMod: {
        [theme.breakpoints.up('md')]: {
            bottom: '20px !important',
            right: '100px !important',
            width: 'auto !important',
            left: 'auto !important',
            margin: 0,
            display: 'none',
        },
    },
    paginationBullet: {
        padding: '0px 8px',
        borderRadius: '0 !important',
        background: '#D5D6DA !important',
        margin: '0px 10px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            padding: '0px 5px',
        },
    },
    paginationBulletActive: {
        backgroundColor: '#8223D2 !important',
        width: '1rem',
        height: '1rem',
        borderRadius: '0.5rem',
    },
    swiperContainer: {
        width: '100%',
    },
    swiper: {
        height: '100%',
    },
    slideContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '10em',
        },
    },
    navigation: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    textBold: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
}));

export const Summary = (props: SummaryProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    SwiperCore.use([Navigation, Pagination, Autoplay]);

    const Bold = ({ children }: any) => <span className={classes.textBold}>{children}</span>;

    const Text = ({ children }: any) => <p className="">{children}</p>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Grid md={4} className={classes.descriptionWrapper}>
                    <Typography variant="h3">{parse(`${props.title}`)}</Typography>
                    <Typography variant="subtitle1" className={classes.description}>
                        {documentToReactComponents(props.description, options)}
                    </Typography>
                    <Link to={props.buttonLink} className={classes.link}>
                        <Button variant="contained" color="primary" className={classes.button}>
                            {props.buttonText}
                        </Button>
                    </Link>
                </Grid>
                <Grid container direction="row" md={8}>
                    <Grid container item md={12}>
                        <Swiper
                            className={classes.showcaseContainer}
                            autoplay
                            slidesPerView={isMobile ? 1 : 2}
                            pagination={{
                                clickable: true,
                                bulletClass: `` + classes.paginationBullet + ``,
                                bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                clickableClass: `` + classes.paginationMod + ``,
                            }}
                            spaceBetween={50}
                            navigation={{
                                nextEl: '.swiper-button-next-unique',
                                prevEl: '.swiper-button-prev-unique',
                            }}
                            observeParents={true}
                            observer={true}
                        >
                            {props.productDetails.map((detail, i) => {
                                return (
                                    <SwiperSlide className={classes.slideContainer}>
                                        <ProductDetail title={detail.title} details={detail.details} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Grid>
                    <Grid item md={12} className={classes.navigation}>
                        <Grid container direction="row" className={classes.navigationBox}>
                            <Grid className="swiper-button-prev-unique">
                                <CircledArrowIcon className={classes.previousButton} />
                            </Grid>
                            <Grid className="swiper-button-next-unique">
                                <CircledArrowIcon className={classes.nextButton} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default Summary;
