import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: '7vw',
            paddingRight: '7vw',
            height: '52.3em',
        },
        list: {
            '&>li': {
                listStyle: 'none',
            },
            '&>li>p>a': {
                textDecoration: 'none',
                color: 'primary',
            },
        },
    })
);

function SiteMap() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <h2>Asurion Site Map</h2>
            <ul>
                <h3>Company</h3>
                <ul className={classes.list}>
                    <li>
                        <Typography>
                            <Link to="/company/our-story">Our Story</Link>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <Link to="/company/leadership">Leadership</Link>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <Link to="/company/csr">CSR</Link>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <Link to="/company/careers">Career </Link>
                        </Typography>
                    </li>
                </ul>
            </ul>
            <ul>
                <h3>Products</h3>
                <ul className={classes.list}>
                    <li>
                        <Typography>
                            <Link to="/what-we-do">What We Do</Link>
                        </Typography>
                    </li>
                </ul>
            </ul>
            <ul>
                <h3>Partners</h3>
                <ul className={classes.list}>
                    <li>
                        <Typography>
                            <Link to="/partners">Partners</Link>
                        </Typography>
                    </li>
                </ul>
            </ul>
            <ul>
                <h3>Support</h3>
                <ul className={classes.list}>
                    <li>
                        <Typography>
                            <Link to="/contact-us">Contact Us</Link>
                        </Typography>
                    </li>
                </ul>
            </ul>
            <ul>
                <h3>Privacy</h3>
                <ul className={classes.list}>
                    <li>
                        <Typography>
                            <Link
                                to={
                                    (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && '/tha/privacy-policy') ||
                                    (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'MY' && '/bah/privacy-policy') ||
                                    (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'CN' && '/cn/privacy-policy') ||
                                    (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TW' && '/tech-support/privacy-policy/') ||
                                    (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'HK' && '/hk/privacy-policy') ||
                                    '/eng/privacy-policy'
                                }
                            >
                                Privacy Policy
                            </Link>
                        </Typography>
                    </li>
                </ul>
            </ul>
        </div>
    );
}

export default SiteMap;
