import * as React from 'react';
import { Hidden } from '@material-ui/core';
import { useEffect, useState } from 'react';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import Lead, { LeadProps } from './Lead';
import ProductStrip from './ProductStrip';
import Summary, { SummaryProps } from './Summary';
import { ServiceList } from './Services/ServiceList';
import { ServiceListAccordion } from './Services/ServiceListAccordion';
import { ProductItem } from './types';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, getContentfulImages } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { ServiceItem } from './Services/types';
import { Helmet } from 'react-helmet';

//DEV NOTES: WE ARE USING TH SPACE FOR WHAT WE DO CONTENT BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageEntryId: string = process.env.REACT_APP_WHATWEDO_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

export const WhatWeDoPage = () => {
    // const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [lead, setLead] = useState<LeadProps | null>(null);
    const [productStrip, setProductStrip] = useState<Array<ProductItem>>([]);
    const [summary, setSummary] = useState<SummaryProps | null>(null);
    const [services, setServices] = useState<Array<ServiceItem>>([]);
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    var contentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();

    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let bannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'footerBannerPersonImage');
                let productTypesIconsReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'productTypesIcons');
                let servicesShowcaseIconsReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'servicesShowcaseIcons');
                let servicesShowcaseImagesReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'servicesShowcaseImages');

                let bannerImagePromise: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let bannerImageSmallPromise: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                let productTypesIconsPromise: Promise<ContentfulEntry[]> = getContentfulImages(productTypesIconsReferences, contentfulConfig);
                let servicesShowcaseIconsPromise: Promise<ContentfulEntry[]> = getContentfulImages(servicesShowcaseIconsReferences, contentfulConfig);
                let servicesShowcaseImagesPromise: Promise<ContentfulEntry[]> = getContentfulImages(servicesShowcaseImagesReferences, contentfulConfig);

                Promise.all([bannerImagePromise, bannerImageSmallPromise, footerBannerImagePromise]).then((values: ContentfulEntry[]) => {
                    setHero({ title: entry.fields.bannerText, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[2].fields['file'].url,
                    });
                    setSummary({
                        title: entry.fields.productsTitle,
                        description: entry.fields.productsDescription,
                        productDetails: entry.fields.productDetails,
                        buttonText: entry.fields.productsButtonText,
                        buttonLink: entry.fields.productsButtonLink,
                    });
                    setLead({
                        title: entry.fields.subBannerTitle,
                        content: entry.fields.subBannerDescription,
                    });
                });

                Promise.all([productTypesIconsPromise, servicesShowcaseIconsPromise, servicesShowcaseImagesPromise]).then((values: ContentfulEntry[][]) => {
                    //setting icons for each product type
                    for (let i = 0; i < entry.fields.productTypes.length; i++) {
                        entry.fields.productTypes[i]['icon'] = values[0][i] ? values[0][i] : '';
                    }

                    //setting icons for each service
                    for (let i = 0; i < entry.fields.services.length; i++) {
                        entry.fields.services[i]['icon'] = values[0][i] ? values[0][i] : '';
                    }
                    //setting icons for showcase of first service
                    for (let i = 0; i < entry.fields.services[0]['showcase'].length; i++) {
                        entry.fields.services[0]['showcase'][i]['icon'] = values[1][i] ? values[1][i] : '';
                    }

                    // setting images of showcase
                    entry.fields.services[1]['showcase'] = values[2][0];
                    entry.fields.services[2]['showcase'] = values[2][1];
                    entry.fields.services[3]['showcase'] = values[2][2];

                    setServices(entry.fields.services);
                    setProductStrip(entry.fields.productTypes);
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, []);

    return (
        <>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain title={hero?.title} backgroundImage={hero?.backgroundImage} backgroundImageSmall={hero?.backgroundImageSmall} />
                    {lead && <Lead title={lead.title} content={lead.content} />}
                    <Hidden xsDown>
                        <ProductStrip products={productStrip} />
                    </Hidden>
                    {summary && (
                        <Summary title={summary.title} description={summary.description} productDetails={summary.productDetails} buttonText={summary.buttonText} buttonLink={summary.buttonLink} />
                    )}
                    <Hidden xsDown>{services && <ServiceList services={services} />}</Hidden>
                    <Hidden smUp>{services && <ServiceListAccordion services={services} />}</Hidden>
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </>
    );
};

export default WhatWeDoPage;
