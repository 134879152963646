import { makeStyles } from '@material-ui/core/styles';
// import { BASE_WIDTH } from '../../../common/constants';

export const NavBarStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: '1400px',
    },
    containerCompress: {
        maxWidth: '1400px',
        // paddingLeft: '7vw',
        // paddingRight: '7vw',
        // [theme.breakpoints.up('md')]: {
        //     paddingLeft: '7vw',
        //     paddingRight: '7vw',
        // },
    },
    menuButton: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    logoBox: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    logoLink: {
        display: 'flex',
        alignItems: 'center',
    },
    linkBox: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    link: {
        flexGrow: 0.1,
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
    },
    navLink: {
        textDecoration: 'none',
        color: 'white',
        fontSize: theme.spacing(2),
    },
    subMenu: {
        background: '#333F48',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        position: 'absolute',
        width: '100%',
    },
    subMenuLink: {
        fontSize: theme.spacing(1.5),
    },
    subMenuActiveLink: {
        color: '#37E7A7 !important',
        fontWeight: 'bold',
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#4C0087',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogBox: {
        background: '#333F48',
        position: 'absolute',
        height: '100%',
        width: '100%',
        padding: theme.spacing(5),
    },
    dialogLink: {
        fontSize: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    nestedLink: {
        paddingLeft: theme.spacing(4),
    },
}));
