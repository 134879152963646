import React, { useEffect, useState } from 'react';

import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import CSRTeam, { CSRTeamProps } from './CSRTeam';
import CSRInfo, { InfoDetails, CSRInfoProps } from './CSRInfo';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, getContentfulImages } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';

//DEV NOTES: WE ARE USING TH SPACE FOR CSR CONTENT BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageEntryId: string = process.env.REACT_APP_CSR_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

function CareersPage() {
    // const [contentfulConfig, setcontentFulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [teamDetails, setTeamDetails] = useState<CSRTeamProps>();
    const [infoDetails, setInfoDetails] = useState<CSRInfoProps>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    var contentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();

    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let bannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = entry.getReferences('footerBannerPersonImage');
                let impactTypesImagesReferences: ContentfulReference[] = entry.getReferences('impactTypesImages');
                let infoDetailsImage1References: ContentfulReference[] = entry.getReferences('infoDetailsImage1');
                let infoDetailsImageSmall1References: ContentfulReference[] = entry.getReferences('infoDetailsImageSmall1');
                let infoDetailsImage2References: ContentfulReference[] = entry.getReferences('infoDetailsImage2');
                let infoDetailsImageSmall2References: ContentfulReference[] = entry.getReferences('infoDetailsImageSmall2');
                let infoDetailsImage3References: ContentfulReference[] = entry.getReferences('infoDetailsImage3');
                let infoDetailsImageSmall3References: ContentfulReference[] = entry.getReferences('infoDetailsImageSmall3');

                let bannerImagePromise: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let bannerImageSmallPromise: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                let testimonialsPromise: Promise<ContentfulEntry[]> = getContentfulImages(impactTypesImagesReferences, contentfulConfig);
                let infoDetailsImage1Promise: Promise<ContentfulEntry> = infoDetailsImage1References[0].getData(contentfulConfig);
                let infoDetailsImageSmall1Promise: Promise<ContentfulEntry> = infoDetailsImageSmall1References[0].getData(contentfulConfig);
                let infoDetailsImage2Promise: Promise<ContentfulEntry> = infoDetailsImage2References[0].getData(contentfulConfig);
                let infoDetailsImageSmall2Promise: Promise<ContentfulEntry> = infoDetailsImageSmall2References[0].getData(contentfulConfig);
                let infoDetailsImage3Promise: Promise<ContentfulEntry> = infoDetailsImage3References[0].getData(contentfulConfig);
                let infoDetailsImageSmall3Promise: Promise<ContentfulEntry> = infoDetailsImageSmall3References[0].getData(contentfulConfig);

                Promise.all([
                    bannerImagePromise,
                    bannerImageSmallPromise,
                    footerBannerImagePromise,
                    infoDetailsImage1Promise,
                    infoDetailsImageSmall1Promise,
                    infoDetailsImage2Promise,
                    infoDetailsImageSmall2Promise,
                    infoDetailsImage3Promise,
                    infoDetailsImageSmall3Promise,
                ]).then((values: ContentfulEntry[]) => {
                    setHero({ title: entry.fields.bannerText, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[2].fields['file'].url,
                    });
                    let info: InfoDetails[] = [
                        {
                            image: values[3].fields['file'].url,
                            imageSmall: values[4].fields['file'].url,
                            title: entry.fields.infoDetailsTitle1,
                            description: entry.fields.infoDetailsDescription1,
                        },
                        {
                            image: values[5].fields['file'].url,
                            imageSmall: values[6].fields['file'].url,
                            title: entry.fields.infoDetailsTitle2,
                            description: entry.fields.infoDetailsDescription2,
                        },
                        {
                            image: values[7].fields['file'].url,
                            imageSmall: values[8].fields['file'].url,
                            title: entry.fields.infoDetailsTitle3,
                            description: entry.fields.infoDetailsDescription3,
                        },
                    ];

                    setInfoDetails({ infoDetails: info });
                });

                Promise.all([testimonialsPromise]).then((values: ContentfulEntry[][]) => {
                    for (let i = 0; i < entry.fields.impactTypes.length; i++) {
                        entry.fields.impactTypes[i]['image'] = values[0][i] ? values[0][i] : '';
                    }
                    setTeamDetails({
                        title: entry.fields.infoTitle,
                        description: entry.fields.infoDescription,
                        cardDetails: entry.fields.impactTypes,
                    });

                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
        // }, [contentfulConfig]);
    }, []);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...hero} />
                    <CSRTeam {...teamDetails} />
                    <CSRInfo {...infoDetails} />
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </div>
    );
}

export default CareersPage;
