import React, { Fragment } from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import Rating from '@material-ui/lab/Rating';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface Ratings {
    rater: string;
    rating: number;
}

export interface HeroReviewsProps {
    title: string;
    ratings: Ratings[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#F0F0F5',
            [`${theme.breakpoints.up('md')} and (orientation:portrait)`]: {
                height: '70px',
            },
            [theme.breakpoints.down('sm')]: {
                // height: '200px',
            },

            [`${theme.breakpoints.only('md')} and (orientation:landscape)`]: {},
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(3),
            paddingLeft: '7vw;',
            paddingRight: '7vw',
        },
        sectionBox: {
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                flexFlow: 'row',
            },
            [`${theme.breakpoints.only('md')} and (orientation:landscape)`]: {
                flexFlow: 'column',
            },
            [theme.breakpoints.down('sm')]: {
                flexFlow: 'column',
            },
        },
        titleBox: {
            color: '#333F48',
            fontSize: '0.875rem',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'flex-end',
            },
            [theme.breakpoints.down('sm')]: {
                height: '50%',
                display: 'flex',
                flexFlow: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
                textAlign: 'center',
            },
            [`${theme.breakpoints.only('md')} and (orientation:landscape)`]: {
                height: '50%',
                display: 'flex',
                flexFlow: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
                textAlign: 'center',
            },
        },
        ratingBox: {
            justifyContent: 'space-evenly',
            fontSize: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                height: '50%',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            },
            [`${theme.breakpoints.only('md')} and (orientation:landscape)`]: {
                // height: '50%',
                // display: 'flex',
                // alignContent: 'center',
                // justifyContent: 'center',
                // marginBottom: theme.spacing(2),
            },
        },
        reviewItem: {
            display: 'flex',
            fontSize: theme.spacing(3),
        },
        swiper: {
            // height: '100%',
            width: '100%',
        },
        slideContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(5),
        },
        paginationMod: {
            [`${theme.breakpoints.up('md')} and (orientation:portrait)`]: {
                bottom: '20px !important',
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: '#D5D6DA !important',
            margin: '0px 10px !important',

            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#8223D2 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
    })
);

const useRatingStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
    },
}));

function HeroReviews(reviewDetails: HeroReviewsProps) {
    const classes = useStyles();
    const ratingClasses = useRatingStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const landscape = useMediaQuery(theme.breakpoints.only('md')) && window.matchMedia('(orientation: landscape)').matches === true;

    const renderStatistics = (reviewDetails: HeroReviewsProps): JSX.Element => {
        let jsx: JSX.Element;
        jsx = (
            <Grid container direction="row" className={classes.sectionBox}>
                {isMobile || landscape ? (
                    <Fragment>
                        <Grid item xs={12} className={classes.titleBox}>
                            <Box>{reviewDetails.title}</Box>
                        </Grid>
                        <Swiper
                            className={clsx(classes.swiper)}
                            autoplay
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                                bulletClass: `` + classes.paginationBullet + ``,
                                bulletActiveClass: `` + classes.paginationBulletActive + ``,
                                clickableClass: `` + classes.paginationMod + ``,
                            }}
                        >
                            <Grid item xs={12} container direction="row" className={classes.ratingBox}>
                                {reviewDetails.ratings.map((rating, i) => (
                                    <SwiperSlide className={classes.slideContainer} key={i}>
                                        <Grid className={classes.reviewItem}>
                                            <Box>{rating.rater}</Box>
                                            <Box>
                                                <Rating name="read-only" value={rating.rating} precision={0.1} readOnly classes={{ root: ratingClasses.root }} />
                                            </Box>
                                        </Grid>
                                    </SwiperSlide>
                                ))}
                            </Grid>
                        </Swiper>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Grid item md={4} sm={12} className={classes.titleBox}>
                            <Box>{reviewDetails.title}</Box>
                        </Grid>
                        <Grid item md={8} sm={12} container direction="row" className={classes.ratingBox}>
                            {reviewDetails.ratings.map((rating, i) => (
                                <Grid className={classes.reviewItem} key={i}>
                                    <Box>{rating.rater}</Box>
                                    <Box>
                                        <Rating name="read-only" value={rating.rating} precision={0.1} readOnly classes={{ root: ratingClasses.root }} />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
        return jsx;
    };

    return <div className={clsx(classes.root, classes.containerCompress)}>{renderStatistics(reviewDetails)}</div>;
}

export default HeroReviews;
