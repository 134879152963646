import React, { Fragment, useState } from 'react';
import { Grid, Box, Card, CardContent, Collapse, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface Core {
    icon: string;
    title: string;
    points: string[];
}

export interface PartnersCoreProps {
    title: string;
    description: string;
    coreDetails: Core[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '50em',
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(5),
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            // paddingTop: theme.spacing(5),
            // paddingBottom: theme.spacing(5),

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2),
                paddingLeft: '7vw',
                paddingRight: '7vw',
            },
        },
        centerContents: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        spaceBelow: {
            marginBottom: theme.spacing(10),
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        textBox: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: theme.spacing(10),
            marginTop: theme.spacing(5),
        },
        textTitle: {
            fontSize: '2.125rem',
            fontWeight: 'bold',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        textDescription: {
            fontSize: '1.5rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                paddingLeft: '7vw',
                paddingRight: '7vw',
            },
        },
        cardBox: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
        },
        card: {
            minHeight: '15em',
            width: '15em',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            borderRadius: 0,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        cardContentBox: {
            height: '15em',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(1.2),
            paddingRight: theme.spacing(1.2),
        },
        cardIconBox: {},
        cardIcon: {},
        cardTitleBox: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        cardTitle: {
            fontSize: '1.25rem',
            fontWeight: 'bold',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                fontWeight: 'normal',
                color: '#333F48',
                paddingLeft: theme.spacing(1),
            },
        },
        divider: {
            height: theme.spacing(1),
            background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)',
        },
        cardDescriptionBox: {
            background: '#F0F0F5',
            minHeight: '15em',
            '&.top': {
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end',
            },
            '&.bottom': {
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
            },
        },
        cardDescription: {
            fontSize: '1rem',
            textAlign: 'left',
            '&.bottom': { marginBottom: theme.spacing(2) },
            '&.top': { marginTop: theme.spacing(2) },
            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
            },
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        list: {
            paddingLeft: theme.spacing(2),
        },
        imageBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        accordion: {
            margin: 'auto',
            boxShadow: 'none',
            background: 'transparent',
        },
        accordionHeader: {
            margin: theme.spacing(2),
        },
        accordionIcon: {
            maxHeight: '40px',
        },
        accordionDetails: {
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        expandIcon: {
            fill: theme.palette.primary.main,
        },
        accordionSummary: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                background: '#F0F0F5',

                '&.even': {
                    background: '#E6E6EB',
                },
            },
        },
    })
);

function PartnersCore(coreDetails: PartnersCoreProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState<number>();

    const handleExpand = selected => {
        if (selected !== expanded) {
            setExpanded(selected);
            return;
        }
        setExpanded(null);
    };

    const renderSection = (coreDetails: PartnersCoreProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Grid className={clsx(classes.h100, classes.containerCompress)}>
                <Grid container direction="column" className={clsx(classes.textBox, classes.spaceBelow)}>
                    <Box component="h1" className={classes.textTitle}>
                        {coreDetails.title}
                    </Box>
                    <Box className={clsx(classes.textDescription)}>{coreDetails.description}</Box>
                </Grid>
                {isMobile ? (
                    <Grid container direction="row">
                        {coreDetails.coreDetails.map((core, i) => (
                            <Fragment key={i}>
                                {/* <Grid item xs={2} className={classes.imageBox}></Grid> */}
                                <Grid item xs={12}>
                                    <Accordion key={i} className={classes.accordion} onChange={() => handleExpand(i)} expanded={expanded === i}>
                                        <AccordionSummary
                                            expandIcon={expanded === i ? <RemoveCircleOutlineIcon className={classes.expandIcon} /> : <AddCircleOutlineIcon className={classes.expandIcon} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className={clsx(classes.centerContents, classes.accordionSummary, i % 2 === 1 && 'even')}
                                        >
                                            <Grid container direction="row">
                                                <Grid item xs={2} justifyContent="center" alignItems="center" className={classes.imageBox}>
                                                    <img src={core.icon} alt={core.title} className={classes.accordionIcon} />
                                                </Grid>
                                                <Grid item xs={10} justifyContent="flex-start" alignItems="center" className={classes.cardTitleBox}>
                                                    <Typography className={classes.cardTitle}>{core.title}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Box></Box>
                                        </AccordionSummary>
                                        <AccordionDetails className={clsx(classes.accordionDetails, classes.containerCompress)}>
                                            <ul className={classes.list}>
                                                {core.points.map((point, i) => (
                                                    <Fragment key={i}>
                                                        <Box className={clsx(classes.cardDescription, 'bottom')}>
                                                            <li>{point}</li>
                                                        </Box>
                                                    </Fragment>
                                                ))}
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid container direction="row" className={clsx(classes.cardBox, classes.spaceBelow)}>
                            {coreDetails.coreDetails.map((core, i) => (
                                <Fragment key={i}>
                                    {i < 4 && (
                                        <Card className={classes.card} onClick={() => handleExpand(i)} aria-label="show more">
                                            <Grid container direction="column">
                                                <Grid item md={12}>
                                                    <CardContent className={classes.cardContentBox}>
                                                        <Box className={classes.cardIconBox}>
                                                            <img src={core.icon} alt={core.title} className={classes.cardIcon} />
                                                        </Box>
                                                        <Box className={classes.cardTitleBox}>
                                                            <span className={classes.cardTitle}>{core.title}</span>
                                                        </Box>
                                                    </CardContent>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                                                        <Box className={classes.divider}></Box>
                                                        <CardContent className={clsx(classes.cardDescriptionBox, 'bottom')}>
                                                            <ul className={classes.list}>
                                                                {core.points.map((point, i) => (
                                                                    <Fragment key={i}>
                                                                        <Box className={clsx(classes.cardDescription, 'bottom')}>
                                                                            <li>{point}</li>
                                                                        </Box>
                                                                    </Fragment>
                                                                ))}
                                                            </ul>
                                                        </CardContent>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                        <Grid container direction="row" className={classes.cardBox}>
                            {coreDetails.coreDetails.map((core, i) => (
                                <Fragment key={i}>
                                    {i > 3 && (
                                        <Card className={classes.card} onClick={() => handleExpand(i)}>
                                            <Grid container direction="column">
                                                <Grid item md={12}>
                                                    <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                                                        <CardContent className={clsx(classes.cardDescriptionBox, 'top')}>
                                                            <ul className={classes.list}>
                                                                {core.points.map((point, i) => (
                                                                    <Fragment key={i}>
                                                                        <Box className={clsx(classes.cardDescription, 'top')}>
                                                                            <li>{point}</li>
                                                                        </Box>
                                                                    </Fragment>
                                                                ))}
                                                            </ul>
                                                        </CardContent>
                                                        <Box className={classes.divider}></Box>
                                                    </Collapse>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <CardContent className={classes.cardContentBox}>
                                                        <Box className={classes.cardIconBox}>
                                                            <img src={core.icon} alt={core.title} className={classes.cardIcon} />
                                                        </Box>
                                                        <Box className={classes.cardTitleBox}>
                                                            <span className={classes.cardTitle}>{core.title}</span>
                                                        </Box>
                                                    </CardContent>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(coreDetails)}</div>;
}

export default PartnersCore;
