import React, { Fragment, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LeadershipBoard, { LeaderProfile, LeaderBoardProps } from './LeadershipBoard';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import { ContentfulConfig, ContentfulIncludes, ContentfulEntry, getImageFromIncludes, getContentfulElements, ContentfulLoaderContentTypeId } from '../../../utils/ContentfulLoader';
import { Helmet } from 'react-helmet';
import Spinner from '../../utils/Spinner';

//DEV NOTES: WE ARE USING TH SPACE FOR LEADERSHIP CONTENT BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageContentTypeId: string = process.env.REACT_APP_LEADERSHIP_CONTENT_TYPE_ID;
const pageEntryId: string = process.env.REACT_APP_LEADERSHIP_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

export const sortByIndex = (data: any[]) => {
    let sortedData = data.sort(function (a, b) {
        return a.index - b.index;
    });

    return sortedData;
};

function LeadershipPage() {
    const classes = LeadershipPageStyles();
    // const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [leaderBoardContent, setLeaderBoardContent] = useState<LeaderBoardProps | null>(null);
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    var contentfulConfig: ContentfulConfig = ContentfulConfig.getProduction();

    var regionalLeadersClean: LeaderProfile[] = [];
    var countryLeadersClean: LeaderProfile[] = [];

    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoaderContentTypeId.loadEntity(pageContentTypeId, contentfulConfig)
            .then((entry: any) => {
                console.log(entry);
                let includes: ContentfulIncludes = entry.includes;
                let bannerImage: ContentfulEntry = ContentfulLoaderContentTypeId.getIncludeDataSingle(entry, 'bannerImage');
                let bannerImageSmall: ContentfulEntry = ContentfulLoaderContentTypeId.getIncludeDataSingle(entry, 'bannerImageSmall');
                let regionalLeaders: ContentfulEntry[] = ContentfulLoaderContentTypeId.getIncludeData(entry, 'regionalLeaders');
                let countryLeaders: ContentfulEntry[] = ContentfulLoaderContentTypeId.getIncludeData(entry, 'countryLeaders');
                let footerBannerPersonImage: ContentfulEntry = ContentfulLoaderContentTypeId.getIncludeDataSingle(entry, 'footerBannerPersonImage');

                regionalLeaders = ContentfulLoaderContentTypeId.getRecursiveReferenceData(regionalLeaders, includes, 'image');
                countryLeaders = ContentfulLoaderContentTypeId.getRecursiveReferenceData(countryLeaders, includes, 'image');

                setHero({ title: entry.fields.bannerText, backgroundImage: bannerImage.fields['file'].url, backgroundImageSmall: bannerImageSmall.fields['file'].url });
                setFooterBannerContent({
                    bannerTitle: entry.fields.footerBannerTitle,
                    bannerDescription: entry.fields.footerBannerDescription,
                    buttonLink: entry.fields.footerBannerButtonLink,
                    buttonText: entry.fields.footerBannerButtonText,
                    bannerImage: footerBannerPersonImage.fields['file'].url,
                });

                for (let i = 0; i < regionalLeaders.length; i++) {
                    //Need to exclude Arran Broadbent from AU corp site. NO OTHER OPTION DUE TO LOCALE RESTRICTIONS BETWEEN TWO CONTENTFUL SPACES
                    // if (process.env.REACT_APP_COUNTRY_CODE === 'AU' && regionalLeaders[i].fields['name'] === 'Arran Broadbent') {
                    // } else {
                    regionalLeadersClean.push({
                        name: regionalLeaders[i].fields['name'],
                        position: regionalLeaders[i].fields['position'],
                        image: regionalLeaders[i].fields['image'],
                        statement: regionalLeaders[i].fields['statement'],
                        index: i,
                    });
                    // }
                }

                for (let i = 0; i < countryLeaders.length; i++) {
                    countryLeadersClean.push({
                        name: countryLeaders[i].fields['name'],
                        position: countryLeaders[i].fields['position'],
                        image: countryLeaders[i].fields['image'],
                        statement: countryLeaders[i].fields['statement'],
                        index: i,
                    });
                }

                setLeaderBoardContent({
                    title: entry.fields.title,
                    regionalLabel: entry.fields.regionalLabel,
                    countryLabel: entry.fields.countryLabel,
                    countryLeaders: countryLeadersClean,
                    regionalLeaders: regionalLeadersClean,
                });
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => {
                setDataLoaded(true);
            });
    }, []);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <Fragment>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <HeroMain {...hero} />
                        </Grid>
                        <Grid item xs={12}>
                            <LeadershipBoard {...leaderBoardContent} />
                        </Grid>
                        <Grid item xs={12}>
                            <FooterBanner {...footerBannerContent} />
                        </Grid>
                    </Grid>
                </div>
            )}
        </Fragment>
    );
}

const LeadershipPageStyles = makeStyles(theme => ({
    root: {},
    hero: {
        width: '100%',
    },
}));

export default LeadershipPage;
