import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import { FaqAccordion, FaqItem } from './FaqAccordion';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

const pageEntryId: string = process.env.REACT_APP_FAQ_ENTRY_ID;

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        gap: 130,
        padding: '90px 125px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: '50px 16px',
            gap: 90,
        },
    },
    infoBox: {
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
    },
    infoBoxContent: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            letterSpacing: `${0.15 / 16}rem`,
        },
    },
}));

// const footerDetailsLocal: FooterBannerProps = {
//     bannerTitle: 'Looking to partner with us?',
//     bannerDescription: footerBannerDescription,
//     bannerImage: FooterPerson,
//     buttonText: 'Contact Us',
//     buttonLink: '/contact-us',
// };

interface SideContent {
    title: string;
    description: string;
}

export const FaqPage = () => {
    const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const classes = useStyle();
    const [faqsContent, setFaqsContent] = useState<Array<FaqItem>>([]);
    const [faqsSideContent, setFaqsSideContent] = useState<SideContent>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [asurionMobilePlusFaq, setAsurionMobilePlusFaq] = useState<string>();

    const loadPageContent = React.useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let bannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = entry.getReferences('bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = entry.getReferences('footerBannerPersonImage');
                const asurionMobilePlusFaqEntry: string = get(entry, 'fields.asurionMobilePlusFaq');
                setAsurionMobilePlusFaq(JSON.stringify(asurionMobilePlusFaqEntry));

                let promise1: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let promise2: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let promise3: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);

                Promise.all([promise1, promise2, promise3]).then((values: ContentfulEntry[]) => {
                    
                    setFaqsContent(entry.fields.questions);
                    setFaqsSideContent({ title: entry.fields.title, description: entry.fields.description });
                    setHero({ title: entry.fields.title, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                    setFooterBannerContent({
                        bannerTitle: entry.fields.footerBannerTitle,
                        bannerDescription: entry.fields.footerBannerDescription,
                        buttonLink: entry.fields.footerBannerButtonLink,
                        buttonText: entry.fields.footerBannerButtonText,
                        bannerImage: values[2].fields['file'].url,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...hero} />
                    <main className={classes.content}>
                        <aside className={classes.infoBox}>
                            <Typography variant="h3">{faqsSideContent.title}</Typography>
                            <Typography variant="subtitle1" className={classes.infoBoxContent}>
                                {faqsSideContent.description}
                            </Typography>
                        </aside>
                        <section>
                            <FaqAccordion faqs={faqsContent} asurionMobilePlusFaq={asurionMobilePlusFaq} />
                        </section>
                    </main>

                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </>
    );
};

export default FaqPage;
