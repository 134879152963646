import React, { useEffect, useState } from 'react';
// import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, ContentfulFields } from '../../../utils/ContentfulLoader';

import FooterBanner, { FooterBannerProps } from '../../common/FooterBanner';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import PartnersProductFoundation, { PartnersProductFoundationProps } from '../Partners/PartnersProductFoundation';
import PartnersTypes, { PartnersTypesProps } from '../Partners/PartnersTypes';
import PartnersCore, { PartnersCoreProps } from '../Partners/PartnersCore';
import WhatWeDoBanner, { WhatWeDoBannerProps } from '../../common/WhatWeDoBanner';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference, getContentfulImages } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';
import { Helmet } from 'react-helmet';

//DEV NOTES: WE ARE USING TH SPACE FOR PARTNERS CONTENT BECAUSE APAC SPACE RAN OUT OF CONTENT TYPES
const pageEntryId: string = process.env.REACT_APP_PARTNERS_ENTRY_ID;
const spaceId: string = process.env.REACT_APP_CONTENTFUL_TH_SPACE_ID;
const accessToken: string = process.env.REACT_APP_CONTENTFUL_TH_ACCESS_TOKEN;
//

function PartnersPage() {
    var [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [hero, setHero] = useState<HeroMainProps | null>(null);
    const [productFoundationContent, setProductFoundationContent] = useState<PartnersProductFoundationProps>();
    const [productTypesContent, setProductTypesContent] = useState<PartnersTypesProps>();
    const [productCoreContent, setproductCoreContent] = useState<PartnersCoreProps>();
    const [footerBannerContent, setFooterBannerContent] = useState<FooterBannerProps>();
    const [whatWeDoBannerContent, setWhatWeDoBannerContent] = useState<WhatWeDoBannerProps>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const loadPageContent = React.useCallback(() => {
        contentfulConfig = { ...contentfulConfig, spaceId: spaceId, accessToken: accessToken };
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let productWheelImage: string = '';

                let bannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'bannerImage');
                let smallBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'bannerImageSmall');
                let footerBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'footerBannerPersonImage');
                let partnerProductWheelImageImage: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'productWheelImage');
                let whatWeDoBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'whatWeDoBannerImage');
                let whatWeDoBannerImageSmallReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'whatWeDoBannerImageSmall');
                let partnerTypesImagesReference: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'partnerTypesImages');
                let partnerTypesIconsReference: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'partnerTypesIcons');
                let coreTypesIconsmagesReference: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'coreTypesIcons');

                let bannerImagePromise: Promise<ContentfulEntry> = bannerImageReferences[0].getData(contentfulConfig);
                let bannerImageSmallPromise: Promise<ContentfulEntry> = smallBannerImageReferences[0].getData(contentfulConfig);
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                let parterProductWheelPromise: Promise<ContentfulEntry> = partnerProductWheelImageImage[0].getData(contentfulConfig);
                let whatWeDoBannerImagePromise: Promise<ContentfulEntry> = whatWeDoBannerImageReferences[0].getData(contentfulConfig);
                let whatWeDoBannerImageSmallPromise: Promise<ContentfulEntry> = whatWeDoBannerImageSmallReferences[0].getData(contentfulConfig);
                let partnerTypesImagesPromise: Promise<ContentfulEntry[]> = getContentfulImages(partnerTypesImagesReference, contentfulConfig);
                let partnerTypesIconsPromise: Promise<ContentfulEntry[]> = getContentfulImages(partnerTypesIconsReference, contentfulConfig);
                let coreTypesIconsmagesPromise: Promise<ContentfulEntry[]> = getContentfulImages(coreTypesIconsmagesReference, contentfulConfig);

                Promise.all([bannerImagePromise, bannerImageSmallPromise, footerBannerImagePromise, parterProductWheelPromise, whatWeDoBannerImagePromise, whatWeDoBannerImageSmallPromise]).then(
                    (values: ContentfulEntry[]) => {
                        setHero({ title: entry.fields.bannerText, backgroundImage: values[0].fields['file'].url, backgroundImageSmall: values[1].fields['file'].url });
                        setFooterBannerContent({
                            bannerTitle: entry.fields.footerBannerTitle,
                            bannerDescription: entry.fields.footerBannerDescription,
                            buttonLink: entry.fields.footerBannerButtonLink,
                            buttonText: entry.fields.footerBannerButtonText,
                            bannerImage: values[2].fields['file'].url,
                        });
                        productWheelImage = values[3].fields['file'].url;
                        setWhatWeDoBannerContent({
                            title: entry.fields.whatWeDoBannerTitle,
                            bannerImage: values[4].fields['file'].url,
                            bannerImageSmall: values[5].fields['file'].url,
                            buttonText: entry.fields.whatWeDoButtonText,
                            buttonLink: entry.fields.whatWeDoBannerButtonLink,
                        });
                    }
                );

                Promise.all([partnerTypesImagesPromise, partnerTypesIconsPromise, coreTypesIconsmagesPromise]).then((values: ContentfulEntry[][]) => {
                    let maxIterations: number = parseInt(process.env.REACT_APP_MAX_ITERATIONS);
                    let coreTypesIterations: number = entry.fields.coreTypes.length;
                    let partnerTypesIterations: number = entry.fields.partnerTypes.length;

                    if (coreTypesIterations < maxIterations) {
                        for (let i = 0; i < coreTypesIterations; i++) {
                            entry.fields.coreTypes[i]['icon'] = values[2][i] ? values[2][i] : '';
                        }
                    }
                    if (partnerTypesIterations < maxIterations) {
                        for (let i = 0; i < partnerTypesIterations; i++) {
                            entry.fields.partnerTypes[i]['icon'] = values[1][i] ? values[1][i] : '';
                            entry.fields.partnerTypes[i]['bannerImage'] = values[0][i] ? values[0][i] : '';
                        }
                    }
                    setProductFoundationContent({
                        title: entry.fields.title,
                        description: entry.fields.description,
                        buttonText: entry.fields.buttonText,
                        buttonUrl: entry.fields.buttonLink,
                        centerText: entry.fields.subtitle,
                        foundations: entry.fields.foundations,
                        productWheelImage,
                    });
                    setProductTypesContent({
                        title: entry.fields.partnerTypesTitle,
                        typesDetails: entry.fields.partnerTypes,
                    });
                    setproductCoreContent({
                        title: entry.fields.coreTitle,
                        description: entry.fields.coreDescription,
                        coreDetails: entry.fields.coreTypes,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {});
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);

    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...hero} />
                    <PartnersProductFoundation {...productFoundationContent} />
                    <PartnersTypes {...productTypesContent} />
                    <PartnersCore {...productCoreContent} />
                    <WhatWeDoBanner {...whatWeDoBannerContent} />
                    <FooterBanner {...footerBannerContent} />
                </div>
            )}
        </div>
    );
}

export default PartnersPage;
