import React, { Fragment } from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { BLOCKS, MARKS, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parse from 'html-react-parser';

export interface InfoDetails {
    image: string;
    imageSmall: string;
    title: ContentfulDocument;
    description: ContentfulDocument;
}

export interface CSRInfoProps {
    infoDetails: InfoDetails[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '30em',
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            // paddingLeft: '7.5vw;',
            // paddingRight: '7.5vw',
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        textBold: {
            fontWeight: 'bold',
        },
        centerContent: {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sectionBox: {
            marginBottom: theme.spacing(6),
            [theme.breakpoints.up('md')]: {
                height: '45em',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'top center',
                boxShadow: '0px 8px 10px 0px #00000040,0px 6px 30px 0px #00000040',
            },
            [theme.breakpoints.down('sm')]: {
                // maxWidth: 320
            },
        },
        smallImageBox: {
            height: '20em',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        backgroundLeft: {
            backgroundPosition: 'left center',
        },
        backgroundRight: {
            backgroundPosition: 'right center',
        },
        contentLeft: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        contentRight: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        cardBox: {
            background: '#FFFFFF',
            boxShadow: '0px 16px 24px rgba(130, 117, 207, 0.14), 0px 6px 30px rgba(130, 117, 207, 0.12), 0px 8px 10px rgba(130, 117, 207, 0.2)',
            padding: theme.spacing(3),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',

            [theme.breakpoints.up('md')]: {
                width: '35%',
            },
            [theme.breakpoints.down('sm')]: {
                // minWidth: 350,
                marginTop: '-3em',
            },
        },
        cardTitle: {
            fontSize: '1.875rem',
            paddingRight: theme.spacing(5),
            marginBottom: theme.spacing(2),
        },
        cardDescription: {
            fontSize: theme.spacing(2),
            paddingRight: theme.spacing(5),
            lineHeight: '24px',
            whiteSpace: 'break-spaces',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        divider: {
            height: theme.spacing(1),
            background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)',
            marginBottom: theme.spacing(2),
        },
        overlay: {
            // paddingTop: theme.spacing(5),
            // paddingBottom: theme.spacing(5),
        },
        overlayLeft: {
            background: 'linear-gradient(to right, transparent 40%, white 60%)',
        },
        overlayRight: {
            background: 'linear-gradient(to left, transparent 40%, white 60%)',
        },
    })
);

function CSRInfo(infoDetails: CSRInfoProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Bold = ({ children }: any) => <span className={clsx(classes.textPrimary, classes.textBold)}>{children}</span>;

    const Text = ({ children }: any) => <span className="">{children}</span>;

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text: string) => parse(`${text[1]}`),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
        },

        renderText: (text: string) => text.split('\n').flatMap((text: string, i: number) => [i > 0 && <br />, text]),
    };

    const renderSection = ({ infoDetails }: CSRInfoProps): JSX.Element => {
        let jsx: JSX.Element;

        jsx = (
            <Box>
                {infoDetails.map((info, i) => (
                    <Fragment key={i}>
                        {isMobile ? (
                            <Grid container direction="column" className={clsx(classes.sectionBox)} justifyContent="center" alignItems="center">
                                <Grid
                                    item
                                    className={classes.smallImageBox}
                                    style={{
                                        backgroundImage: `url(` + info.imageSmall + `)`,
                                    }}
                                ></Grid>
                                {/* <Grid item xs> */}
                                <Grid item id="inner-box" className={clsx(classes.containerCompress)}>
                                    <Grid container direction="column" className={clsx(classes.cardBox)}>
                                        <Box className={classes.cardTitle}>{documentToReactComponents(info.title, options)}</Box>
                                        <Box className={classes.divider}></Box>
                                        <Box className={classes.cardDescription}>{documentToReactComponents(info.description, options)}</Box>
                                    </Grid>
                                </Grid>
                                {/* </Grid> */}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                direction="row"
                                className={clsx(classes.sectionBox, i % 2 === 0 ? classes.backgroundLeft : classes.backgroundRight)}
                                id="outer-box"
                                style={{
                                    backgroundImage: `url(` + info.image + `)`,
                                }}
                            >
                                <Grid className={clsx(classes.overlay, i % 2 === 0 ? classes.overlayLeft : classes.overlayRight)}>
                                    <Grid
                                        item
                                        md={12}
                                        id="inner-box"
                                        container
                                        direction="row"
                                        className={clsx(classes.containerCompress, classes.h100, i % 2 === 0 ? classes.contentLeft : classes.contentRight)}
                                    >
                                        <Grid container direction="column" className={clsx(classes.cardBox)}>
                                            <Box className={classes.cardTitle}>{documentToReactComponents(info.title, options)}</Box>
                                            <Box className={classes.divider}></Box>
                                            <Box className={classes.cardDescription}>{documentToReactComponents(info.description, options)}</Box>
                                        </Grid>
                                        <div id="hioverlay" className={classes.h100} style={{ background: '' }}></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Fragment>
                ))}
            </Box>
        );
        return jsx;
    };

    return <div className={classes.root}>{renderSection(infoDetails)}</div>;
}

export default CSRInfo;
