import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import WhatWeDo from '../../images/common/WhatWeDo.jpg';
import WhatWeDoSmall from '../../images/common/WhatWeDo-small.jpg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import parse from 'html-react-parser';

import { createStyles, makeStyles, Theme, withStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '35em',
            background: 'rgba(0, 0, 0, 0.02)',
            [theme.breakpoints.down('sm')]: {
                // height: '55em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            padding: theme.spacing(2),
            paddingLeft: '7vw',
            paddingRight: '7vw',
        },
        textPrimary: {
            color: theme.palette.primary.main,
        },
        banner: {
            backgroundImage: 'url(' + WhatWeDo + ')',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',

            [theme.breakpoints.up('sm')]: {
                backgroundPosition: 'right center',
            },
            [theme.breakpoints.down('sm')]: {
                // height: '65%',
                height: '250px',
            },
            [theme.breakpoints.down('xs')]: {
                backgroundImage: 'url(' + WhatWeDoSmall + ')',
            },
        },
        bannerTextBox: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                // height: '35%',
                height: '300px',
            },
        },
        bannerText: {
            fontSize: '2.125rem',
            lineHeight: '2.813rem',
            fontWeight: 'bold',
            color: 'black',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.875rem',
            },
        },
        bannerButton: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            textAlign: 'left',

            '&>a': {
                textDecoration: 'none',
            },
        },
    })
);

const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        borderRadius: '2em',
        backgroundColor: '#8223d2',
        '&:hover': {
            backgroundColor: '#8223d2',
        },
    },
}))(Button);

export interface WhatWeDoBannerProps {
    title: string;
    bannerImage: string;
    bannerImageSmall: string;
    buttonText: string;
    buttonLink: string;
}

function WhatWeDoBanner(whatWeDoBannerContent: WhatWeDoBannerProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            {isMobile ? (
                <Grid container direction="row" className={classes.h100}>
                    <Grid item sm={12} xs={12} className={classes.banner}></Grid>
                    <Grid item sm={12} xs={12} container direction="column" className={clsx(classes.bannerTextBox, classes.containerCompress)}>
                        <Grid>
                            <Box className={classes.bannerText}>{parse(`${whatWeDoBannerContent.title}`)}</Box>
                        </Grid>
                        <Grid>
                            <Box className={classes.bannerButton}>
                                <Link to="/what-we-do">
                                    <ColorButton variant="contained" color="primary">
                                        {whatWeDoBannerContent.buttonText}
                                    </ColorButton>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container direction="row" className={classes.h100}>
                    <Grid item md={12} className={classes.banner}>
                        <Grid item md={6} direction="column" className={clsx(classes.bannerTextBox, classes.containerCompress)}>
                            <Grid>
                                <Box className={classes.bannerText}>{parse(`${whatWeDoBannerContent.title}`)}</Box>
                            </Grid>
                            <Grid>
                                <Box className={classes.bannerButton}>
                                    <Link to="/what-we-do">
                                        <ColorButton variant="contained" color="primary">
                                            {whatWeDoBannerContent.buttonText}
                                        </ColorButton>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default WhatWeDoBanner;
