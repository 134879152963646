import axios from 'axios';

export interface SendMessageParameters {
    MessageType: string;
    ClientName: string;
    TemplateName: string;
    ReferenceType: string;
    ReferenceNBR: string;
    Subject: string;
    Language: string;
    // ToAddresses: string;
    ExtendedDynamicValues: {
        Customer_Email: string;
        Message_Category: string;
        Contact_Number: string;
        Tell_Us: string;
        Site_Source: string;
    };
}
export interface EmailProps {
    SendMessageParameters: SendMessageParameters;
}

let key: string = process.env.REACT_APP_EMAIL_API_KEY;
const config = {
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': key,
    },
};

export const sendEmail = async (SendMessageParameters: EmailProps) => {
    try {
        const res = await axios.post('/api/corewrapper/v1/sendemailsms', SendMessageParameters, config);
        console.log(res.data);
        return res.status;
    } catch (err) {
        return err;
    }
};
