import React, { Fragment } from 'react';
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

import clsx from 'clsx';

import { ReactComponent as CircledArrowIcon } from '../../../images/common/circledarrow.svg';

export interface Testimonial {
    image: string;
    headerText: string;
    quote: string;
    from: string;
}

export interface CareersTestimonialsProps {
    testimonialsDetails: Testimonial[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '25em',
            [theme.breakpoints.down('sm')]: {
                height: '35em',
            },
        },
        h100: {
            height: '100%',
        },
        containerCompress: {
            // paddingLeft: '7vw',
            // paddingRight: '7vw',
            padding: theme.spacing(2),
        },
        centerContents: {
            // height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        swiper: {
            background: '#8223D2',
            height: '100%',
            paddingLeft: '7vw',
            paddingRight: '7vw',
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                '&>.swiper-wrapper': {
                    height: '90%',
                },
            },
        },
        slideContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                paddingTop: '10px',
            },
        },
        paginationMod: {
            bottom: '20px !important',
            [theme.breakpoints.up('md')]: {
                right: '100px !important',
                width: 'auto !important',
                left: 'auto !important',
                margin: 0,
                display: 'none',
            },
        },
        paginationBullet: {
            padding: '0px 8px',
            borderRadius: '0 !important',
            background: 'white !important',
            margin: '0px 10px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                padding: '0px 5px',
            },
        },
        paginationBulletActive: {
            backgroundColor: '#03dac5 !important',
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        cardContainer: {
            height: '301px',
            width: '800px',
            background: 'white',
            [theme.breakpoints.down('sm')]: {
                height: '420px',
                width: '80%',
                minWidth: '320px',
            },
        },
        cardImageBox: {
            background: 'linear-gradient(to right, #EFEBFD 50%, white 50%)',
            [theme.breakpoints.down('sm')]: {
                background: '#EFEBFD',
                width: '100%',
                height: '3.75em',
                marginBottom: '3em',
            },
        },
        cardImage: {
            borderRadius: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '10em',
            },
        },
        cardTextBox: {
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                // height: '100%',
            },
        },
        cardTextHeader: {
            fontSize: '1.25rem',
            color: '#333F48',
        },
        cardTextQuote: {
            fontSize: '2.125rem',
            color: '#333F48',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),

            '&.smallText': {
                fontSize: '1.5rem',
            },
            '&.smallTextNonEnglish': {
                fontSize: '1.5rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',

                '&.smallText': {
                    fontSize: '1.25rem',
                },
                '&.smallTextNonEnglish': {
                    fontSize: '1.3rem',
                },
            },
        },
        cardTextName: {
            color: theme.palette.primary.main,
            fontSize: '1rem',
        },
        navigationBox: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
        },
        previousButton: {
            marginRight: theme.spacing(1),
            stroke: 'white',
            '&>circle': {
                stroke: 'white',
            },
            '&>g>path': {
                fill: 'white',
            },
            '&:hover': {
                fill: 'white',
                color: 'white',
                '&>g>path': {
                    fill: theme.palette.primary.main,
                },
                '&>g': {
                    fill: theme.palette.primary.main,
                },
            },
        },
        nextButton: {
            transform: 'scaleX(-1)',
            stroke: 'white',
            '&>circle': {
                stroke: 'white',
            },
            '&>g>path': {
                fill: 'white',
            },
            '&:hover': {
                fill: 'white',
                color: 'white',
                '&>g>path': {
                    fill: theme.palette.primary.main,
                },
                '&>g': {
                    fill: theme.palette.primary.main,
                },
            },
        },
    })
);

function CareersTestimonials({ testimonialsDetails }: CareersTestimonialsProps) {
    const classes = useStyles({ testimonialsDetails });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    SwiperCore.use([Navigation, Pagination, Autoplay]);

    return (
        <div className={classes.root}>
            <Fragment>
                <Swiper
                    className={clsx(classes.swiper)}
                    slidesPerView={isMobile ? 1 : 1.5}
                    spaceBetween={50}
                    pagination={{
                        clickable: true,
                        bulletClass: `` + classes.paginationBullet + ``,
                        bulletActiveClass: `` + classes.paginationBulletActive + ``,
                        clickableClass: `` + classes.paginationMod + ``,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next-unique',
                        prevEl: '.swiper-button-prev-unique',
                    }}
                    observeParents={true}
                    observer={true}
                >
                    {!isMobile && (
                        <Grid container direction="row" className={classes.navigationBox}>
                            <Grid className="swiper-button-prev-unique">
                                <CircledArrowIcon className={classes.previousButton} />
                            </Grid>
                            <Grid className="swiper-button-next-unique">
                                <CircledArrowIcon className={classes.nextButton} />
                            </Grid>
                        </Grid>
                    )}

                    {testimonialsDetails.map((testimonial, index) => (
                        <Fragment key={testimonial.from + index}>
                            <SwiperSlide className={classes.slideContainer}>
                                {isMobile ? (
                                    <Grid container direction="column" className={clsx(classes.cardContainer)}>
                                        <Grid className={clsx(classes.cardImageBox, classes.centerContents)}>
                                            <Box>
                                                <img src={testimonial.image} className={classes.cardImage} alt={testimonial.from} />
                                            </Box>
                                        </Grid>
                                        <Grid container direction="column" className={classes.cardTextBox}>
                                            <Box className={classes.cardTextHeader}>{testimonial.headerText}</Box>
                                            <Box
                                                className={clsx(
                                                    classes.cardTextQuote,
                                                    process.env.REACT_APP_LOCALE === 'ko' && testimonial.quote.length >= 60 ? 'smallTextNonEnglish' : '',
                                                    testimonial.quote.length >= 85 && 'smallText'
                                                )}
                                            >
                                                {testimonial.quote}
                                            </Box>
                                            <Box className={classes.cardTextName}>-{testimonial.from}</Box>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" className={clsx(classes.centerContents)}>
                                        <Grid container direction={isMobile ? 'column' : 'row'} className={classes.cardContainer}>
                                            <Grid item md={4} sm={12} className={clsx(classes.cardImageBox, classes.centerContents)}>
                                                <Box>
                                                    <img src={testimonial.image} className={classes.cardImage} alt={testimonial.from} />
                                                </Box>
                                            </Grid>
                                            <Grid item md={8} sm={12} className={classes.centerContents}>
                                                <Grid container direction="column" className={classes.cardTextBox}>
                                                    <Box className={classes.cardTextHeader}>{testimonial.headerText}</Box>
                                                    <Box
                                                        className={clsx(
                                                            classes.cardTextQuote,
                                                            process.env.REACT_APP_LOCALE === 'ko' && testimonial.quote.length >= 55 ? 'smallTextNonEnglish' : '',
                                                            testimonial.quote.length >= 85 && 'smallText'
                                                        )}
                                                    >
                                                        {testimonial.quote}
                                                    </Box>
                                                    <Box className={classes.cardTextName}>-{testimonial.from}</Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </SwiperSlide>
                        </Fragment>
                    ))}
                </Swiper>
            </Fragment>
        </div>
    );
}

export default CareersTestimonials;
