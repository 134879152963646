import { curryRight } from 'lodash';
import { BASE_WIDTH } from './constants';

export const toPercentage = (dividend: number, divisor: number) => {
  return `${dividend / divisor * 100}%`
}

export const toPercentageCurried = curryRight(toPercentage)

export const relativeToBaseWidth = toPercentageCurried(BASE_WIDTH)

export const rem = (input: number) => `${input}rem`

export const spatialValues = (...values: Array<string|number>) => {
  return values.reduce(
    (acc, current) => `${acc} ${current}${!isNaN(Number(current)) ? 'px' : ''}`,
    ''
  )
}

export const sv = spatialValues

export const rgba = (r: number, g: number, b: number, a: number = 1) => {
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export const vw = (input: number) => `${input}vw`
