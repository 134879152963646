import React, { useCallback, useEffect, useState } from 'react';
import HeroMain, { HeroMainProps } from '../../common/HeroMain';
import FooterBanner, { footerDescriptionText, FooterBannerProps } from '../../common/FooterBanner';
import PrivacyContent, { PrivacyContentProps } from './PrivacyContentTha';
import { ContentfulConfig, ContentfulEntry, ContentfulLoader, ContentfulReference } from '../../../utils/ContentfulLoader';
import Spinner from '../../utils/Spinner';

import BannerImage from '../../../images/privacy/Banner.png';
import BannerImageSmall from '../../../images/privacy/Banner-small.png';
import FooterPerson from '../../../images/common/careersfooterperson.png';

const pageEntryId: string = process.env.REACT_APP_TERMS_AND_PRIVACY_ENTRY_ID;

const heroMainDetails: HeroMainProps = {
    title: 'Privacy Policy',
    backgroundImage: BannerImage,
    backgroundImageSmall: BannerImageSmall,
};

const footerBannerDescription: footerDescriptionText[] = [
    { text: 'We’re at the forefront of ', color: 'white' },
    { text: 'protecting technology', color: '#37E7A7' },
    { text: ', from smartphone to smart home', color: 'white' },
];

const footerDetailsLocal: FooterBannerProps = {
    bannerTitle: 'Want to learn more about our products?',
    bannerDescription: footerBannerDescription,
    bannerImage: FooterPerson,
    buttonText: 'Learn More',
    buttonLink: '/what-we-do',
};

function PrivacyPage() {
    const [contentfulConfig] = useState<ContentfulConfig>(ContentfulConfig.getProduction());
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [privacyContent, setPrivacyContent] = useState<PrivacyContentProps>();
    const [footerDetails, setFooterDetails] = useState<FooterBannerProps>();

    const loadPageContent = useCallback(() => {
        ContentfulLoader.loadEntity(pageEntryId, contentfulConfig, 'Entry')
            .then((entry: ContentfulEntry) => {
                let footerBannerImageReferences: ContentfulReference[] = ContentfulLoader.getReferences(entry, 'privacyFooterBannerPersonImage');
                let footerBannerImagePromise: Promise<ContentfulEntry> = footerBannerImageReferences[0].getData(contentfulConfig);
                setPrivacyContent({ title: entry.fields.privacyPolicyTitleThai, dateUpdated: entry.fields.privacyPolicyEffectivityDateThai, content: entry.fields.privacyPolicyContentThai });
                Promise.all([footerBannerImagePromise]).then((values: ContentfulEntry[]) => {
                    setFooterDetails({
                        bannerTitle: entry.fields.privacyFooterBannerTitle,
                        bannerDescription: entry.fields.privacyFooterBannerDescription,
                        bannerImage: values[0].fields['file'].url,
                        buttonText: entry.fields.privacyFooterBannerButtonText,
                        buttonLink: entry.fields.privacyFooterBannerButtonLink,
                    });
                    setDataLoaded(true);
                });
            })
            .catch((error: any) => {
                setDataLoaded(true);
            });
    }, [contentfulConfig]);

    useEffect(() => {
        loadPageContent();
    }, [loadPageContent]);
    return (
        <div>
            {(!dataLoaded && <Spinner />) || (
                <div>
                    <HeroMain {...heroMainDetails} />
                    <PrivacyContent {...privacyContent} />
                    <FooterBanner {...footerDetails} />
                </div>
            )}
        </div>
    );
}

export default PrivacyPage;
